/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { SearchService } from '../../../../app/core/shared/search/search.service';
import { Injectable } from '@angular/core';
import { SearchOptions } from '../../../../app/shared/search/models/search-options.model';
import { QuerySuggestionsObject } from '../../../shared/search/query-suggestion-object.model';
import { RemoteData } from '../../../../app/core/data/remote-data';
import { Observable, of as observableOf } from 'rxjs';
import { FollowLinkConfig } from '../../../../app/shared/utils/follow-link-config.model';
import { environment } from '../../../../environments/environment';
import { map, take } from 'rxjs/operators';
import { hasValue, isNotEmpty } from '../../../../app/shared/empty.util';
import { URLCombiner } from '../../../../app/core/url-combiner/url-combiner';
import { GenericConstructor } from '../../../../app/core/shared/generic-constructor';
import { ResponseParsingService } from '../../../../app/core/data/parsing.service';
import { SuggestResponseParsingService } from '../../data/suggest-response-parsing.service';

@Injectable()
export class AtmireSearchService extends SearchService {

  /**
   * Endpoint link path for retrieving suggestions
   */
  private suggestLinkPath = 'querySuggestions';

  /**
   * Method to retrieve a list of suggestion categories,
   * each containing a list of suggestions of that category
   * @param searchOptions The search options to find suggestions for
   */
  suggest(searchOptions: SearchOptions, ...linksToFollow: FollowLinkConfig<any>[]): Observable<RemoteData<QuerySuggestionsObject>> {
    // const href$ = this.halService.getEndpoint(this.suggestLinkPath).pipe(
    //   map((url: string) => {
    //     if (hasValue(searchOptions)) {
    //       url = searchOptions.toRestUrl(url);
    //     }
    //     return url;
    //   }),
    // );

    const href$ = observableOf(environment.rest.baseUrl + '/api/suggestion/querySuggestions/search/byQuery').pipe(
      map((url: string) => {
        const args = this.searchDataService.addEmbedParams(url, [], ...linksToFollow);
        if (hasValue(searchOptions)) {
          url = searchOptions.toRestUrl(url, args);
        } else if (isNotEmpty(args)) {
          url = new URLCombiner(url, `?${args.join('&')}`).toString();
        }
        return url;
      })
    );

    href$.pipe(take(1)).subscribe((url: string) => {
      const request = new this.request(this.requestService.generateRequestId(), url);
      const getResponseParserFn: () => GenericConstructor<ResponseParsingService> = () => {
        return SuggestResponseParsingService;
      };

      Object.assign(request, {
        getResponseParser: getResponseParserFn,
      });
      this.requestService.send(request, true);
    });

    return this.rdb.buildFromHref(href$);
  }

}
