/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component } from '@angular/core';

@Component({
  selector: 'ds-sso-redirect-page',
  templateUrl: './sso-redirect-page.component.html',
  styleUrls: ['./sso-redirect-page.component.scss']
})
export class SsoRedirectPageComponent {

}
