/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Input, OnInit } from '@angular/core';
import { ComcolPageHeaderComponent } from '../../../../app/shared/comcol/comcol-page-header/comcol-page-header.component';
import { DSpaceObject } from '../../../../app/core/shared/dspace-object.model';
import { MultilingualMetadataService } from '../../../core/shared/multilingual-metadata.service';

@Component({
  selector: 'ds-multilingual-comcol-page-header',
  styleUrls: ['../../../../app/shared/comcol/comcol-page-header/comcol-page-header.component.scss'],
  templateUrl: '../../../../app/shared/comcol/comcol-page-header/comcol-page-header.component.html',
})
export class MultilingualComcolPageHeaderComponent extends ComcolPageHeaderComponent implements OnInit {

  // The Community/Collection
  @Input() dso: DSpaceObject;

  name: string;

  constructor(
    protected multilingualMetadataService: MultilingualMetadataService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.name = this.multilingualMetadataService.firstValueCurrentLang(this.dso.metadata, 'dc.title');
  }
}
