/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Optional } from '@angular/core';
import { FooterComponent as BaseComponent } from '../../../../app/footer/footer.component';
import { LOGIN_ROUTE, AuthService } from '../../../../app/core/auth/auth.service';
import { KlaroService } from '../../../../app/shared/cookies/klaro.service';
import { getPrivacyPath } from '../../../../app/info/info-routing-paths';

@Component({
  selector: 'ds-footer',
  styleUrls: ['footer.component.scss'],
  // styleUrls: ['../../../../app/footer/footer.component.scss'],
  templateUrl: 'footer.component.html'
  // templateUrl: '../../../../app/footer/footer.component.html'
})
export class FooterComponent extends BaseComponent {
  showTopFooter = false;
  public loginRoute = LOGIN_ROUTE;

  constructor(
    @Optional() cookies: KlaroService,
    public authService: AuthService,
  ) {
    super(cookies);
  }

  getPrivacyPath() {
    return getPrivacyPath();
  }
}
