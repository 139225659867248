<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="container">
  <div class="row">
    <div class="col-12 pb-4">
      <ng-container *ngVar="(parentRD$ | async)?.payload as parent">
        <h2 *ngIf="!parent" id="header" class="border-bottom pb-2">{{ 'community.create.head' | translate }}</h2>
        <h2 *ngIf="parent" id="sub-header" class="border-bottom pb-2">{{ 'community.create.sub-head' | translate:{ parent: parentName$ | async } }}</h2>
      </ng-container>
    </div>
  </div>
  <ds-themed-community-form (submitForm)="onSubmit($event)"
                     (back)="navigateToHome()"
                     (finish)="navigateToNewPage()"></ds-themed-community-form>
</div>
