<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div *ngIf="!(isAuthenticated | async) && hasHidden" class="sign-in-section mt-3 mb-3">
  {{ 'item.page.sign-in.intro' | translate }}
  <a class="btn sign-in-btn link-indicator ml-2" (click)="signIn()" [href]="redirectRoute$ | async">
    {{ 'item.page.sign-in.button' | translate }}&nbsp;
  </a>
</div>
