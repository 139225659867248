<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->

<div *ngIf="bannerType" class="banner alert alert-warning" [ngClass]="bannerType" role="alert" style="width: 100%">
  <div class="banner-content" [innerHTML]="'item.page.banner.content.' + bannerType | translate: params"></div>
</div>
