/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { autoserialize, inheritSerialization } from 'cerialize';
import { Suggestion } from './suggestion.model';
import { typedObject } from '../../../../app/core/cache/builders/build-decorators';
import { SEARCH_SUGGESTION } from '../resource-types/search-suggestion.resource-type';

/**
 * Represents a search suggestion for a search query
 */
@typedObject
@inheritSerialization(Suggestion)
export class SearchSuggestion extends Suggestion {
  static type = SEARCH_SUGGESTION;

  /**
   * The amount of results for this search query
   */
  @autoserialize
  count: number;
}
