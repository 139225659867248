import { ChangeDetectorRef, Component, ComponentFactoryResolver, Input } from '@angular/core';
import { ThemedComponent } from '../shared/theme-support/themed.component';
import { ThumbnailComponent } from './thumbnail.component';
import { Bitstream } from '../core/shared/bitstream.model';
import { RemoteData } from '../core/data/remote-data';
import { ThemeService } from '../shared/theme-support/theme.service';

/**
 * Themed wrapper for ThumbnailComponent
 */
@Component({
  selector: 'ds-themed-thumbnail',
  styleUrls: ['./thumbnail.component.scss'],
  templateUrl: '../shared/theme-support/themed.component.html',
})
export class ThemedThumbnailComponent extends ThemedComponent<ThumbnailComponent> {
  protected inAndOutputNames: (keyof ThumbnailComponent & keyof this)[] = ['thumbnail', 'defaultImage', 'alt', 'placeholder', 'limitWidth'];

  @Input() thumbnail: Bitstream | RemoteData<Bitstream>;

  @Input() defaultImage?;

  @Input() alt?;

  @Input() placeholder?;

  @Input() limitWidth?;

  constructor(
    protected resolver: ComponentFactoryResolver,
    protected cdr: ChangeDetectorRef,
    protected themeService: ThemeService
  ) {
    super(resolver, cdr, themeService);
  }

  protected getComponentName(): string {
    return 'ThumbnailComponent';
  }

  protected importThemedComponent(themeName: string): Promise<any> {
    return import(`../../themes/${themeName}/app/thumbnail/thumbnail.component`);
  }

  protected importUnthemedComponent(): Promise<any> {
    return import(`./thumbnail.component`);
  }
}
