/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { HardRedirectService } from '../../../app/core/services/hard-redirect.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { map, tap } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { AtmireAuthService } from './atmire-auth.service';

@Injectable()
export class SsoGuard implements CanActivate {

  sub: Subscription;

  constructor(private authService: AtmireAuthService,
              @Inject(PLATFORM_ID) private platformId: any,
              private hardRedirectService: HardRedirectService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.getSsoServerUrl().pipe(
      tap((url: string) => {
        // only redirect to sso on the client, because the server doesn't have access to the
        // sso cookie and can therefore never know if you're authenticated or not
        if (isPlatformBrowser(this.platformId)) {
          this.hardRedirectService.redirect(url);
        }
      }),
      map(() => true)
    );

  }
}
