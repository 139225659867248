/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component } from '@angular/core';
import { ComcolLanguagesComponent } from '../../../shared/comcol/comcol-forms/edit-comcol-page/comcol-languages/comcol-languages.component';
import { Collection } from '../../../../app/core/shared/collection.model';
import { CollectionDataService } from '../../../../app/core/data/collection-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from '../../../../app/shared/notifications/notifications.service';

/**
 * Component for modifying the supported languages of a collection
 */
@Component({
  selector: 'ds-collection-languages',
  templateUrl: '../../../shared/comcol/comcol-forms/edit-comcol-page/comcol-languages/comcol-languages.component.html',
})
export class CollectionLanguagesComponent extends ComcolLanguagesComponent<Collection> {
  frontendURL = '/collections/';

  constructor(
    protected dsoDataService: CollectionDataService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected translate: TranslateService,
    protected notifications: NotificationsService,
  ) {
    super(dsoDataService, route, router, translate, notifications);
  }
}
