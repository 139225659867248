<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<ds-loading *ngIf="(dataSource.loading$ | async) && !loadingNode" class="ds-loading"></ds-loading>
<cdk-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <!-- This is the tree node template for show more node -->
  <cdk-tree-node *cdkTreeNodeDef="let node; when: isShowMore" cdkTreeNodePadding
                 class="example-tree-node show-more-node">
    <div class="btn-group">
      <button type="button" class="btn btn-default" cdkTreeNodeToggle>
        <span class="fa fa-chevron-right invisible" aria-hidden="true"></span>
      </button>
      <div class="align-middle pt-2">
        <a *ngIf="node!==loadingNode" [routerLink]="" (click)="getNextPage(node)"
           class="btn btn-outline-primary btn-sm" role="button">
          <i class="fas fa-angle-down"></i> {{ 'communityList.showMore' | translate }}
        </a>
        <ds-loading *ngIf="node===loadingNode && dataSource.loading$ | async" class="ds-loading"></ds-loading>
      </div>
    </div>
    <div class="text-muted" cdkTreeNodePadding>
      <div class="d-flex">
      </div>
    </div>
  </cdk-tree-node>
  <!-- This is the tree node template for expandable nodes (coms and subcoms with children) -->
  <cdk-tree-node *cdkTreeNodeDef="let node; when: hasChild" cdkTreeNodePadding cdkTreeNodePaddingIndent="71"
                 class="example-tree-node">
    <div class="btn-group">
      <button type="button" class="btn btn-default" cdkTreeNodeToggle
              [title]="'toggle ' + node.name"
              [attr.aria-label]="'toggle ' + node.name"
              (click)="toggleExpanded(node)"
              [ngClass]="(hasChild(null, node)| async) ? 'visible' : 'invisible'"
              *ngIf="(hasChild(null, node)| async)">
        <span class="align-middle">
          <i class="fa {{node.isExpanded ? 'fa-minus' : 'fa-plus'}}" aria-hidden="true"></i>
        </span>
      </button>
      <h5 [ngClass]="'node-title'">
        <a [routerLink]="node.route" class="lead">
          {{ getName(node) }}
        </a>
      </h5>
    </div>
    <div class="d-flex" *ngIf="node===loadingNode && dataSource.loading$ | async"
         cdkTreeNodePadding>
      <button type="button" class="btn btn-default invisible">
        <span class="align-middle">
          <i class="fa {{node.isExpanded ? 'fa-minus' : 'fa-plus'}}" aria-hidden="true"></i>
        </span>
      </button>
      <ds-loading class="ds-loading pt-2"></ds-loading>
    </div>
  </cdk-tree-node>
</cdk-tree>
