/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[dsSuggestionPreviewAnchor]',
})
export class SuggestionPreviewDirective {
  constructor(public viewContainerRef: ViewContainerRef) { }
}
