/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { deserialize, inheritSerialization } from 'cerialize';
import { Suggestion } from './suggestion.model';
import { DSpaceObject } from '../../../../app/core/shared/dspace-object.model';
import { RemoteData } from '../../../../app/core/data/remote-data';
import { link, typedObject } from '../../../../app/core/cache/builders/build-decorators';
import { HALLink } from '../../../../app/core/shared/hal-link.model';
import { OBJECT_SUGGESTION } from '../resource-types/object-suggestion.resource-type';
import { ITEM } from '../../../../app/core/shared/item.resource-type';
import { Observable } from 'rxjs';

/**
 * Represents a search suggestion for a DSpaceObject
 */
@typedObject
@inheritSerialization(Suggestion)
export class ObjectSuggestion<T extends DSpaceObject> extends Suggestion {
  static type = OBJECT_SUGGESTION;

  /**
   * The {@link HALLink}s for this SuggestionCategory
   */
  @deserialize
  _links: {
    self: HALLink;
    dspaceObject: HALLink;
  };

  /**
   * The DSpaceObject that was found
   */
  @link(ITEM)
  dspaceObject: Observable<RemoteData<T>>;
}
