/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { FollowLinkConfig } from '../../../app/shared/utils/follow-link-config.model';
import { ListableObject } from '../../../app/shared/object-collection/shared/listable-object.model';
import { PaginationComponentOptions } from '../../../app/shared/pagination/pagination-component-options.model';
import { SortOptions } from '../../../app/core/cache/models/sort-options.model';
import { Observable } from 'rxjs';
import { RemoteData } from '../../../app/core/data/remote-data';
import { PaginatedList } from '../../../app/core/data/paginated-list.model';
import { ListableObjectSourceType } from '../listable-object-sources';
import { DSpaceObject } from '../../../app/core/shared/dspace-object.model';
import { CacheableObject } from '../../../app/core/cache/object-cache.reducer';
import { ListableCacheableObject } from '../base/listable-cachable-object.model';

// tslint:disable:max-classes-per-file

export interface AbstractSourceKwargs<T extends ListableObject & CacheableObject> {
  useCachedVersionIfAvailable?: boolean;
  reRequestOnStale?: boolean;
  linksToFollow?: FollowLinkConfig<T>[];
}

/**
 * Extend this class to define object source models.
 */
export abstract class AbstractListableObjectSourceModel<T extends ListableObject & CacheableObject> {
  readonly source: ListableObjectSourceType;

  useCachedVersionIfAvailable? = true;
  reRequestOnStale? = true;
  linksToFollow?: FollowLinkConfig<T>[] = [];

  protected constructor(kwargs?: AbstractSourceKwargs<T>) {
    if (kwargs !== undefined) {
      Object.keys(kwargs).filter(kw => kwargs[kw] !== undefined)
            .forEach((kw: string) => this[kw] = kwargs[kw]);
    }
  }
}

/**
 * Extend this class to define object source implementations.
 */
export abstract class AbstractListableObjectSource<T extends ListableCacheableObject> {
  readonly source: ListableObjectSourceType;

  protected useCachedVersionIfAvailable? = true;
  protected reRequestOnStale? = true;
  protected linksToFollow?: FollowLinkConfig<T>[] = [];

  protected constructor(model?: AbstractListableObjectSourceModel<T>) {
    this.useCachedVersionIfAvailable = model.useCachedVersionIfAvailable;
    this.reRequestOnStale = model.reRequestOnStale;
    this.linksToFollow = model.linksToFollow;
  }

  /**
   * Parameters describing this source's configuration.
   * To be used for i18n interpolation (e.g. when displaying errors to the user)
   */
  abstract get params(): object;

  /**
   * Retrieve a list of objects from an object collection.
   * @param paginationOptions
   * @param sort
   */
  abstract getList(
    paginationOptions: Partial<PaginationComponentOptions>, sort: Partial<SortOptions>,
  ): Observable<RemoteData<PaginatedList<ListableObject>>>;
}
