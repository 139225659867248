<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="jumbotron jumbotron-fluid">
  <div class="container">
    <h1 class="h2 text-center pb-4">{{ 'home-news.page.title' | translate }}</h1>
    <p class="lead text-center pb-4">{{ 'home-news.page.description' | translate }}</p>
    <div class="d-flex flex-wrap justify-content-center pb-2">
      <button (click)="navigate( 'normative.framework.documents.collection')">
        <i class="fas fa-folder-open"></i>
        <span>{{ 'home-news.page.document-centre' | translate }}</span>
      </button>
      <button (click)="navigate('fsc-certified-forests.collection')">
        <i class="fas fa-medal"></i>
        <span class="font-weight-bold">{{ 'home-news.page.FSC-certified-forests' | translate }}</span>
      </button>
      <button (click)="navigate('fsc-research-portal.collection')">
        <i class="fas fa-tree"></i>
        <span class="font-weight-bold">{{ 'home-news.page.fsc-research-portal' | translate }}</span>
      </button>
      <button (click)="navigate('fsc-resources-podcasts.collection')">
        <i class="fas fa-microphone-alt"></i>
        <span>{{ 'home-news.page.podcasts' | translate}}</span>
      </button>
    </div>
  </div>
</div>
