/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component } from '@angular/core';
import {
  CommunityListComponent as BaseComponent
} from '../../../../../app/community-list-page/community-list/community-list.component';
import { CommunityListService } from '../../../../../app/community-list-page/community-list-service';
import { DSONameService } from '../../../../../app/core/breadcrumbs/dso-name.service';
import { DSpaceObject } from '../../../../../app/core/shared/dspace-object.model';
import { AtmireCommunityListService } from '../../../../../app-atmire/community-list-page/atmire-community-list.service';

/**
 * A tree-structured list of nodes representing the communities, their subCommunities and collections.
 * Initially only the page-restricted top communities are shown.
 * Each node can be expanded to show its children and all children are also page-limited.
 * More pages of a page-limited result can be shown by pressing a show more node/link.
 * Which nodes were expanded is kept in the store, so this persists across pages.
 */
@Component({
  selector: 'ds-community-list',
  styleUrls: ['./community-list.component.scss'],
  templateUrl: './community-list.component.html'
  // templateUrl: '../../../../../app/community-list-page/community-list/community-list.component.html'
})
export class CommunityListComponent extends BaseComponent {

  constructor(
    communityListService: AtmireCommunityListService,
    public dsoNameService: DSONameService,
  ) {
    super(communityListService);
  }

  getName(node) {
    return this.dsoNameService.getName(Object.assign(new DSpaceObject(), node));
  }

}
