<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<ds-metadata-field-line-wrapper [label]="label"
                                [labelSuffix]="labelSuffix"
                                [labelClass]="labelClass"
                                [labelTooltip]="labelTooltip">
      <span class="d-inline-block" [ngClass]="valueClass"
            *ngIf="!displayLink && (!initialSize || showAll || (mdValues.length < initialSize))">
          {{ getMetadataString() }}
      </span>
</ds-metadata-field-line-wrapper>
