<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<ds-community-search-result-list-element-client [object]="object"
                                             [index]="index"
                                             [linkType]="linkType"
                                             [listID]="listID"></ds-community-search-result-list-element-client>
<div>
  <a class="btn btn-light mt-1" [routerLink]="[editPath]">
    <i class="fa fa-edit"></i> {{"admin.search.community.edit" | translate}}
  </a>
</div>
