/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import {
  Component,
  ComponentFactoryResolver,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Suggestion } from '../../../../../core/search/models/suggestion.model';
import { getSuggestionPreviewRendererFor } from '../suggestion.decorator';
import { GenericConstructor } from '../../../../../../app/core/shared/generic-constructor';
import { SuggestionPreviewDirective } from './suggestion-preview.directive';
import { SuggestionPreviewComponent } from './suggestion-preview.component';
import { hasValue } from '../../../../../../app/shared/empty.util';
import { SuggestionCategory } from '../../../../../core/search/models/suggestion-category.model';
import { SearchOptions } from '../../../../../../app/shared/search/models/search-options.model';

/**
 * Wrapper component that renders a specific suggestion's preview based on the suggestion's type
 */
@Component({
  selector: 'ds-suggestion-preview-wrapper',
  templateUrl: './suggestion-preview-wrapper.component.html',
})
export class SuggestionPreviewWrapperComponent implements OnChanges {

  /**
   * Suggestion for this wrapper component
   */
  @Input() suggestion: Suggestion;
  @Input() searchOptions: SearchOptions;
  @Input() category: SuggestionCategory;

  /**
   * Directive used to place the specific suggestion preview component
   */
  @ViewChild(SuggestionPreviewDirective, {static: false}) previewAnchor: SuggestionPreviewDirective;

  /**
   * The specific suggestion's preview component
   */
  suggestionComponent: SuggestionPreviewComponent<Suggestion>;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
  }

  /**
   * With every change, update the suggestion preview
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (hasValue(changes.suggestion) && hasValue(this.suggestion)) {
      const suggestionComponent = this.getSuggestionPreviewComponent();
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(suggestionComponent);
      const viewContainerRef = this.previewAnchor.viewContainerRef;
      viewContainerRef.clear();
      const componentRef = viewContainerRef.createComponent(componentFactory);
      ((componentRef.instance) as SuggestionPreviewComponent<Suggestion>).suggestion = this.suggestion;
      ((componentRef.instance) as SuggestionPreviewComponent<Suggestion>).searchOptions = this.searchOptions;
      ((componentRef.instance) as SuggestionPreviewComponent<Suggestion>).category = this.category;
      this.suggestionComponent = ((componentRef.instance) as SuggestionPreviewComponent<Suggestion>);
    }
  }

  /**
   * Find the correct component based on the suggestion's type
   */
  getSuggestionPreviewComponent() {
    const searchSuggestion: GenericConstructor<Suggestion> = this.suggestion.constructor as GenericConstructor<Suggestion>;
    return getSuggestionPreviewRendererFor(searchSuggestion);
  }
}
