<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<ds-themed-results-back-button *ngIf="showBackButton | async" [back]="back"></ds-themed-results-back-button>
<div class="row" *ngIf="iiifEnabled">
  <div class="col-12">
    <ds-mirador-viewer id="iiif-viewer"
                       [object]="object"
                       [searchable]="iiifSearchEnabled"
                       [query]="iiifQuery$ | async">
    </ds-mirador-viewer>
  </div>
</div>
<!-- title -->
<div class="d-flex flex-row">
  <h2 class="item-page-title-field mr-auto">{{ dsoNameService.getName(object) }}</h2>
  <div class="pl-2 space-children-mr">
    <ds-dso-page-version-button (newVersionEvent)="onCreateNewVersion()" [dso]="object"
                                [tooltipMsgCreate]="'item.page.version.create'"
                                [tooltipMsgHasDraft]="'item.page.version.hasDraft'"></ds-dso-page-version-button>
    <ds-dso-page-edit-button [pageRoute]="itemPageRoute" [dso]="object"
                             [tooltipMsg]="'item.page.edit'"></ds-dso-page-edit-button>
  </div>
</div>


<div class="row">
  <!-- left column -->
  <div class="col-xs-12 col-md-4">
    <!-- thumbnail -->
    <ng-container *ngIf="!mediaViewer.image">
      <ds-metadata-field-wrapper [hideIfNoTextContent]="false">
        <ds-themed-thumbnail [thumbnail]="object?.thumbnail | async"></ds-themed-thumbnail>
      </ds-metadata-field-wrapper>
    </ng-container>
    <div *ngIf="mediaViewer.image" class="mb-2">
      <ds-themed-media-viewer [item]="object" [videoOptions]="mediaViewer.video"></ds-themed-media-viewer>
    </div>
  </div>

  <!-- right column -->
  <div class="col-xs-12 col-md-8 mb-5">
    <div class="mb-3">
      <!-- Authors -->
      <ds-styled-item-page-field [item]="object"
                                 [valueClass]="'content-pill light-pill mb-2'"
                                 [separator]="undefined"
                                 [initialSize]="5"
                                 [fields]="['dc.contributor','dc.contributor.author', 'dc.creator']"
                                 [label]="'item-search-result-list-element.author' | translate">
      </ds-styled-item-page-field>
    </div>

    <div class="mb-3">
      <!-- Publication date -->
      <ds-styled-item-page-field [item]="object"
                                 [fields]="['dcterms.issued']"
                                 [label]="'item-search-result-list-element.issued' | translate">
      </ds-styled-item-page-field>
      <!-- Publication type -->
      <ds-styled-item-page-field [item]="object"
                                 [fields]="['dcterms.type']"
                                 [label]="'item-search-result-list-element.type' | translate">
      </ds-styled-item-page-field>
      <!-- Source -->
      <ds-client-styled-item-page-field [item]="object"
                                        [fields]="['is.journalName', 'is.extent.volume', 'is.extent.number', 'is.extent.pages']"
                                        [label]="'item-search-result-list-element.source' | translate">
      </ds-client-styled-item-page-field>
      <!-- Code -->
      <ds-styled-item-page-field class="w-100"
                                 [item]="object"
                                 [initialSize]="1"
                                 [fields]="['fscdoc.code', 'is.identifier.standardCode', 'is.identifier.adviceCode', 'is.identifier.certificatecode']"
                                 [label]="'item-search-result-list-element.code' | translate">
      </ds-styled-item-page-field>
    </div>

    <div class="mb-3">
      <!-- Access to the Study -->
      <ds-styled-item-page-field class="w-100"
                                 [item]="object"
                                 [valueClass]="'content-pill secondary-pill mr-2 mb-2'"
                                 [separator]="undefined"
                                 [initialSize]="3"
                                 [fields]="['is.link.url']"
                                 [displayLink]="true"
                                 [externalLink]="true"
                                 [linkText]="'Open link'"
                                 [label]="'item-search-result-list-element.access-to-study' | translate">
      </ds-styled-item-page-field>

      <!-- Permanent Resource Identifier -->
      <ds-styled-item-page-field class="w-100"
                                 [item]="object"
                                 [valueClass]="'content-pill secondary-pill mr-2 mb-2'"
                                 [separator]="undefined"
                                 [initialSize]="3"
                                 [displayLink]="true"
                                 [externalLink]="true"
                                 [fields]="['is.identifier.doi']"
                                 [linkText]="'Open link'"
                                 [label]="'item-search-result-list-element.permanent-resource-id' | translate">
      </ds-styled-item-page-field>
      <ds-styled-item-page-field class="w-100"
                                 [item]="object"
                                 [valueClass]="'content-pill secondary-pill mr-2 mb-2'"
                                 [separator]="undefined"
                                 [initialSize]="3"
                                 [displayLink]="true"
                                 [externalLink]="true"
                                 [linkText]="'Open link'"
                                 [fields]="['is.identifier.fscdoi']"
                                 [label]="'item-search-result-list-element.fscdoi' | translate">
      </ds-styled-item-page-field>
    </div>

    <div>
      <!-- Collections -->
      <ds-client-item-page-multilingual-collections [item]="object"></ds-client-item-page-multilingual-collections>

      <!-- Full Item page link -->
      <ds-metadata-field-line-wrapper [label]="'Metadata'" *ngIf="isAuthorized$ | async">
        <a class="btn btn-outline-primary" [routerLink]="[itemPageRoute + '/full']" role="button">
          <i class="fas fa-info-circle"></i> {{ "item.page.link.full" | translate }}
        </a>
      </ds-metadata-field-line-wrapper>
    </div>
  </div>

</div>

<div class="row">
  <!-- Shared sections -->
  <div class="col-12">
    <!-- Abstract -->
    <ds-themed-generic-item-page-field [item]="object"
                                       [enableMarkdown]="true"
                                       [fields]="['dc.description.abstract', 'dcterms.abstract']"
                                       [label]="'item.page.abstract'">
    </ds-themed-generic-item-page-field>

    <!-- Summary -->
    <ds-themed-generic-item-page-field [item]="object"
                                       [enableMarkdown]="true"
                                       [fields]="['is.evidenceSummary']"
                                       [label]="'item-search-result-list-element.evidence-summary'">
    </ds-themed-generic-item-page-field>

    <!-- Description -->
    <ds-themed-generic-item-page-field class="w-100"
                                       [item]="object"
                                       [fields]="['dc.description', 'dcterms.description']"
                                       [label]="'item.page.description' | translate">
    </ds-themed-generic-item-page-field>

    <!-- Citation -->
    <ds-themed-generic-item-page-field class="w-100"
                                       [item]="object"
                                       [fields]="['dcterms.bibliographicCitation', 'dc.identifier.citation']"
                                       [label]="'item-search-result-list-element.citation' | translate">
    </ds-themed-generic-item-page-field>

    <!-- File section -->
    <ds-themed-item-page-file-section [item]="object"></ds-themed-item-page-file-section>
  </div>
</div>

<div class="row">
  <div class="col-12 mb-5">
    <!-- Info Section -->
    <ds-client-item-info-sections [dso]="object" [sectionClass]="'item-page-section'" [full]="true">
    </ds-client-item-info-sections>

    <!-- Sign in to access more information section -->
    <ds-client-sign-in [item]="object"></ds-client-sign-in>
  </div>
</div>
