/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Registration } from '../app/core/shared/registration.model';
import { VersionHistory } from '../app/core/shared/version-history.model';
import { ObjectSuggestion } from './core/search/models/object-suggestion.model';
import { SearchSuggestion } from './core/search/models/search-suggestion.model';
import { Suggestion } from './core/search/models/suggestion.model';
import { SuggestionCategory } from './core/search/models/suggestion-category.model';
import { FilterSuggestion } from './core/search/models/filter-suggestion.model';

/**
 * Declaration needed to make sure all decorator functions are called in time
 */
export const MODELS = [
  Registration,
  VersionHistory,
  SuggestionCategory,
  Suggestion,
  SearchSuggestion,
  ObjectSuggestion,
  FilterSuggestion,
];
