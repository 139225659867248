<ds-community-search-result-grid-element [object]="object"
                                         [index]="index"
                                         [linkType]="linkType"
                                         [listID]="listID">
  <ul class="list-group list-group-flush">
    <li class="list-group-item text-center">
      <a class="btn btn-light btn-sm btn-auto my-1 edit-link" [routerLink]="[editPath]">
        <i class="fa fa-edit"></i>
      </a>
    </li>
  </ul>
</ds-community-search-result-grid-element>

