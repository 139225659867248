/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { autoserialize, inheritSerialization } from 'cerialize';
import { Suggestion } from './suggestion.model';
import { typedObject } from '../../../../app/core/cache/builders/build-decorators';
import { FILTER_SUGGESTION } from '../resource-types/filter-suggestion.resource-type';

/**
 * Represents a search suggestion for a filter query
 */
@typedObject
@inheritSerialization(Suggestion)
export class FilterSuggestion extends Suggestion {
  static type = FILTER_SUGGESTION;

  /**
   * The amount of results for this filter query
   */
  @autoserialize
  count: number;
}
