/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { rendersSuggestionResult } from '../../suggestion.decorator';
import { ObjectSuggestion } from '../../../../../../core/search/models/object-suggestion.model';
import { DSpaceObject } from '../../../../../../../app/core/shared/dspace-object.model';
import { SuggestionResultComponent } from '../suggestion-result.component';
import {
  GET_DSO_PAGE_ROUTE_OPERATOR
} from '../../../../../utils/dspace-object.utils';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { RemoteData } from '../../../../../../../app/core/data/remote-data';
import { hasNoValue, hasValue } from '../../../../../../../app/shared/empty.util';
import {
  getFirstSucceededRemoteData,
  getFirstSucceededRemoteDataPayload
} from '../../../../../../../app/core/shared/operators';
import { LinkService } from '../../../../../../../app/core/cache/builders/link.service';
import { DSpaceObjectDataService } from '../../../../../../../app/core/data/dspace-object-data.service';
import { Observable } from 'rxjs';

/**
 * This component renders a suggestion result for an object suggestion
 */
@Component({
  selector: 'ds-object-suggestion-result',
  styleUrls: ['./object-suggestion-result.component.scss', '../suggestion-result.component.scss'],
  templateUrl: './object-suggestion-result.component.html'
})
@rendersSuggestionResult(ObjectSuggestion)
export class ObjectSuggestionResultComponent extends SuggestionResultComponent<ObjectSuggestion<DSpaceObject>> implements OnInit {

  /**
   * The element ref to the link
   */
  @ViewChild('link', {static: false}) link: ElementRef;

  constructor(
    private router: Router,
    private linkService: LinkService,
    @Inject(GET_DSO_PAGE_ROUTE_OPERATOR) private getDSOPageRoute: (dso: DSpaceObject) => string,
    private dsoService: DSpaceObjectDataService) {
    super();
  }

  ngOnInit(): void {
    if (hasValue(this.suggestion.label)) {
      this.displayValue = [].concat(...Object.values(this.suggestion.hitHighlights))
        .find((hitHighlight: string) => hitHighlight.replace(/<[^>]*>?/gm, '') === this.suggestion.label) || this.suggestion.label;
    }
  }

  /**
   * Method to navigate to the right dso detail page when clicking the suggestion result when it's already active
   */
  select() {
    if (hasNoValue(this.suggestion.dspaceObject)) {
      this.suggestion = Object.assign(
        new ObjectSuggestion(),
        this.suggestion,
        {
          dspaceObject: this.dsoService.findByHref(this.suggestion._links.dspaceObject.href) as Observable<RemoteData<DSpaceObject>>
        });
    }
    if (this.active.getValue()) {
      this.suggestion.dspaceObject.pipe(
        getFirstSucceededRemoteData(),
        take(1)
      ).subscribe((dsoRD: RemoteData<DSpaceObject>) => {
          this.router.navigate([this.getDSOPageRoute(dsoRD.payload)], {});
        }
      );
    }
    return false;
  }

  /**
   * Method to set the focus on the link in the view
   */
  focus() {
    this.link.nativeElement.focus();
  }
}
