/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { DSpaceObject } from '../../../app/core/shared/dspace-object.model';
import { Item } from '../../../app/core/shared/item.model';
import { Collection } from '../../../app/core/shared/collection.model';
import { Community } from '../../../app/core/shared/community.model';
import { InjectionToken } from '@angular/core';
import { getItemPageRoute } from '../../../app/item-page/item-page-routing-paths';
import { getCollectionPageRoute } from '../../../app/collection-page/collection-page-routing-paths';
import { getCommunityPageRoute } from '../../../app/community-page/community-page-routing-paths';


export const GET_DSO_PAGE_ROUTE_OPERATOR = new InjectionToken<(dso: DSpaceObject) => string>('getDSOPageRoute', {
  providedIn: 'root',
  factory: () => getDSOPageRoute
});

// TODO rethink when we add handle resolving
export function getDSOPageRoute(dspaceObject: DSpaceObject): string {
  if (dspaceObject instanceof Item) {
    return getItemPageRoute(dspaceObject);
  }
  if (dspaceObject instanceof Collection) {
    return getCollectionPageRoute(dspaceObject.uuid);
  }
  if (dspaceObject instanceof Community) {
    return getCommunityPageRoute(dspaceObject.uuid);
  }
}
