/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component } from '@angular/core';
import {
  AuthNavMenuComponent as BaseComponent,
} from '../../../../../app/shared/auth-nav-menu/auth-nav-menu.component';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../app/app.reducer';
import { HostWindowService } from '../../../../../app/shared/host-window.service';
import { hasValue } from '../../../../../app/shared/empty.util';
import { AtmireAuthService } from '../../../../../app-atmire/core/auth/atmire-auth.service';
import { switchMap } from 'rxjs/operators';
import { of as observableOf } from 'rxjs';

/**
 * Component representing the {@link AuthNavMenuComponent} of a page
 */
@Component({
  selector: 'ds-auth-nav-menu',
  templateUrl: 'auth-nav-menu.component.html',
  // templateUrl: '../../../../../app/shared/auth-nav-menu/auth-nav-menu.component.html',
  // styleUrls: ['auth-nav-menu.component.scss']
  styleUrls: ['../../../../../app/shared/auth-nav-menu/auth-nav-menu.component.scss']
})
export class AuthNavMenuComponent extends BaseComponent {
  /**
   * The shibboleth authentication location url.
   * @type {string}
   */
  public location: string;

  redirectRoute$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

  constructor(protected store: Store<AppState>,
              protected windowService: HostWindowService,
              protected authService: AtmireAuthService) {
    super(store, windowService, authService);

  }

  ngOnInit(): void {
    super.ngOnInit();
    this.sub = this.isAuthenticated.pipe(
      switchMap((isAuthenticated: boolean) => {
        if (isAuthenticated) {
          return observableOf(undefined);
        } else {
          return this.authService.getSsoServerUrl();
        }
      })
    ).subscribe((path) => this.redirectRoute$.next(path));
  }

  ngOnDestroy(): void {
    if (hasValue(this.sub)) {
      this.sub.unsubscribe();
    }
  }
}
