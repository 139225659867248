/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component } from '@angular/core';

import { Observable } from 'rxjs';
import { find } from 'rxjs/operators';
import { listableObjectComponent } from '../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { ViewMode } from '../../../../../../../app/core/shared/view-mode.model';
import { WorkflowItemSearchResult } from '../../../../../../../app/shared/object-collection/shared/workflow-item-search-result.model';
import { AtmireSearchResultDetailElementComponent } from '../../../../../../../app-atmire/shared/object-detail/my-dspace-result-detail-element/atmire-search-result-detail-element.component';
import { WorkflowItem } from '../../../../../../../app/core/submission/models/workflowitem.model';
import { Item } from '../../../../../../../app/core/shared/item.model';
import { LinkService } from '../../../../../../../app/core/cache/builders/link.service';
import { followLink } from '../../../../../../../app/shared/utils/follow-link-config.model';
import { RemoteData } from '../../../../../../../app/core/data/remote-data';
import { isNotUndefined } from '../../../../../../../app/shared/empty.util';
import { Context } from '../../../../../../../app/core/shared/context.model';
import { MultilingualMetadataService } from '../../../../../../../app-atmire/core/shared/multilingual-metadata.service';
import {
  MyDspaceItemStatusType
} from '../../../../../../../app/shared/object-collection/shared/badges/my-dspace-status-badge/my-dspace-item-status-type';

/**
 * This component renders workflowitem object for the search result in the detail view.
 */
@Component({
  selector: 'ds-workflow-item-search-result-detail-element',
  styleUrls: ['../../../../../../../app/shared/object-detail/my-dspace-result-detail-element/search-result-detail-element.component.scss'],
  templateUrl: '../../../../../../../app/shared/object-detail/my-dspace-result-detail-element/workflow-item-search-result/workflow-item-search-result-detail-element.component.html',
})

@listableObjectComponent(WorkflowItemSearchResult, ViewMode.DetailedListElement, Context.Any, 'atmire')
export class WorkflowItemSearchResultDetailElementComponent extends AtmireSearchResultDetailElementComponent<WorkflowItemSearchResult, WorkflowItem> {

  /**
   * The item object that belonging to the result object
   */
  public item: Item;

  /**
   * Represent item's status
   */
  public status = MyDspaceItemStatusType.WORKFLOW;

  constructor(
    protected linkService: LinkService,
    protected multilingualMetadataService: MultilingualMetadataService,
  ) {
    super(multilingualMetadataService);
  }

  /**
   * Initialize all instance variables
   */
  ngOnInit() {
    super.ngOnInit();
    this.linkService.resolveLink(this.dso, followLink('item'));
    this.initItem(this.dso.item as Observable<RemoteData<Item>>);
  }

  /**
   * Retrieve item from result object
   */
  initItem(item$: Observable<RemoteData<Item>>) {
    item$.pipe(
      find((rd: RemoteData<Item>) => rd.hasSucceeded && isNotUndefined(rd.payload))
    ).subscribe((rd: RemoteData<Item>) => {
      this.item = rd.payload;
    });
  }

}
