/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { HeaderComponent as BaseComponent } from '../../../../app/header/header.component';
import { Component } from '@angular/core';

@Component({
  selector: 'ds-header',
  styleUrls: ['../../../../app/header/header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent extends BaseComponent {

}
