/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { GenericConstructor } from '../../../../../app/core/shared/generic-constructor';
import { Suggestion } from '../../../../core/search/models/suggestion.model';
import { DSpaceObject } from '../../../../../app/core/shared/dspace-object.model';
import { hasNoValue } from '../../../../../app/shared/empty.util';

export const DEFAULT_ITEM_TYPE = 'item';
/**
 * Collection of all types of suggestion previews, mapped onto their matching suggestion constructor
 */
const suggestionPreviewMap = new Map();

/**
 * Collection of all types of object suggestion previews, mapped onto their matching dspace object constructor
 */
const objectSuggestionPreviewMap = new Map();

/**
 * Collection of all types of object suggestion previews, mapped onto their matching dspace object constructor
 */
const itemSuggestionPreviewMap = new Map();

/**
 * Collection of all types suggestion results, mapped onto their matching suggestion constructor
 */
const suggestionResultMap = new Map();

/**
 * Stores a suggestion constructor as a key, with a matching suggestion preview component constructor as value
 */
export function rendersSuggestionPreview(searchSuggestion: GenericConstructor<Suggestion>) {
  return function decorator(suggestionPreview: any) {
    if (!suggestionPreview) {
      return;
    }
    suggestionPreviewMap.set(searchSuggestion, suggestionPreview);
  };
}

/**
 * Retrieves the correct suggestion preview component's constructor, based on a suggestion's constructor
 */
export function getSuggestionPreviewRendererFor(searchSuggestion: GenericConstructor<Suggestion>) {
  return suggestionPreviewMap.get(searchSuggestion);
}

/**
 * Stores a DSpaceObject constructor as a key, with a matching DSpaceObject suggestion preview component constructor as value
 */
export function rendersObjectSuggestionPreview(dso: GenericConstructor<DSpaceObject>) {
  return function decorator(suggestionPreview: any) {
    if (!suggestionPreview) {
      return;
    }
    objectSuggestionPreviewMap.set(dso, suggestionPreview);
  };
}
/**
 * Stores a DSpaceObject constructor as a key, with a matching DSpaceObject suggestion preview component constructor as value
 */
export function rendersItemSuggestionPreview(type: string) {
  return function decorator(suggestionPreview: any) {
    if (!suggestionPreview) {
      return;
    }
    itemSuggestionPreviewMap.set(type, suggestionPreview);
  };
}

/**
 * Retrieves the correct DSpaceObject suggestion preview component's constructor, based on a DSpaceObject's constructor
 */
export function getItemSuggestionPreviewRendererFor(type: string) {
  let previewComp = itemSuggestionPreviewMap.get(type);
  if (hasNoValue(previewComp)) {
    previewComp = itemSuggestionPreviewMap.get(DEFAULT_ITEM_TYPE);
  }
  return previewComp;
}

/**
 * Retrieves the correct DSpaceObject suggestion preview component's constructor, based on a DSpaceObject's constructor
 */
export function getObjectSuggestionPreviewRendererFor(dso: GenericConstructor<DSpaceObject>) {
  return objectSuggestionPreviewMap.get(dso);
}

/**
 * Stores a suggestion constructor as a key, with a matching suggestion result component constructor as value
 */
export function rendersSuggestionResult(searchSuggestion: GenericConstructor<Suggestion>) {
  return function decorator(suggestionResult: any) {
    if (!suggestionResult) {
      return;
    }
    suggestionResultMap.set(searchSuggestion, suggestionResult);
  };
}

/**
 * Retrieves the correct suggestion result component's constructor, based on a suggestion's constructor
 */
export function getSuggestionResultRendererFor(searchSuggestion: GenericConstructor<Suggestion>) {
  return suggestionResultMap.get(searchSuggestion);
}
