import { ListableObject } from '../object-collection/shared/listable-object.model';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { WidthCategory } from '../host-window.service';
import { combineLatest, Observable } from 'rxjs';
import { hasNoValue, hasValue } from '../empty.util';
import { RemoteData } from '../../core/data/remote-data';
import { PaginatedList } from '../../core/data/paginated-list.model';

export const columnsFromArray = (
  objects$: Observable<ListableObject[]>,
  widthCategory: Observable<WidthCategory>,
): Observable<ListableObject[][]> => {
  const nbColumns$ = widthCategory.pipe(
    map((widthCat: WidthCategory) => {
      switch (widthCat) {
        case WidthCategory.XL:
        case WidthCategory.LG: {
          return 3;
        }
        case WidthCategory.MD:
        case WidthCategory.SM: {
          return 2;
        }
        default: {
          return 1;
        }
      }
    }),
    distinctUntilChanged()
  ).pipe(startWith(3));

  return combineLatest([
    nbColumns$,
    objects$
  ]).pipe(map(([nbColumns, objects]) => {
    if (hasValue(objects)) {
      const result = [];

      objects.forEach((obj: ListableObject, i: number) => {
        const colNb = i % nbColumns;
        let col = result[colNb];
        if (hasNoValue(col)) {
          col = [];
        }
        result[colNb] = [...col, obj];
      });
      return result;
    } else {
      return [];
    }
  }));
};

export const columnsFromRD = (
  objectsRD$: Observable<RemoteData<PaginatedList<ListableObject>>>,
  widthCategory: Observable<WidthCategory>,
): Observable<ListableObject[][]> => {
  return columnsFromArray(
    objectsRD$.pipe(
      map(objectsRD => {
          if (hasValue(objectsRD) && hasValue(objectsRD.payload) && hasValue(objectsRD.payload.page)) {
            return objectsRD.payload.page;
          } else {
            return [];
          }
        }
      )
    ),
    widthCategory,
  );
};
