/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { AfterViewInit, Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { StyledItemPageFieldComponent } from '../specific-field/generic/styled-item-page-field.component';
import { isNotEmpty } from '../../../../app/shared/empty.util';

@Component({
  selector: 'ds-styled-item-section',
  templateUrl: './styled-item-section.component.html',
  styleUrls: ['../../../shared/styles/content-styles.scss', './styled-item-section.component.scss']
})
export class StyledItemSectionComponent implements AfterViewInit {
  /**
   * Whether or not all metadata values of all fields within this section are empty
   */
  empty = false;

  /**
   * Class(es) to assign to the section wrapper
   */
  @Input() sectionClass: string;

  @ContentChildren(StyledItemPageFieldComponent, { descendants: true }) fields: QueryList<StyledItemPageFieldComponent>;

  ngAfterViewInit(): void {
    this.empty = this.fields.map(field => field.empty).every(Boolean);
  }
}
