/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ItemMetadataComponent } from '../../../../app/item-page/edit-item-page/item-metadata/item-metadata.component';
import { Observable } from 'rxjs';
import { ItemDataService } from '../../../../app/core/data/item-data.service';
import { ObjectUpdatesService } from '../../../../app/core/data/object-updates/object-updates.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from '../../../../app/shared/notifications/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { CollectionDataService } from '../../../../app/core/data/collection-data.service';
import { AuthService } from '../../../../app/core/auth/auth.service';
import { BitstreamDataService } from '../../../../app/core/data/bitstream-data.service';
import { map, take } from 'rxjs/operators';
import { RequestService } from '../../../../app/core/data/request.service';
import { ObjectCacheService } from '../../../../app/core/cache/object-cache.service';

@Component({
  selector: 'ds-atmire-item-template-metadata',
  styleUrls: ['./item-metadata.component.scss'],
  templateUrl: './item-metadata.component.html',
})
/**
 * Component for displaying an item's metadata edit page
 */
export class AtmireItemTemplateMetadataComponent extends ItemMetadataComponent {
  /**
   * Emit an event when the user submits the form
   */
  @Output() submitForm: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Emit an event when the user discards the form
   */
  @Output() discardForm: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Is the logo marked to be deleted?
   */
  @Input() markLogoForDeletion = false;

  /**
   * Are there any changes made to the logo?
   */
  @Input() hasLogoChanges = false;

  constructor(
    public itemService: ItemDataService,
    public objectUpdatesService: ObjectUpdatesService,
    public router: Router,
    public notificationsService: NotificationsService,
    public translateService: TranslateService,
    public route: ActivatedRoute,
    protected collectionDataService: CollectionDataService,
    protected authService: AuthService,
    protected translate: TranslateService,
    protected bitstreamService: BitstreamDataService,
    protected requestService: RequestService,
    protected objectCache: ObjectCacheService,
  ) {
    super(itemService, objectUpdatesService, router, notificationsService, translateService, route);
  }

  public submit(): void {
    super.submit();
    this.submitForm.emit();
  }

  /**
   * The form contains changes when metadata has been changed, or the thumbnail
   */
  hasChanges(): Observable<boolean> {
    return super.hasChanges().pipe(
      map((hasChanges) => hasChanges || this.hasLogoChanges || this.markLogoForDeletion),
    );
  }

  /**
   * Only call super.discard when metadata form contains changes
   * Emit a discard event so the parent component can deal with the rest
   */
  discard() {
    super.hasChanges().pipe(
      take(1),
    ).subscribe((hasChanges) => {
      if (hasChanges) {
        super.discard();
      }
      this.discardForm.emit();
    });
  }
}
