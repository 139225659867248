/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */

import { Component, Input } from '@angular/core';
import { Item } from '../../../../app/core/shared/item.model';
import { AlertType } from '../../../../app/shared/alert/aletr-type';

const CONTENT_PREFIX = 'item.page.banner.content.';

/**
 * Shows a banner based on the values of an Item's (virtual) metadata
 *   - the MDV of {@link field} is used for the i18n key of the banner (if this field has no value, no banner will be shown)
 *   - MDVs starting with {@link interpolationFieldPrefix} are interpolated into the banner
 *
 * It is up to the backend to provide the correct metadata for all cases
 * It is up to the frontend to manage the i18n text
 */
@Component({
  selector: 'ds-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent {
  @Input()
  item: Item;

  @Input()
  field = 'fscdoc.banner';

  @Input()
  interpolationFieldPrefix = 'fscdoc.banner.';

  /**
   * The type of alert to show.
   */
  @Input()
  type: AlertType = AlertType.Info;


  /**
   * The type of banner to show, as specified in the metadata {@link field}.
   * If this property is falsy, no banner will be shown.
   */
  bannerType: string;

  /**
   * Values to interpolate into the banner.
   * Which of these will end up being used depends on the banner that is displayed.
   * When adding support for a new type of banner, make sure to add any new interpolation values here.
   * @private
   */
  params: { [key: string]: string };

  ngOnInit() {
    this.bannerType = this.item?.firstMetadataValue(this.field);
    this.params = Object.keys(this.item?.metadata || {})
                        .filter(mdf => mdf.startsWith(this.interpolationFieldPrefix))
                        .reduce((params, mdf) => ({
                          ...params,
                          [mdf.substr(this.interpolationFieldPrefix.length)]: this.item.firstMetadataValue(mdf)
                        }), {});
  }
}
