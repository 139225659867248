/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { deserialize, inheritSerialization } from 'cerialize';
import { Suggestion } from './suggestion.model';
import { typedObject } from '../../../../app/core/cache/builders/build-decorators';
import { DSpaceObject } from '../../../../app/core/shared/dspace-object.model';
import { SUGGESTION_CATEGORY } from '../resource-types/suggestion-category.resource-type';
import { HALLink } from '../../../../app/core/shared/hal-link.model';
import { PaginatedList } from '../../../../app/core/data/paginated-list.model';

@typedObject
@inheritSerialization(DSpaceObject)
export class SuggestionCategory extends DSpaceObject {

  static type = SUGGESTION_CATEGORY;

  /**
   * The {@link HALLink}s for this SuggestionCategory
   */
  @deserialize
  _links: {
    self: HALLink;
    values: HALLink;
  };

  /**
   * The found search suggestion values in this category
   */
  values: PaginatedList<Suggestion>;
}
