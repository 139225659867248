/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, OnInit } from '@angular/core';
import { Item } from '../../../../../../../../app/core/shared/item.model';
import {
  DEFAULT_ITEM_TYPE,
  rendersItemSuggestionPreview,
} from '../../../suggestion.decorator';
import { DSOSuggestionPreviewComponent } from '../dso-suggestion-preview.component';
import { BitstreamDataService } from '../../../../../../../../app/core/data/bitstream-data.service';
import { Observable } from 'rxjs';
import { Bitstream } from '../../../../../../../../app/core/shared/bitstream.model';
import {
  getFirstSucceededRemoteListPayload
} from '../../../../../../../../app/core/shared/operators';
import { DSONameService } from '../../../../../../../../app/core/breadcrumbs/dso-name.service';
import { map } from 'rxjs/operators';

/**
 * This component renders a preview for a item object suggestion
 * It shows a thumbnail, some metadata and bitstreams
 */

@Component({
  selector: 'ds-item-suggestion-preview',
  styleUrls: ['./item-suggestion-preview.component.scss'],
  templateUrl: './item-suggestion-preview.component.html'
})
@rendersItemSuggestionPreview(DEFAULT_ITEM_TYPE)
export class ItemSuggestionPreviewComponent extends DSOSuggestionPreviewComponent<Item> implements OnInit {
  bitstreams$: Observable<Bitstream[]>;
  primaryBitstream$: Observable<Bitstream>;

  constructor(
    protected bitstreamDataService: BitstreamDataService,
    public dsoNameService: DSONameService,
  ) {
    super();
  }

  ngOnInit(): void {
    // TODO refactor this to return RemoteData, and the template to deal with loading and errors
    this.bitstreams$ = this.bitstreamDataService.findAllByItemAndBundleName(
      this.dso, 'ORIGINAL', {
        elementsPerPage: 5,  // same amount as on Item pages
      }
    ).pipe(
      getFirstSucceededRemoteListPayload()
    );

    this.primaryBitstream$ = this.bitstreams$.pipe(
      map((bitstreams: Bitstream[]) => bitstreams[0])
    );
  }
}
