/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Input, OnInit } from '@angular/core';
import { ComcolPageContentComponent } from '../../../../app/shared/comcol/comcol-page-content/comcol-page-content.component';
import { DSpaceObject } from '../../../../app/core/shared/dspace-object.model';
import { MultilingualMetadataService } from '../../../core/shared/multilingual-metadata.service';

@Component({
  selector: 'ds-multilingual-comcol-page-content',
  templateUrl: '../../../../app/shared/comcol/comcol-page-content/comcol-page-content.component.html',
  styleUrls: ['../../../../app/shared/comcol/comcol-page-content/comcol-page-content.component.scss'],
})
export class MultilingualComcolPageContentComponent extends ComcolPageContentComponent {

  // The metadata field to render. Might contain HTML.
  @Input() field: string | string[];

  // The Community/Collection
  @Input() dso: DSpaceObject;

  // The content to render. Might be HTML.
  content: string;

  constructor(
    protected multilingualMetadataService: MultilingualMetadataService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.content = this.multilingualMetadataService.firstValueCurrentLang(this.dso.metadata, this.field);
  }
}
