/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component } from '@angular/core';
import {
  listableObjectComponent
} from '../../../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { ViewMode } from '../../../../../../../../../app/core/shared/view-mode.model';
import {
  ItemSearchResult
} from '../../../../../../../../../app/shared/object-collection/shared/item-search-result.model';
import { Context } from '../../../../../../../../../app/core/shared/context.model';
import { ItemSearchResultListElementComponent } from '../item/item-search-result-list-element.component';

@listableObjectComponent('PublicationSearchResult', ViewMode.ListElement, Context.RelatedItems, 'client')
@listableObjectComponent(ItemSearchResult, ViewMode.ListElement, Context.RelatedItems, 'client')
@Component({
  selector: 'ds-related-item-search-result-list-element-client',
  // styleUrls: ['./related-item-search-result-list-element.component.scss'],
  // styleUrls: ['../../../../../../../../../app/shared/object-list/search-result-list-element/item-search-result/item-types/item/item-search-result-list-element.component.scss'],
  styleUrls: ['../item/item-search-result-list-element.component.scss'],
  // templateUrl: './related-item-search-result-list-element.component.html'
  // templateUrl: '../../../../../../../../../app/shared/object-list/search-result-list-element/item-search-result/item-types/item/item-search-result-list-element.component.html'
  templateUrl: '../item/item-search-result-list-element.component.html',
})
export class RelatedItemSearchResultListElementComponent extends ItemSearchResultListElementComponent {
  showThumbnail = false;
}
