/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Input } from '@angular/core';
import { ComcolPageLogoComponent } from '../../app/shared/comcol/comcol-page-logo/comcol-page-logo.component';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MultilingualMetadataService } from '../../app-atmire/core/shared/multilingual-metadata.service';
import { DSpaceObject } from '../../app/core/shared/dspace-object.model';
import { Bitstream } from '../../app/core/shared/bitstream.model';


@Component({
  selector: 'ds-faq-button',
  styleUrls: ['faq-button.component.scss'],
  templateUrl: 'faq-button.component.html',
})
export class FaqButtonComponent {

  @Input() dso: DSpaceObject;
  @Input() logo: Bitstream;
  public modalRef: NgbModalRef;
  faq: string;
  title: string;

  constructor(protected modalService: NgbModal,
              protected multilingualMetadataService: MultilingualMetadataService,) {
  }

  public ngOnInit(): void {
    this.faq = this.multilingualMetadataService.firstValueCurrentLang(this.dso.metadata, 'dc.description.faq');
    this.title = this.multilingualMetadataService.firstValueCurrentLang(this.dso.metadata, 'dc.title');
  }

  openVirtualMetadataModal(content: any) {
    this.modalRef = this.modalService.open(content);
  }

  closeVirtualMetadataModal() {
    this.modalRef.close();
  }

}
