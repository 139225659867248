<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<footer class="text-lg-start">
  <div *ngIf="showTopFooter" class="top-footer">
    <div class="container p-4 d-flex flex-wrap justify-content-center row mx-auto">

      <h5 class="col m-auto pb-3">{{ 'footer.quick-links.title' | translate }}</h5>

      <ul class="col text-center list-unstyled m-auto">
        <li>
          <a href="https://fsc.org/en/about-us" target="_blank" rel="noopener noreferrer">
            {{ 'footer.quick-links.about-FSC' | translate }}
          </a>
        </li>
        <li>
          <a href="https://fsc.org/en/contact-us" target="_blank" rel="noopener noreferrer">
            {{ 'footer.quick-links.contact-us' | translate }}
          </a>
        </li>
        <!--<li>
          <a href="https://connect.fsc.org/connect-home-public" target="_blank" rel="noopener noreferrer">
          {{ 'footer.quick-links.fsc-connect' | translate }}
          </a>
        </li>-->
      </ul>
      <ul class="col text-center list-unstyled m-auto">
        <li>
          <a href="javascript:void(0);" (click)="showCookieSettings()">
            {{ 'footer.quick-links.change-your-cookie-consent' | translate }}
          </a>
        </li>
      </ul>

    </div>
  </div>

  <!-- Copyright -->
  <div class="bottom-footer p-1 d-flex flex-column justify-content-center align-items-center text-white">
    <div class="container fsc-footer px-4 pt-5 pb-3 justify-content-center row mx-auto">
      <a class="col pb-2" href="https://fsc.org/" target="_blank" rel="noopener noreferrer">
        <img [alt]="'fsc-slogan' | translate" src="assets/client/images/fsc-slogan.png" class="m-auto" height="65"
             width="142">
      </a>
      <div class="fsc-international col text-center mb-auto">
        <h5 class="mb-2">{{ 'footer.fsc-international' | translate }}</h5>
        <address>
          <p>Adenauerallee 134 53113 Bonn</p>
          <p>
            {{ 'footer.fsc-international.fax' | translate }}: <a href="fax:+492283676665">+49 (0) 228 367 66 65</a>
          </p>
          <p>
            {{ 'footer.fsc-international.email' | translate }}: <a href="mailto:info@fsc.org">info@fsc.org</a>
          </p>
          <p>
            {{ 'footer.fsc-international.phone' | translate }}: <a href="tel:+4922836766">+49 (0) 228 367 66</a>
          </p>
        </address>
      </div>
      <div class="fsc-quick-links col d-flex flex-column justify-content-center mb-auto">
        <h5 class="mb-2">{{ 'footer.quick-links.title' | translate }}</h5>
        <a href="https://fsc.org/en/about-us" target="_blank" rel="noopener noreferrer">
          {{ 'footer.quick-links.about-FSC' | translate }}
        </a>
        <a href="https://fsc.org/en/contact-us" target="_blank" rel="noopener noreferrer">
          {{ 'footer.quick-links.contact-us' | translate }}
        </a>
      </div>
    </div>
    <hr>
    <div class="content-container copyright-footer d-flex flex-column flex-md-row my-2">
      <p class="mx-2 my-1">
        {{ 'footer.custom.trademark' | translate }}
      </p>
      <p class="mx-2 my-1">
        <u>
          <a [routerLink]="[getPrivacyPath()]">
            {{ 'footer.legal-notice-privacy-policy-statement' | translate }}
          </a>
        </u>
      </p>
      <p class="mx-2 my-1">
        {{ 'footer.custom.copyright' | translate }}
      </p>
    </div>
  </div>
  <!-- Copyright -->
</footer>
