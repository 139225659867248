/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component } from '@angular/core';
import { ListableObject } from '../../../../../app/shared/object-collection/shared/listable-object.model';
import { DSpaceObject } from '../../../../../app/core/shared/dspace-object.model';
import { MetadataMapInterface } from '../../../../../app/core/shared/metadata.models';
import { AbstractListableElementComponent } from '../../../../../app/shared/object-collection/shared/object-collection-element/abstract-listable-element.component';
import { MultilingualMetadataService } from '../../../../core/shared/multilingual-metadata.service';

@Component({
  selector: 'ds-atmire-abstract-object-element',
  template: ``,
})
export class AtmireAbstractListableElementComponent<T extends ListableObject> extends AbstractListableElementComponent<T> {

  constructor(protected multilingualMetadataService: MultilingualMetadataService) {
    super();
  }

  /**
   * Returns the metadata of this element's object.
   * Assumes the object extends DSpaceObject. If this is not the case for a specific impementation, it should
   * override this getter
   * @protected
   */
  protected get metadata(): MetadataMapInterface | MetadataMapInterface[] {
    if (this.object instanceof DSpaceObject) {
      return this.object.metadata;
    } else {
      throw new Error(`Listable element type '${this.object.constructor.name}' is not a DSpaceObject subclass`);
    }
  }

  /**
   * Gets all matching metadata string values.
   *
   * @param {string|string[]} keyOrKeys The metadata key(s) in scope. Wildcards are supported; see [[Metadata]].
   * @returns {string[]} the matching string values or an empty array.
   */
  allMetadataValues(keyOrKeys: string | string[]): string[] {
    return this.multilingualMetadataService.allValuesCurrentLang(this.metadata, keyOrKeys);
  }

  /**
   * Gets the first matching metadata string value.
   *
   * @param {string|string[]} keyOrKeys The metadata key(s) in scope. Wildcards are supported; see [[Metadata]].
   * @returns {string} the first matching string value, or `undefined`.
   */
  firstMetadataValue(keyOrKeys: string | string[]): string {
    return this.multilingualMetadataService.firstValueCurrentLang(this.metadata, keyOrKeys);
  }
}
