/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { NgModule } from '@angular/core';
import { SharedModule } from '../../app/shared/shared.module';
import { ConditionalNoticeComponent } from './simple/conditional-notice/conditional-notice.component';
import { ItemPageForestFieldComponent } from './simple/field-components/forest/item-page-forest-field.component';
import { ItemPageIdentifiersFieldComponent } from './simple/field-components/identifiers/item-page-identifiers-field.component';
import { ItemPageModule } from '../../app/item-page/item-page.module';
import { AtmireItemPageModule } from '../../app-atmire/item-page/atmire-item-page.module';
import { BannerComponent } from './simple/banner/banner.component';
import { ItemPageLinkFieldComponent } from './simple/field-components/link-field/item-page-link-field.component';
import { ClientMultilingualCollectionsComponent } from './field-components/multilingual-collections/multilingual-collections.component';
import { AtmireAppModule } from '../../app-atmire/atmire-app.module';

const ENTRY_COMPONENTS = [
  // put only entry components that use custom decorator
];

const DECLARATIONS = [
  ...ENTRY_COMPONENTS,
  ConditionalNoticeComponent,
  BannerComponent,
  ItemPageForestFieldComponent,
  ItemPageIdentifiersFieldComponent,
  ItemPageLinkFieldComponent,
];

@NgModule({
  imports: [
    SharedModule.withEntryComponents(),
    AtmireItemPageModule.withEntryComponents(),
    ItemPageModule,
  ],
  declarations: [
    ...DECLARATIONS
  ],
  exports: [
    ...DECLARATIONS
  ],
})
export class ClientItemPageModule {
  /**
   * NOTE: this method allows to resolve issue with components that using a custom decorator
   * which are not loaded during SSR otherwise
   */
  static withEntryComponents() {
    return {
      ngModule: ClientItemPageModule,
      providers: ENTRY_COMPONENTS.map((component) => ({ provide: component }))
    };
  }
}
