<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" rel="noopener noreferrer" [routerLink]="['/collections/' + object.id]" class="lead">
    {{firstMetadataValue('dc.title')}}
</a>
<span *ngIf="linkType == linkTypes.None" class="lead">
    {{firstMetadataValue('dc.title')}}
</span>
<div *ngIf="firstMetadataValue('dc.description.abstract')" class="text-muted abstract-text">
    {{firstMetadataValue('dc.description.abstract')}}
</div>
