/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { SearchInputFieldComponent } from '../shared/search-form/search-input-field/search-input-field.component';
import { filter, map, startWith } from 'rxjs/operators';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { expandSearchInput } from '../../app/shared/animations/slide';
import { SearchService } from '../../app/core/shared/search/search.service';
import { PaginationService } from '../../app/core/pagination/pagination.service';
import { SearchConfigurationService } from '../../app/core/shared/search/search-configuration.service';
import { HostWindowService, WidthCategory } from '../../app/shared/host-window.service';

/**
 * Atmire version of SearchNavbarComponent
 * This component contains too many changes (including removal/renaming of service/properties) to be themed
 * Instead, this component is used in a themed version of HeaderComponent
 */
@Component({
  selector: 'ds-atmire-search-navbar',
  templateUrl: './atmire-search-navbar.component.html',
  styleUrls: ['./atmire-search-navbar.component.scss'],
  animations: [expandSearchInput]
})
export class AtmireSearchNavbarComponent {

  // Whether or not the search bar is expanded, boolean for html ngIf, string fo AngularAnimation state change
  searchExpanded$ = new BehaviorSubject(false);
  isExpanded = 'collapsed';
  animationState$: Observable<string>;

  // Search input field
  @ViewChild('searchInput') searchField: SearchInputFieldComponent;

  constructor(private router: Router, private searchService: SearchService,
              private paginationService: PaginationService,
              private searchConfig: SearchConfigurationService,
              private hostWindowService: HostWindowService) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationStart)
    ).subscribe((event: NavigationStart) => {
      this.collapse();
    });
    this.animationState$ =
      combineLatest([this.hostWindowService.widthCategory, this.searchExpanded$])
        .pipe(
          map(([widthCategory, searchExpanded]: [WidthCategory, boolean]) => {
            if (!searchExpanded) {
              return 'collapsed';
            } else {
              if (widthCategory === WidthCategory.SM) {
                return 'expandedSm';
              } else if (widthCategory === WidthCategory.LG ) {
                return 'expandedLg';
              } else if (widthCategory === WidthCategory.XL ) {
                return 'expandedXl';
              }
              return 'expanded';
            }
          }),
          startWith('collapsed')
        );
  }

  /**
   * Expands search bar by angular animation, see expandSearchInput
   */
  expand() {
    this.searchExpanded$.next(true);
    this.editSearch();
  }

  /**
   * Collapses & blurs search bar by angular animation, see expandSearchInput
   */
  collapse() {
    this.searchField.blur();
    this.searchExpanded$.next(false);
    this.isExpanded = 'collapsed';
  }

  /**
   * Focuses on input search bar so search can be edited
   */
  editSearch(): void {
    this.searchField.focus();
  }

  /**
   * Submits the search (on enter or on search icon click)
   */
  onSubmit() {
    this.collapse();
    const linkToNavigateTo = this.searchService.getSearchLink().split('/');
    this.searchField.submit();
    this.paginationService.updateRouteWithUrl(this.searchConfig.paginationID, linkToNavigateTo, { page: 1 }, { query: this.searchField.value });
  }
}
