/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { MenuResolver } from '../app/menu.resolver';
import { MenuService } from '../app/shared/menu/menu.service';
import { BrowseService } from '../app/core/browse/browse.service';
import { AuthorizationDataService } from '../app/core/data/feature-authorization/authorization-data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ScriptDataService } from '../app/core/data/processes/script-data.service';
import { Injectable } from '@angular/core';

/**
 * Menu entries specific to app-atmire are defined here.
 */
@Injectable()
export class AtmireMenuResolver extends MenuResolver {
  constructor(
    protected menuService: MenuService,
    protected browseService: BrowseService,
    protected authorizationService: AuthorizationDataService,
    protected modalService: NgbModal,
    protected scriptDataService: ScriptDataService,
  ) {
    super(menuService, browseService, authorizationService, modalService, scriptDataService);
  }

  createPublicMenu$(): Observable<boolean> {
    return super.createPublicMenu$();
  }

  createMainMenuSections(): void {
    super.createMainMenuSections();
  }

  createExportMenuSections(): void {
    super.createExportMenuSections();
  }

  createImportMenuSections(): void {
    super.createImportMenuSections();
  }

  createSiteAdministratorMenuSections(): void {
    super.createSiteAdministratorMenuSections();
  }

  createAccessControlMenuSections(): void {
    super.createAccessControlMenuSections();
  }
}
