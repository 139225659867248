<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<button type="button" class="btn btn-outline-secondary mr-2 faq-button"
        *ngIf="faq"
        [ngClass]="{'float-right': logo, 'float-left': !logo}"
        (click)="openVirtualMetadataModal(faqModal)">
  <span class="button-text align-middle"><i class="fa fa-info-circle info-icon"></i>FAQ</span>
</button>

<ng-template #faqModal>
  <div>
    <div class="modal-header">
      {{ 'faq.header' | translate: { title: title } }}
      <button type="button" class="close"
              (click)="closeVirtualMetadataModal()" aria-label="Close">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body preserve-line-breaks" [innerHTML]="faq">
    </div>
  </div>
</ng-template>
