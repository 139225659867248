/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Item } from '../../../../../app/core/shared/item.model';
import { MetadataValue } from '../../../../../app/core/shared/metadata.models';
import { hasNoValue, hasValue, isEmpty, isNotEmpty } from '../../../../../app/shared/empty.util';
import { TranslateService } from '@ngx-translate/core';

/**
 * Component to display information about a metadata field and its values using the styles provided
 */
@Component({
  selector: 'ds-styled-item-page-field',
  styleUrls: ['./styled-item-page-field.component.scss', '../../../../shared/styles/content-styles.scss'],
  templateUrl: './styled-item-page-field.component.html',
  preserveWhitespaces: true,
})
export class StyledItemPageFieldComponent implements OnInit {
  /**
   * Item to display metadata for
   */
  @Input() item: Item;

  /**
   * Label to display in front of the values (on the same line)
   */
  @Input() label: string;

  /**
   * Suffix to display after the label, to separate label from values
   */
  @Input() labelSuffix = ': ';

  /**
   * Metadata fields to display values for
   */
  @Input() fields: string[];

  /**
   * Css class(es) to assign to each metadata value
   */
  @Input() valueClass?: string;

  /**
   * Css class(es) to assign to the expand and collapse buttons of the metadata values
   * If not provided, it will assign a default opacity of 0.5 together with the same classes used for the values
   */
  @Input() expandClass?: string;

  /**
   * Css class(es) to assign to the label
   */
  @Input() labelClass = 'text-muted';

  /**
   * Tooltip to display for the label
   */
  @Input() labelTooltip?: string;

  /**
   * Max amount of metadata values to display upon loading the page
   * Expandable by clicking a button displaying the remaining values
   * When not provided, all values will display by default
   */
  @Input() initialSize?: number;

  /**
   * Display the values as links
   * Use "linkPrefix" if the values are incomplete links
   */
  @Input() displayLink = false;

  /**
   * Display the values as links
   * Use "linkPrefix" if the values are incomplete links
   */
  @Input() discoveryFilterLink?: string;

  @Input() linkText?: string;

  /**
   * Whether or not link values should open in a new tab
   */
  @Input() externalLink = false;

  /**
   * Whether or not the value displayed should be the full link, including "linkPrefix"
   * Only relevant when "displayLink" is true
   */
  @Input() linkAsValue = false;

  /**
   * Label to use for displaying the "show all" button
   * Allows an "amount" parameter for displaying how many values aren't shown yet
   */
  @Input() showAllLabel = 'item.page.fields.show-all';

  /**
   * Label to use for displaying the "show less" button
   * Allows an "amount" parameter for displaying how many values extra are being shown
   */
  @Input() showLessLabel = 'item.page.fields.show-less';

  /**
   * Whether or not the user should have access to a "show less" button once the values are expanded
   */
  @Input() collapsible = true;

  /**
   * The character or string to use for separating multiple values of a field.
   * Default is ',' (comma).
   */
  @Input() separator = ',';

  /**
   * Whether to render the values in an unordered list. When set to true, the {@link separator} and {@link ellipsis} is not shown.
   */
  @Input() ul = false;

  /**
   * Whether or not we are currently showing all values (only relevant when "initialSize" is provided)
   */
  showAll = false;

  /**
   * The list of metadata values to display
   */
  mdValues: string[];

  @Input() i18nKeyTransform?: (value: string) => string;

  /**
   * Whether or not the array of metadata values is empty
   * Will hide the entire component if empty
   */
  @HostBinding('class.d-none') empty = false;

  /**
   * Optional method to transform values to URLs. Defaults to the identity method.
   */
  @Input() linkTransform: (value: string) => string = (value: string) => value;

  constructor(protected translateService: TranslateService) {
  }

  ngOnInit(): void {

    this.mdValues = this.item.allMetadata(this.fields).map(mdValue => {
      if (this.i18nKeyTransform) {
        return this.translateService.instant(this.i18nKeyTransform(mdValue.value));
      } else {
        return mdValue.value;
      }
    });

    this.empty = isEmpty(this.mdValues);
    if (isEmpty(this.expandClass)) {
      this.expandClass = `${this.valueClass} hover-opacity-50`;
    }
  }

  showSeparator(idx: number): boolean {
    if (this.ul || hasNoValue(this.separator) || this.mdValues.length <= 1) {
      return false;
    }

    let limit = this.mdValues.length - 1;
    if (hasValue(this.initialSize) && !this.showAll) {
      limit = Math.min(this.initialSize, this.mdValues.length) - 1;
    }

    return idx < limit;
  }
}
