/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Input } from '@angular/core';
import { Suggestion } from '../../../../../core/search/models/suggestion.model';
import { SuggestionCategory } from '../../../../../core/search/models/suggestion-category.model';
import { SearchOptions } from '../../../../../../app/shared/search/models/search-options.model';
import { Context } from 'src/app/core/shared/context.model';

@Component({
  selector: 'ds-abstract-suggestion-preview-component',
  template: ``,
})
/**
 * Abstract component for suggestion previews
 */
export class SuggestionPreviewComponent<T extends Suggestion> {
  context: Context = Context.QuerySuggestionPreview;

  /**
   * The suggestion object to render a view for
   */
  @Input() suggestion: T;
  @Input() searchOptions: SearchOptions;
  @Input() category: SuggestionCategory;

}
