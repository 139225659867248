/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Input, Output, EventEmitter } from '@angular/core';

import { ThemedComponent } from '../../../../app/shared/theme-support/themed.component';
import { SearchInputFieldComponent } from './search-input-field.component';

/**
 * Themed wrapper for {@link SearchInputFieldComponent}
 */
@Component({
  selector: 'ds-themed-search-input-field',
  templateUrl: '../../../../app/shared/theme-support/themed.component.html',
})
export class ThemedSearchInputFieldComponent extends ThemedComponent<SearchInputFieldComponent> {

  protected inAndOutputNames: (keyof SearchInputFieldComponent & keyof this)[] = [
    'query',
    'scope',
    'useSearchOptions',
    'showButton',
    'placeholder',
    'inputSize',
    'brandColor',
    'queryChange',
  ];

  @Input() query: string;

  @Input() scope: string;

  @Input() useSearchOptions: boolean;

  @Input() showButton = true;

  @Input() placeholder = '';

  @Input() inputSize: undefined | 'sm' | 'lg';

  @Input() brandColor = 'primary';

  @Output() queryChange: EventEmitter<string> = new EventEmitter();

  protected getComponentName(): string {
    return 'SearchInputFieldComponent';
  }

  protected importThemedComponent(themeName: string): Promise<any> {
    return import(`../../../../themes/${themeName}/app/shared/search-form/search-input-field/search-input-field.component`);
  }

  protected importUnthemedComponent(): Promise<any> {
    return import('./search-input-field.component');
  }

}
