<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<ds-metadata-field-wrapper *ngIf="(this.collections$ | async)?.length > 0" [label]="label | translate">
  <div class="collections">
    <a *ngFor="let collection of (this.collections$ | async); let last=last;" [routerLink]="['/collections', collection.id]">
      <span>{{ getName(collection) }}</span><span *ngIf="!last" [innerHTML]="separator"></span>
    </a>
  </div>
</ds-metadata-field-wrapper>
