/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Inject, Injectable, Optional } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from '../../../app/app.reducer';
import { AuthRequestService } from '../../../app/core/auth/auth-request.service';
import { EPersonDataService } from '../../../app/core/eperson/eperson-data.service';
import { REQUEST, RESPONSE } from '@nguniversal/express-engine/tokens';
import { CookieService } from '../../../app/core/services/cookie.service';
import { HardRedirectService } from '../../../app/core/services/hard-redirect.service';
import { RouteService } from '../../../app/core/services/route.service';
import { NativeWindowRef, NativeWindowService } from '../../../app/core/services/window.service';
import { NotificationsService } from '../../../app/shared/notifications/notifications.service';
import { AuthService, LOGIN_ROUTE } from '../../../app/core/auth/auth.service';
import { Router } from '@angular/router';
import { getAuthenticationMethods, isAuthenticationLoading } from '../../../app/core/auth/selectors';
import { Observable, of as observableOf } from 'rxjs';
import { find, map, switchMap } from 'rxjs/operators';
import { AuthMethod } from '../../../app/core/auth/models/auth.method';
import { hasNoValue, hasValue, isEmpty } from '../../../app/shared/empty.util';
import { URLCombiner } from '../../../app/core/url-combiner/url-combiner';
import { AuthMethodType } from '../../../app/core/auth/models/auth.method-type';
import { APP_CONFIG, AppConfig } from '../../../config/app-config.interface';

export const PASSWORD_LOGIN_ROUTE = '/password-login';

/**
 * The auth service.
 */
@Injectable()
export class AtmireAuthService extends AuthService {

  constructor(
    @Inject(REQUEST) protected req: any,
    @Inject(NativeWindowService) protected _window: NativeWindowRef,
    @Optional() @Inject(RESPONSE) protected response: any,
    protected authRequestService: AuthRequestService,
    protected epersonService: EPersonDataService,
    protected router: Router,
    protected routeService: RouteService,
    protected storage: CookieService,
    protected store: Store<AppState>,
    protected hardRedirectService: HardRedirectService,
    protected notificationService: NotificationsService,
    protected translateService: TranslateService,
    @Inject(APP_CONFIG) protected appConfig: AppConfig,
  ) {
    super(req, _window, response, authRequestService, epersonService, router, routeService, storage, store, hardRedirectService, notificationService, translateService);
  }

  /**
   * Determines if authentication is loading
   * @returns {Observable<boolean>}
   */
  public isAuthenticationLoading(): Observable<boolean> {
    return this.store.pipe(select(isAuthenticationLoading));
  }

  public getSsoServerUrl(): Observable<string> {
    const authType = this.appConfig.atmire.ssoAuthenticationMethod;

    // If configured for password authentication for some reason, just redirect to /password-login
    if (authType === AuthMethodType.Password) {
      return observableOf(PASSWORD_LOGIN_ROUTE);
    }

    return this.isAuthenticationLoading().pipe(
      find((isLoading: boolean) => isLoading === false),
      switchMap(() => {
        return this.store.pipe(
          select(getAuthenticationMethods),
          map((methods: AuthMethod[]) => {
            return methods.find((method: AuthMethod) => method.authMethodType === authType);
          }),
          map((authMethod) => {
            if (hasNoValue(authMethod)) {
              console.error(`No authentication method of type '${authType}' is set up in the backend.`);
              return PASSWORD_LOGIN_ROUTE;
            }

            let redirectRoute = this.hardRedirectService.getCurrentRoute();
            if (isEmpty(redirectRoute) || redirectRoute === LOGIN_ROUTE) {
              redirectRoute = '/';
            }

            const correctRedirectUrl = new URLCombiner(this.hardRedirectService.getCurrentOrigin(), redirectRoute).toString();
            const ssoServerUrl = new URL(authMethod.location);

            const target = ssoServerUrl.searchParams.get('target');
            if (hasValue(target)) {
              const targetUrl = new URL(target);
              const targetRedirectUrl = ssoServerUrl.searchParams.get('redirectUrl');

              // Check whether the current page is different from the redirect url received from rest
              if (targetRedirectUrl !== correctRedirectUrl) {
                targetUrl.searchParams.set('redirectUrl', correctRedirectUrl);
                ssoServerUrl.searchParams.set('target', targetUrl.toString());
              }
            }

            return ssoServerUrl.toString();
          }),
        );

      })
    );
  }
}
