/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Input } from '@angular/core';
import { AtmireAbstractListableElementComponent } from '../../../../../../app-atmire/shared/object-collection/shared/object-collection-element/atmire-abstract-listable-element.component';
import { Context } from '../../../../../../app/core/shared/context.model';
import { listableObjectComponent } from '../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { ViewMode } from '../../../../../../app/core/shared/view-mode.model';
import { Collection } from '../../../../../../app/core/shared/collection.model';
import { LinkService } from '../../../../../../app/core/cache/builders/link.service';
import { hasNoValue, hasValue } from '../../../../../../app/shared/empty.util';
import { followLink } from '../../../../../../app/shared/utils/follow-link-config.model';
import { MultilingualMetadataService } from '../../../../../../app-atmire/core/shared/multilingual-metadata.service';
import { DSONameService } from '../../../../../../app/core/breadcrumbs/dso-name.service';

/**
 * Component representing a grid element for collection
 */
@Component({
  selector: 'ds-collection-grid-element',
  styleUrls: ['./collection-grid-element.component.scss'],
  templateUrl: './collection-grid-element.component.html',
})
@listableObjectComponent(Collection, ViewMode.GridElement, Context.Any, 'atmire')
export class CollectionGridElementComponent extends AtmireAbstractListableElementComponent<Collection> {
  private _object: Collection;

  constructor(
    private linkService: LinkService,
    protected multilingualMetadataService: MultilingualMetadataService,
  ) {
    super(multilingualMetadataService);
  }

  // @ts-ignore
  @Input() set object(object: Collection) {
    this._object = object;
    if (hasValue(this._object) && hasNoValue(this._object.logo)) {
      this.linkService.resolveLink<Collection>(
        this._object,
        followLink('logo')
      );
    }
  }

  get object(): Collection {
    return this._object;
  }
}
