/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component } from '@angular/core';
import { ViewMode } from '../../../../../../../app/core/shared/view-mode.model';
import { TruncatableService } from '../../../../../../../app/shared/truncatable/truncatable.service';
import { CollectionSearchResult } from '../../../../../../../app/shared/object-collection/shared/collection-search-result.model';
import { listableObjectComponent } from '../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { LinkService } from '../../../../../../../app/core/cache/builders/link.service';
import { BitstreamDataService } from '../../../../../../../app/core/data/bitstream-data.service';
import { Collection } from '../../../../../../../app/core/shared/collection.model';
import { MultilingualMetadataService } from '../../../../../../../app-atmire/core/shared/multilingual-metadata.service';
import { hasNoValue, hasValue } from '../../../../../../../app/shared/empty.util';
import { followLink } from '../../../../../../../app/shared/utils/follow-link-config.model';
import { AtmireSearchResultGridElementComponent } from '../../../../../../../app-atmire/shared/object-grid/search-result-grid-element/atmire-search-result-grid-element.component';
import { Context } from '../../../../../../../app/core/shared/context.model';

@Component({
  selector: 'ds-collection-search-result-grid-element',
  styleUrls: ['../../../../../../../app/shared/object-grid/search-result-grid-element/search-result-grid-element.component.scss', '../../../../../../../app/shared/object-grid/search-result-grid-element/collection-search-result/collection-search-result-grid-element.component.scss'],
  templateUrl: 'collection-search-result-grid-element.component.html'
})
/**
 * Component representing a grid element for a collection search result
 */
@listableObjectComponent(CollectionSearchResult, ViewMode.GridElement, Context.Any, 'atmire')
export class CollectionSearchResultGridElementComponent extends AtmireSearchResultGridElementComponent< CollectionSearchResult, Collection > {
  private _dso: Collection;

  constructor(
    private linkService: LinkService,
    protected truncatableService: TruncatableService,
    protected bitstreamDataService: BitstreamDataService,
    protected multilingualMetadataService: MultilingualMetadataService,
  ) {
    super(truncatableService, bitstreamDataService, multilingualMetadataService);
  }
}
