/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import {
  Component,
  ComponentFactoryResolver,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Suggestion } from '../../../../../core/search/models/suggestion.model';
import { GenericConstructor } from '../../../../../../app/core/shared/generic-constructor';
import { getSuggestionResultRendererFor } from '../suggestion.decorator';
import { SuggestionResultComponent } from './suggestion-result.component';
import { SuggestionResultDirective } from './suggestion-result.directive';
import { hasValue } from '../../../../../../app/shared/empty.util';
import { SuggestionCategory } from '../../../../../core/search/models/suggestion-category.model';
import { BehaviorSubject } from 'rxjs';

/**
 * Wrapper component that renders a specific suggestion's result entry based on the suggestion's type
 */
@Component({
  selector: 'ds-suggestion-result-wrapper',
  templateUrl: './suggestion-result-wrapper.component.html'
})
export class SuggestionResultWrapperComponent implements OnChanges {
  /**
   * Suggestion for this wrapper component
   */
  @Input() suggestion: Suggestion;

  /**
   * Whether or not this result is currently active
   */
  @Input() active: boolean;

  /**
   * The query suggestion category this result is part of
   */
  @Input() category: SuggestionCategory;

  /**
   * Directive used to place the specific suggestion result component
   */
  @ViewChild(SuggestionResultDirective, {static: true}) resultAnchor: SuggestionResultDirective;

  /**
   * The specific suggestion's result component
   */
  suggestionComponent: SuggestionResultComponent<Suggestion>;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
  }

  ngOnInit(): void {
    const suggestionComponent = this.getSuggestionResultComponent();

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(suggestionComponent);
    const viewContainerRef = this.resultAnchor.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    ((componentRef.instance) as SuggestionResultComponent<Suggestion>).suggestion = this.suggestion;
    this.suggestionComponent = ((componentRef.instance) as SuggestionResultComponent<Suggestion>);
    this.suggestionComponent.active = new BehaviorSubject<boolean>(false);
    this.suggestionComponent.category = this.category;
  }

  /**
   * Find the correct component based on the filter config's type
   */
  getSuggestionResultComponent() {
    const searchSuggestion: GenericConstructor<Suggestion> = this.suggestion.constructor as GenericConstructor<Suggestion>;
    return getSuggestionResultRendererFor(searchSuggestion);
  }

  /**
   * Method to set focus on the child suggestion result component
   */
  focus() {
    this.suggestionComponent.focus();
  }

  /**
   * With every change, check if the child component is still active and update it
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (hasValue(this.suggestionComponent) && hasValue(changes.active)) {
      this.suggestionComponent.active.next(changes.active.currentValue);
    }
  }
}
