/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Input } from '@angular/core';

/**
 * This component renders any content inside this wrapper.
 * The wrapper prints a label before the content (if available), on the same line
 */
@Component({
  selector: 'ds-metadata-field-line-wrapper',
  styleUrls: ['./metadata-field-line-wrapper.component.scss'],
  templateUrl: './metadata-field-line-wrapper.component.html',
})
export class MetadataFieldLineWrapperComponent {

  /**
   * The label (title) for the content
   */
  @Input() label: string;

  /**
   * Suffix to use after the label to separate label from values
   */
  @Input() labelSuffix = ':';

  /**
   * Css class(es) to assign to the label
   */
  @Input() labelClass = 'text-muted';

  /**
   * Tooltip to display for the label
   */
  @Input() labelTooltip?: string;
}
