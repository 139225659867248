<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<ds-atmire-truncatable [id]="dso.id" *ngIf="object !== undefined && object !== null" [disabledToggle]="true">
  <div class="item-list" [ngClass]="{'compact': compact}">
    <!-- Top Section -->
    <div class="w-100 d-flex flex-nowrap item-list-main">
      <div class="item-search-result-list-element-thumbnail-container" *ngIf="showThumbnail">
        <ds-themed-thumbnail class="item-list-thumbnail" [thumbnail]="dso?.thumbnail | async" [limitWidth]="false">
        </ds-themed-thumbnail>
      </div>
      <div class="item-list-summary-content">
        <div class="d-flex">
          <div class="flex-grow-1">
            <ds-truncatable-part [id]="dso.id" [minLines]="3">
              <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'"
                 rel="noopener noreferrer" [routerLink]="[itemPageRoute]" class="lead item-list-title"
                 [innerHTML]="dsoTitle">
              </a>
              <span *ngIf="linkType == linkTypes.None" class="lead item-list-title" [innerHTML]="dsoTitle"></span>
            </ds-truncatable-part>
          </div>
          <div class="expand-button p-2" *ngIf="showToggle" (click)="toggle()" role="button" aria-label="Expand">
            <i class="fa fa-chevron-{{ collapsed ? 'down' : 'up' }}"></i>
          </div>
        </div>

        <div class="d-block">
          <ds-styled-item-page-field [item]="dso"
                                     [valueClass]="'content-pill light-pill mb-2'"
                                     [separator]="undefined"
                                     [initialSize]="compact ? 3 : 5"
                                     [fields]="['dc.contributor','dc.contributor.author', 'dc.creator']"
                                     [label]="'item-search-result-list-element.author' | translate">
          </ds-styled-item-page-field>
        </div>

        <div class="d-flex flex-wrap justify-content-between">
          <ds-styled-item-page-field [item]="dso"
                                     [valueClass]="'mb-2'"
                                     [fields]="['dcterms.issued']"
                                     [label]="'item-search-result-list-element.issued' | translate">
          </ds-styled-item-page-field>
          <ds-client-styled-item-page-field [item]="dso"
                                            [valueClass]="'mb-2'"
                                            [fields]="['is.journalName', 'is.extent.volume', 'is.extent.number', 'is.extent.pages']"
                                            [label]="'item-search-result-list-element.source' | translate">
          </ds-client-styled-item-page-field>
          <ds-styled-item-page-field [item]="dso"
                                     [valueClass]="'mb-2'"
                                     [fields]="['dcterms.type']"
                                     [label]="'item-search-result-list-element.type' | translate">
          </ds-styled-item-page-field>
          <ds-styled-item-page-field class="w-100"
                                     [item]="dso"
                                     [valueClass]="'mb-2'"
                                     [initialSize]="3"
                                     [fields]="['fscdoc.code', 'is.identifier.standardCode', 'is.identifier.adviceCode', 'is.identifier.certificatecode']"
                                     [label]="'item-search-result-list-element.code' | translate">
          </ds-styled-item-page-field>
        </div>
      </div>
    </div>

    <!-- Bottom-Expandable Section -->
    <div #expandedContentContainer class="item-list-expanded-content-container w-100" #collapse="ngbCollapse" [(ngbCollapse)]="collapsed" *ngIf="showExpandableSection">
      <div #expandedContent class="item-list-expanded-content w-100" (scroll)="resolveScrollIndicators()">
        <ds-client-item-info-sections [dso]="dso">
        </ds-client-item-info-sections>
        <!-- Show more button -->
        <div class="mt-3 d-flex align-items-center justify-content-center">
          <a [routerLink]="[itemPageRoute]" class="btn show-more-btn ml-2">
            {{ 'item.page.show-more' | translate }}&nbsp;
          </a>
        </div>
      </div>
    </div>
  </div>
</ds-atmire-truncatable>
