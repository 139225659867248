/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { listableObjectComponent } from 'src/app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { WorkflowItemSearchResult } from '../../../../../../../../app/shared/object-collection/shared/workflow-item-search-result.model';
import { Context } from '../../../../../../../../app/core/shared/context.model';
import { ViewMode } from '../../../../../../../../app/core/shared/view-mode.model';
import { WorkflowItem } from '../../../../../../../../app/core/submission/models/workflowitem.model';
import { AtmireSearchResultListElementComponent } from '../../../../../../../../app-atmire/shared/object-list/search-result-list-element/atmire-search-result-list-element.component';
import { Item } from '../../../../../../../../app/core/shared/item.model';
import { LinkService } from '../../../../../../../../app/core/cache/builders/link.service';
import { TruncatableService } from '../../../../../../../../app/shared/truncatable/truncatable.service';
import { DSONameService } from '../../../../../../../../app/core/breadcrumbs/dso-name.service';
import { followLink } from '../../../../../../../../app/shared/utils/follow-link-config.model';
import { RemoteData } from '../../../../../../../../app/core/data/remote-data';
import { getAllSucceededRemoteData, getRemoteDataPayload } from '../../../../../../../../app/core/shared/operators';
import { MultilingualMetadataService } from '../../../../../../../../app-atmire/core/shared/multilingual-metadata.service';

/**
 * The component for displaying a list element for an workflow item on the admin workflow search page
 */
@listableObjectComponent(WorkflowItemSearchResult, ViewMode.ListElement, Context.AdminWorkflowSearch, 'atmire')
@Component({
  selector: 'ds-workflow-item-search-result-admin-workflow-list-element',
  styleUrls: ['../../../../../../../../app/admin/admin-workflow-page/admin-workflow-search-results/admin-workflow-search-result-list-element/workflow-item/workflow-item-search-result-admin-workflow-list-element.component.scss'],
  templateUrl: '../../../../../../../../app/admin/admin-workflow-page/admin-workflow-search-results/admin-workflow-search-result-list-element/workflow-item/workflow-item-search-result-admin-workflow-list-element.component.html'
})
export class WorkflowItemSearchResultAdminWorkflowListElementComponent extends AtmireSearchResultListElementComponent<WorkflowItemSearchResult, WorkflowItem> implements OnInit {

  /**
   * The item linked to the workflow item
   */
  public item$: Observable<Item>;

  constructor(
    private linkService: LinkService,
    protected truncatableService: TruncatableService,
    protected dsoNameService: DSONameService,
    protected multilingualMetadataService: MultilingualMetadataService,
  ) {
    super(truncatableService, dsoNameService, multilingualMetadataService);
  }

  /**
   * Initialize the item object from the workflow item
   */
  ngOnInit(): void {
    super.ngOnInit();
    this.dso = this.linkService.resolveLink(this.dso, followLink('item'));
    this.item$ = (this.dso.item as Observable<RemoteData<Item>>).pipe(getAllSucceededRemoteData(), getRemoteDataPayload());
  }
}
