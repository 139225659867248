<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<header>
  <div class="container">
    <div class="d-flex flex-row justify-content-between">
      <a class="navbar-brand my-auto" routerLink="/home">
        <img src="assets/client/images/fsc-logo.svg" [attr.alt]="'menu.header.image.logo' | translate"/>
      </a>

      <h1 class="my-auto flex-grow-1">{{ 'header.title' | translate}}</h1>

      <nav role="navigation" [attr.aria-label]="'nav.user.description' | translate" class="navbar navbar-light navbar-expand-md flex-shrink-0 px-0">
        <ds-lang-switch class="header-search mr-2"></ds-lang-switch>
        <ds-themed-auth-nav-menu></ds-themed-auth-nav-menu>
        <ds-impersonate-navbar></ds-impersonate-navbar>
      </nav>
    </div>
  </div>
</header>
