/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
/**
 * This component renders workspaceitem object for the search result in the list view.
 */
import { Component } from '@angular/core';
import { listableObjectComponent } from '../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { WorkspaceItemSearchResult } from '../../../../../../../app/shared/object-collection/shared/workspace-item-search-result.model';
import { ViewMode } from '../../../../../../../app/core/shared/view-mode.model';
import { AtmireSearchResultListElementComponent } from '../../../../../../../app-atmire/shared/object-list/search-result-list-element/atmire-search-result-list-element.component';
import { Item } from '../../../../../../../app/core/shared/item.model';
import { LinkService } from '../../../../../../../app/core/cache/builders/link.service';
import { Observable } from 'rxjs';
import { WorkspaceItem } from '../../../../../../../app/core/submission/models/workspaceitem.model';
import { TruncatableService } from '../../../../../../../app/shared/truncatable/truncatable.service';
import { DSONameService } from '../../../../../../../app/core/breadcrumbs/dso-name.service';
import { followLink } from '../../../../../../../app/shared/utils/follow-link-config.model';
import { RemoteData } from '../../../../../../../app/core/data/remote-data';
import { find, map } from 'rxjs/operators';
import { isNotUndefined } from '../../../../../../../app/shared/empty.util';
import { MultilingualMetadataService } from '../../../../../../../app-atmire/core/shared/multilingual-metadata.service';
import { Context } from '../../../../../../../app/core/shared/context.model';
import {
  MyDspaceItemStatusType
} from '../../../../../../../app/shared/object-collection/shared/badges/my-dspace-status-badge/my-dspace-item-status-type';

@Component({
  selector: 'ds-workspace-item-search-result-list-element',
  styleUrls: ['../../../../../../../app/shared/object-list/search-result-list-element/search-result-list-element.component.scss', '../../../../../../../app/shared/object-list/my-dspace-result-list-element/workspace-item-search-result/workspace-item-search-result-list-element.component.scss'],
  templateUrl: '../../../../../../../app/shared/object-list/my-dspace-result-list-element/workspace-item-search-result/workspace-item-search-result-list-element.component.html',
})

@listableObjectComponent(WorkspaceItemSearchResult, ViewMode.ListElement, Context.Any, 'atmire')
export class WorkspaceItemSearchResultListElementComponent extends AtmireSearchResultListElementComponent<WorkspaceItemSearchResult, WorkspaceItem> {

  /**
   * The item object that belonging to the result object
   */
  item$: Observable<Item>;

  /**
   * Represent item's status
   */
  status = MyDspaceItemStatusType.WORKSPACE;

  constructor(
    protected truncatableService: TruncatableService,
    protected linkService: LinkService,
    protected dsoNameService: DSONameService,
    protected multilingualMetadataService: MultilingualMetadataService,
  ) {
    super(truncatableService, dsoNameService, multilingualMetadataService);
  }

  /**
   * Initialize all instance variables
   */
  ngOnInit() {
    super.ngOnInit();
    this.linkService.resolveLink(this.dso, followLink('item'));
    this.initItem(this.dso.item as Observable<RemoteData<Item>>);
  }

  /**
   * Retrieve item from result object
   */
  initItem(item$: Observable<RemoteData<Item>>) {
    this.item$ = item$.pipe(
      find((rd: RemoteData<Item>) => rd.hasSucceeded && isNotUndefined(rd.payload)),
      map((rd: RemoteData<Item>) => rd.payload)
    );
  }
}
