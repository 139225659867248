/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { listableObjectComponent } from '../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { ClaimedTaskSearchResult } from '../../../../../../../app/shared/object-collection/shared/claimed-task-search-result.model';
import { ViewMode } from '../../../../../../../app/core/shared/view-mode.model';
import { ClaimedTask } from '../../../../../../../app/core/tasks/models/claimed-task-object.model';
import { LinkService } from '../../../../../../../app/core/cache/builders/link.service';
import { WorkflowItem } from '../../../../../../../app/core/submission/models/workflowitem.model';
import { RemoteData } from '../../../../../../../app/core/data/remote-data';
import { followLink } from '../../../../../../../app/shared/utils/follow-link-config.model';
import { AtmireSearchResultDetailElementComponent } from '../../../../../../../app-atmire/shared/object-detail/my-dspace-result-detail-element/atmire-search-result-detail-element.component';
import { MultilingualMetadataService } from '../../../../../../../app-atmire/core/shared/multilingual-metadata.service';
import { Context } from '../../../../../../../app/core/shared/context.model';
import {
  MyDspaceItemStatusType
} from '../../../../../../../app/shared/object-collection/shared/badges/my-dspace-status-badge/my-dspace-item-status-type';

/**
 * This component renders claimed task object for the search result in the detail view.
 */
@Component({
  selector: 'ds-claimed-task-search-result-detail-element',
  styleUrls: ['../../../../../../../app/shared/object-detail/my-dspace-result-detail-element/search-result-detail-element.component.scss'],
  templateUrl: '../../../../../../../app/shared/object-detail/my-dspace-result-detail-element/claimed-task-search-result/claimed-task-search-result-detail-element.component.html'
})
@listableObjectComponent(ClaimedTaskSearchResult, ViewMode.DetailedListElement, Context.Any, 'atmire')
export class ClaimedTaskSearchResultDetailElementComponent extends AtmireSearchResultDetailElementComponent<ClaimedTaskSearchResult, ClaimedTask> {

  /**
   * A boolean representing if to show submitter information
   */
  public showSubmitter = true;

  /**
   * Represent item's status
   */
  public status = MyDspaceItemStatusType.VALIDATION;

  /**
   * The workflowitem object that belonging to the result object
   */
  public workflowitemRD$: Observable<RemoteData<WorkflowItem>>;

  constructor(
    protected linkService: LinkService,
    protected multilingualMetadataService: MultilingualMetadataService,
  ) {
    super(multilingualMetadataService);
  }

  /**
   * Initialize all instance variables
   */
  ngOnInit() {
    super.ngOnInit();
    this.linkService.resolveLinks(this.dso, followLink('workflowitem', {},
      followLink('item', {}, followLink('bundles')),
      followLink('submitter')
    ), followLink('action'));
    this.workflowitemRD$ = this.dso.workflowitem as Observable<RemoteData<WorkflowItem>>;
  }

}
