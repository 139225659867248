/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { AtmireSearchResultListElementComponent } from '../../../../../../../../app-atmire/shared/object-list/search-result-list-element/atmire-search-result-list-element.component';
import { listableObjectComponent } from '../../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { ClaimedDeclinedTaskSearchResult } from '../../../../../../../../app/shared/object-collection/shared/claimed-declined-task-search-result.model';
import { ViewMode } from '../../../../../../../../app/core/shared/view-mode.model';
import { WorkflowItem } from '../../../../../../../../app/core/submission/models/workflowitem.model';
import { RemoteData } from '../../../../../../../../app/core/data/remote-data';
import { DSONameService } from '../../../../../../../../app/core/breadcrumbs/dso-name.service';
import { TruncatableService } from '../../../../../../../../app/shared/truncatable/truncatable.service';
import { LinkService } from '../../../../../../../../app/core/cache/builders/link.service';
import { followLink } from '../../../../../../../../app/shared/utils/follow-link-config.model';
import { ClaimedTask } from '../../../../../../../../app/core/tasks/models/claimed-task-object.model';
import { ClaimedTaskSearchResult } from '../../../../../../../../app/shared/object-collection/shared/claimed-task-search-result.model';
import { MultilingualMetadataService } from '../../../../../../../../app-atmire/core/shared/multilingual-metadata.service';
import { Context } from '../../../../../../../../app/core/shared/context.model';
import { MyDspaceItemStatusType } from 'src/app/shared/object-collection/shared/badges/my-dspace-status-badge/my-dspace-item-status-type';

/**
 * This component renders claimed task declined object for the search result in the list view.
 */
@Component({
  selector: 'ds-claimed-declined-search-result-list-element',
  styleUrls: ['../../../../../../../../app/shared/object-list/search-result-list-element/search-result-list-element.component.scss'],
  templateUrl: '../../../../../../../../app/shared/object-list/my-dspace-result-list-element/claimed-search-result/claimed-declined-search-result/claimed-declined-search-result-list-element.component.html'
})
@listableObjectComponent(ClaimedDeclinedTaskSearchResult, ViewMode.ListElement, Context.Any, 'atmire')
export class ClaimedDeclinedSearchResultListElementComponent extends AtmireSearchResultListElementComponent<ClaimedTaskSearchResult, ClaimedTask> {

  /**
   * A boolean representing if to show submitter information
   */
  public showSubmitter = true;

  /**
   * Represent item's status
   */
  public status = MyDspaceItemStatusType.DECLINED;

  /**
   * The workflowitem object that belonging to the result object
   */
  public workflowitemRD$: Observable<RemoteData<WorkflowItem>>;

  public constructor(
    protected linkService: LinkService,
    protected truncatableService: TruncatableService,
    protected dsoNameService: DSONameService,
    protected multilingualMetadataService: MultilingualMetadataService,
  ) {
    super(truncatableService, dsoNameService, multilingualMetadataService);
  }

  /**
   * Initialize all instance variables
   */
  ngOnInit() {
    super.ngOnInit();
    this.linkService.resolveLinks(this.dso,
      followLink('workflowitem',
        { useCachedVersionIfAvailable: false },
        followLink('item'),
        followLink('submitter')
      ),
      followLink('action'));
    this.workflowitemRD$ = this.dso.workflowitem as Observable<RemoteData<WorkflowItem>>;
  }

}
