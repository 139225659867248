/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, OnInit } from '@angular/core';
import { SearchResult } from '../../../../app/shared/search/models/search-result.model';
import { DSpaceObject } from '../../../../app/core/shared/dspace-object.model';
import { AtmireAbstractListableElementComponent } from '../../object-collection/shared/object-collection-element/atmire-abstract-listable-element.component';
import { hasValue } from '../../../../app/shared/empty.util';
import { MetadataMapInterface } from '../../../../app/core/shared/metadata.models';

/**
 * Component representing Search Results with ViewMode.DetailedElement
 */
@Component({
  selector: 'ds-search-result-detail-element',
  template: ``,
})
export class AtmireSearchResultDetailElementComponent<T extends SearchResult<K>, K extends DSpaceObject> extends AtmireAbstractListableElementComponent<T> implements OnInit {

  /**
   * The result element object
   */
  dso: K;

  /**
   * Initialize instance variables
   */
  ngOnInit(): void {
    if (hasValue(this.object)) {
      this.dso = this.object.indexableObject;
    }
  }

  protected get metadata(): MetadataMapInterface[] {
    return [this.object.hitHighlights, this.dso.metadata];
  }
}
