/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { CommunityListService, FlatNode } from '../../app/community-list-page/community-list-service';
import { Injectable } from '@angular/core';
import { Community } from '../../app/core/shared/community.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Collection } from '../../app/core/shared/collection.model';
import { CommunityDataService } from '../../app/core/data/community-data.service';
import { CollectionDataService } from '../../app/core/data/collection-data.service';
import { Store } from '@ngrx/store';
import { DSONameService } from '../../app/core/breadcrumbs/dso-name.service';

@Injectable()
export class AtmireCommunityListService extends CommunityListService {
  constructor(
    protected communityDataService: CommunityDataService,
    protected collectionDataService: CollectionDataService,
    protected store: Store<any>,
    protected dsoNameService: DSONameService,
  ) {
    super(communityDataService, collectionDataService, store);
  }

  public transformCommunity(community: Community, level: number, parent: FlatNode, expandedNodes: FlatNode[]): Observable<FlatNode[]> {
    return super.transformCommunity(community, level, parent, expandedNodes).pipe(
      // go over the flat nodes after they've been generated and ensure names are shown in the current language
      map(nodes => nodes.map(node => {
        const c = node.payload;

        if (c instanceof Community || c instanceof Collection) {
          return {
            ...node,
            name: this.dsoNameService.getName(c),
          };
        } else {
          return node;
        }
      }))
    );
  }
}
