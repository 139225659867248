/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { AfterViewInit, Component, ViewChild, ElementRef } from '@angular/core';
import {
  listableObjectComponent
} from '../../../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { ViewMode } from '../../../../../../../../../app/core/shared/view-mode.model';
import {
  ItemSearchResult
} from '../../../../../../../../../app/shared/object-collection/shared/item-search-result.model';
import { Context } from '../../../../../../../../../app/core/shared/context.model';
import {
  ItemSearchResultListElementComponent as BaseComponent
} from '../../../../../../../../../app/shared/object-list/search-result-list-element/item-search-result/item-types/item/item-search-result-list-element.component';
import { environment } from '../../../../../../../../../environments/environment';
import {
  ItemInfoSectionsComponent
} from '../../../../../../../../../app-client/shared/item-info-sections/item-info-sections.component';

@listableObjectComponent('PublicationSearchResult', ViewMode.ListElement, Context.Any, 'client')
@listableObjectComponent(ItemSearchResult, ViewMode.ListElement, Context.Any, 'client')
@Component({
  selector: 'ds-item-search-result-list-element-client',
  styleUrls: ['./item-search-result-list-element.component.scss', '../../../../../../../../../app-atmire/shared/styles/content-styles.scss'],
  // styleUrls: ['../../../../../../../../../app/shared/object-list/search-result-list-element/item-search-result/item-types/item/item-search-result-list-element.component.scss'],
  templateUrl: './item-search-result-list-element.component.html'
  // templateUrl: '../../../../../../../../../app/shared/object-list/search-result-list-element/item-search-result/item-types/item/item-search-result-list-element.component.html'
})
/**
 * The component for displaying a list element for an item search result of the type Publication
 */
export class ItemSearchResultListElementComponent extends BaseComponent implements AfterViewInit {
  /**
   * Whether or not to show the thumbnail
   */
  showThumbnail = true;

  /**
   * Whether to show the expandable section
   */
  showExpandableSection = true;

  /**
   * Make the style of the component more compact
   */
  compact = false;

  /**
   * Whether or not the extra information section is collapsed
   */
  collapsed = true;

  /**
   * Base URL to the UI
   * Used for constructing internal links from metadata values
   */
  baseUrl: string;

  showToggle = true;

  @ViewChild(ItemInfoSectionsComponent) details: ItemInfoSectionsComponent;
  @ViewChild('expandedContent') expandedContent: ElementRef;
  @ViewChild('expandedContentContainer') expandedContentContainer: ElementRef;

  ngOnInit() {
    super.ngOnInit();

    this.baseUrl = environment.ui.baseUrl;

    // Note: this only makes sense when used together with the query suggestions feature
    if (this.context === Context.QuerySuggestionPreview || this.context === Context.QuerySuggestionPreviewList) {
      this.showThumbnail = false;
      this.showExpandableSection = false;
      this.showToggle = false;
      this.compact = true;
    }
  }

  public ngAfterViewInit(): void {
    if (this.showExpandableSection) {
      this.showToggle = this.details.sections.map(section => !section.empty).some(Boolean);
    }
  }

  toggle(): void {
    this.collapsed = !this.collapsed;
    this.truncatableService.toggle(this.dso.id);

    if (!this.collapsed) {
      /**
       * Make sure we start out with the scroll indicators set
       * Note: if we do this on (shown) the effect appears noticeably too late,
       *       if we don't wait for the next tick the effect doesn't appear at all.
       */
      setTimeout(() => {
        this.resolveScrollIndicators();
      });
    }
  }

  resolveScrollIndicators() {
    const scrollTop = this.expandedContent.nativeElement.scrollTop;
    const scrollHeight = this.expandedContent.nativeElement.scrollHeight;
    const offsetHeight = this.expandedContent.nativeElement.offsetHeight;

    // Determine if scrolled away from the top or bottom
    const isScrolledToTop = scrollTop === 0;
    const isScrolledToBottom = scrollTop + offsetHeight >= scrollHeight;

    // Add CSS classes based on scroll position
    if (isScrolledToTop) {
      this.expandedContentContainer.nativeElement.classList.remove('scrolled-up');
    } else {
      this.expandedContentContainer.nativeElement.classList.add('scrolled-up');
    }
    if (isScrolledToBottom) {
      this.expandedContentContainer.nativeElement.classList.remove('scrolled-down');
    } else {
      this.expandedContentContainer.nativeElement.classList.add('scrolled-down');
    }
  }
}
