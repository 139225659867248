<div [formGroup]="group">
  <div *ngIf="model.repeatable"
       class="form-row.pb-1"
       [attr.tabindex]="model.tabIndex"
       [id]="id"
       [formGroupName]="model.id"
       [ngClass]="model.layout.element?.control">

    <div *ngFor="let columnItems of items" class="col-sm ml-3">

      <div  *ngFor="let item of columnItems" class="custom-control custom-checkbox">

          <input type="checkbox" class="custom-control-input"
                 [attr.aria-labelledby]="'label_' + model.id"
                 [attr.tabindex]="item.index"
                 [checked]="item.value"
                 [id]="item.id"
                 [formControlName]="item.id"
                 [name]="model.name"
                 [required]="model.required"
                 [value]="item.value"
                 (blur)="onBlur($event)"
                 (change)="onChange($event)"
                 (focus)="onFocus($event)"/>
        <label class="custom-control-label"
               [class.disabled]="model.disabled"
               [ngClass]="model.layout.element?.control"
               [for]="item.id">
          <span [ngClass]="model.layout.element?.label" [innerHTML]="item.label"></span>
        </label>
      </div>
    </div>

  </div>

  <div *ngIf="!model.repeatable"
       class="form-row"
       ngbRadioGroup
       [attr.tabindex]="model.tabIndex"
       [id]="id"
       [ngClass]="model.layout.element?.control"
       (change)="onChange($event)">

    <div *ngFor="let columnItems of items" class="col-sm ml-3">

      <div  *ngFor="let item of columnItems" class="custom-control custom-radio">
        <input type="radio" class="custom-control-input"
               [checked]="item.value"
               [id]="item.id"
               [name]="model.id"
               [required]="model.required"
               [value]="item.index"
               (blur)="onBlur($event)"
               (focus)="onFocus($event)"/>
        <label class="custom-control-label"
               [class.disabled]="model.disabled"
               [ngClass]="model.layout.element?.control"
                [for]="item.id">

          <span [ngClass]="model.layout.element?.label" [innerHTML]="item.label"></span>
        </label>
      </div>
    </div>

  </div>
</div>
