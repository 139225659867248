<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="container-fluid">
  <div class="row">
    <div class="col-12 d-inline-block">
      <label>{{type.value + '.edit.logo.label' | translate}}</label>
    </div>
    <ng-container *ngVar="(dso?.logo | async)?.payload as logo">
      <div class="col-12 d-inline-block alert" [ngClass]="{'alert-danger': markLogoForDeletion}" id="logo-section" *ngIf="logo">
        <div class="row">
          <div class="col-8 d-inline-block">
            <ds-comcol-page-logo [logo]="logo"></ds-comcol-page-logo>
          </div>
          <div class="col-4 d-inline-block">
            <div *ngIf="logo" class="btn-group btn-group-sm float-right" role="group">
              <button *ngIf="!markLogoForDeletion" type="button" class="btn btn-danger"
                      title="{{type.value + '.edit.logo.delete.title' | translate}}"
                      (click)="deleteLogo()">
                <i class="fas fa-trash" aria-hidden="true"></i>
              </button>
              <button *ngIf="markLogoForDeletion" type="button" class="btn btn-warning"
                      title="{{type.value + '.edit.logo.delete-undo.title' | translate}}"
                      (click)="undoDeleteLogo()">
                <i class="fas fa-undo" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!logo" class="col-12 d-inline-block">
        <ds-uploader  *ngIf="initializedUploaderOptions | async"
                      [dropMsg]="type.value + '.edit.logo.upload'"
                      [dropOverDocumentMsg]="type.value + '.edit.logo.upload'"
                      [enableDragOverDocument]="true"
                      [uploadFilesOptions]="uploadFilesOptions"
                      (onCompleteItem)="onCompleteItem()"
                      (onUploadError)="onUploadError()"></ds-uploader>
      </div>
    </ng-container>
  </div>
</div>

<div *ngIf="formModel" class="mb-4">
  <ng-container>
    <div class="col-12" *ngIf="formLanguages.size">
      <ul class="nav-tabs" ngbNav #nav="ngbNav">
        <li class="nav-item" ngbNavItem *ngFor="let lang of formLanguages">
          <a href="javascript:void(0);" class="nav-link" ngbNavLink
             [ngClass]="{ 'text-danger': (invalidLanguages$ | async)?.includes(lang) }"
          >
            {{ 'language.' + lang + '.label' | translate }}
          </a>
          <ng-template ngbNavContent>
            <ds-form *ngIf="formModel"
                     [formId]="'comcol-form-id-' + lang"
                     [formModel]="getFormByLanguage(lang)" [formGroup]="formGroup"
                     [displayCancel]="false" [displaySubmit]="false"
                     (dfBlur)="gatherErrors()" (dfChange)="gatherErrors()">
            </ds-form>
          </ng-template>
        </li>
      </ul>

      <div class="tab-box pt-2 mb-4" *ngIf="formLanguages.size">
        <div [ngbNavOutlet]="nav"></div>
      </div>
    </div>
  </ng-container>

  <ds-form [formId]="'comcol-form-id'"
           [formModel]="getFormByLanguage(null)" [formGroup]="formGroup"
           [displayCancel]="false" [displaySubmit]="false"
           (dfBlur)="gatherErrors()" (dfChange)="gatherErrors()">
  </ds-form>

  <div *ngIf="(invalidLanguages$ | async)?.length > 0" class="col-12">
    <p class="alert alert-danger">
      {{ 'comcol.edit.metadata.some-languages-incomplete' | translate: { languages: (invalidLanguagesSummary$ | async) } }}
    </p>
  </div>
</div>

<div class="col-12 text-right">
  <button (click)="back.emit()" class="btn btn-outline-secondary">
    <i class="fas fa-arrow-left"></i> {{ type.value + '.edit.return' | translate }}
  </button>
  <button (click)="onSubmit()" [ngClass]="{ disabled: !(enableSave$ | async) }" type="submit" class="btn btn-primary">
    <i class="fas fa-save"></i> {{ 'form.submit' | translate}}
  </button>
</div>

