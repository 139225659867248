<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div style="position: relative;">
  <span *ngIf="label"
        [ngClass]="labelClass">
    {{ label }}<i *ngIf="labelTooltip" class="fas fa-info-circle ml-1" [ngbTooltip]="labelTooltip"></i>{{ labelSuffix }}
  </span>
  <ng-content></ng-content>
</div>
