/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Input } from '@angular/core';
import { MetadataMapInterface, MetadataValue } from '../../../../app/core/shared/metadata.models';
import { MultilingualMetadataService } from '../../../core/shared/multilingual-metadata.service';

@Component({
  selector: 'ds-language-specific-metadata-values',
  templateUrl: './language-specific-metadata-values.component.html',
  styleUrls: ['./language-specific-metadata-values.component.scss'],
})
export class LanguageSpecificMetadataValuesComponent {

  /**
   * DSO metadata map
   */
  @Input() metadataMap: MetadataMapInterface;

  /**
   * The metadata values to display
   */
  @Input() fields: string[];

  /**
   * The seperator used to split the metadata values (can contain HTML)
   */
  @Input() separator: string;

  /**
   * The label for this iteration of metadata values
   */
  @Input() label: string;

  /**
   * Whether the {@link MarkdownPipe} should be used to render this metadata.
   */
  @Input() enableMarkdown: boolean;

  constructor(protected multilingualMetadataService: MultilingualMetadataService) {
  }

  get mdValues(): MetadataValue[] {
    return this.multilingualMetadataService.allCurrentLang(this.metadataMap, this.fields);
  }
}
