/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { HomeNewsComponent as BaseComponent } from '../../../../../app/home-page/home-news/home-news.component';
import { DsoRedirectDataService } from '../../../../../app/core/data/dso-redirect-data.service';
import { ConfigurationDataService } from '../../../../../app/core/data/configuration-data.service';
import { getFirstCompletedRemoteData } from '../../../../../app/core/shared/operators';
import { RemoteData } from '../../../../../app/core/data/remote-data';
import { ConfigurationProperty } from '../../../../../app/core/shared/configuration-property.model';
import { LookupGuard } from '../../../../../app/lookup-by-id/lookup-guard';
import { switchMap } from 'rxjs/operators';
import { Observable, of as observableOf, Subscription } from 'rxjs';
import { DSpaceObject } from '../../../../../app/core/shared/dspace-object.model';
import { hasValue } from '../../../../../app/shared/empty.util';
import { getDSORoute } from '../../../../../app/app-routing-paths';
import { Router } from '@angular/router';

@Component({
  selector: 'ds-home-news',
  styleUrls: ['./home-news.component.scss'],
  // styleUrls: ['../../../../../app/home-page/home-news/home-news.component.scss'],
  templateUrl: './home-news.component.html',
  // templateUrl: '../../../../../app/home-page/home-news/home-news.component.html',
  providers: [DsoRedirectDataService, LookupGuard]
})

/**
 * Component to render the news section on the home page
 */
export class HomeNewsComponent extends BaseComponent implements OnInit, OnDestroy {

  // Fallback route when property doesn't resolve to valid dso handle
  private FALLBACK_ROUTE_NEWS_BUTTONS = '/community-list';

  private propertiesToResolveToLink = ['normative.framework.documents.collection', 'fsc-certified-forests.collection',
    'fsc-resources-podcasts.collection', 'fsc-research-portal.collection'];
  private mapPropertyLink = new Map<string, Observable<string>>();

  // List of subscription of component, to unsubscribe on destroy
  private subs: Subscription[] = [];

  constructor(
    private router: Router,
    private configurationService: ConfigurationDataService,
    private dsoRedirectDataService: DsoRedirectDataService
  ) {
    super();
  }

  ngOnInit(): void {
    this.propertiesToResolveToLink.forEach((property: string) => {
      this.mapPropertyLink.set(property, this.getCorrespondingLink(property));
    });
  }

  navigate(to: string, isProperty: boolean = true): void {
    const link$: Observable<string> = this.mapPropertyLink.get(to);
    if (hasValue(link$)) {
      this.subs.push(link$.subscribe((link: string) => this.router.navigate([link])));
    } else {
      this.subs.push(((isProperty) ? this.getCorrespondingLink(to) : this.getCorrespondingLinkByHandle(to)).subscribe((link: string) => this.router.navigate([link])));
    }
  }

  getCorrespondingLink(property: string): Observable<string> {
    const link$ = this.configurationService.findByPropertyName(property).pipe(
      getFirstCompletedRemoteData(),
      switchMap((configurationProperty: RemoteData<ConfigurationProperty>) => {
        if (configurationProperty.hasSucceeded) {
          return this.getCorrespondingLinkByHandle(configurationProperty.payload.values[0]);
        } else {
          return observableOf(this.FALLBACK_ROUTE_NEWS_BUTTONS);
        }
      })
    );
    this.subs.push(link$.subscribe());
    return link$;
  }

  getCorrespondingLinkByHandle(handle): Observable<string> {
    return this.dsoRedirectDataService.findById('hdl:' + handle).pipe(
      switchMap((rdDSO: RemoteData<DSpaceObject>) => {
        if (rdDSO.hasSucceeded) {
          return observableOf(getDSORoute(rdDSO.payload));
        } else {
          return observableOf(this.FALLBACK_ROUTE_NEWS_BUTTONS);
    }
      })
    );
  }

  ngOnDestroy(): void {
    // Unsubscribe for each valid subscription of component
    this.subs.filter((sub: Subscription) => hasValue(sub)).forEach((sub: Subscription) => sub.unsubscribe());
  }
}
