/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, ComponentFactoryResolver, OnInit, ViewChild } from '@angular/core';
import { DSpaceObjectDataService } from '../../../../../../../app/core/data/dspace-object-data.service';
import { DSpaceObject } from '../../../../../../../app/core/shared/dspace-object.model';
import { ObjectSuggestion } from '../../../../../../core/search/models/object-suggestion.model';
import { hasNoValue } from '../../../../../../../app/shared/empty.util';
import {
  getItemSuggestionPreviewRendererFor,
  getObjectSuggestionPreviewRendererFor,
  rendersSuggestionPreview
} from '../../suggestion.decorator';
import { GenericConstructor } from '../../../../../../../app/core/shared/generic-constructor';
import { SuggestionPreviewComponent } from '../suggestion-preview.component';
import { SuggestionPreviewDirective } from '../suggestion-preview.directive';
import { DSOSuggestionPreviewComponent } from './dso-suggestion-preview.component';
import { RemoteData } from '../../../../../../../app/core/data/remote-data';
import {
  getFirstSucceededRemoteData,
  getFirstSucceededRemoteListPayload
} from '../../../../../../../app/core/shared/operators';
import { LinkService } from '../../../../../../../app/core/cache/builders/link.service';
import { Item } from '../../../../../../../app/core/shared/item.model';
import { followLink } from '../../../../../../../app/shared/utils/follow-link-config.model';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * This component renders a preview for dspace object suggestions
 */

@Component({
  selector: 'ds-object-suggestion-preview',
  // styleUrls: ['./object-suggestion-preview.component.scss'],
  templateUrl: './object-suggestion-preview.component.html'
})
@rendersSuggestionPreview(ObjectSuggestion)
export class ObjectSuggestionPreviewComponent<T extends DSpaceObject> extends SuggestionPreviewComponent<ObjectSuggestion<T>> implements OnInit {

  /**
   * Directive used to place the specific suggestion component
   */
  @ViewChild(SuggestionPreviewDirective, { static: true }) previewAnchor: SuggestionPreviewDirective;

  /**
   * The specific object suggestion's preview component
   */
  suggestionComponent: DSOSuggestionPreviewComponent<DSpaceObject>;
  loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private linkService: LinkService,
              protected dsoService: DSpaceObjectDataService) {
    super();
  }

  /**
   * Initialize and decide what child preview-component should be rendered
   */
  ngOnInit(): void {
    this.loading$.next(true);
    if (hasNoValue(this.suggestion.dspaceObject)) {
      this.suggestion = Object.assign(
        new ObjectSuggestion(),
        this.suggestion,
        {
          dspaceObject: this.dsoService.findByHref(this.suggestion._links.dspaceObject.href, true, true, followLink('thumbnail' as any, {isOptional: true})) as Observable<RemoteData<T>>
        });
    }
    this.suggestion.dspaceObject
      .pipe(
        getFirstSucceededRemoteData(),
      )
      .subscribe((objectRD: RemoteData<T>) => {
        const suggestionComponent = this.getSuggestionPreviewComponent(objectRD.payload, this.category.name);

        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(suggestionComponent);
        const viewContainerRef = this.previewAnchor.viewContainerRef;
        viewContainerRef.clear();

        const componentRef = viewContainerRef.createComponent(componentFactory);
        ((componentRef.instance) as DSOSuggestionPreviewComponent<T>).dso = objectRD.payload;
        ((componentRef.instance) as DSOSuggestionPreviewComponent<T>).searchOptions = this.searchOptions;
        this.suggestionComponent = ((componentRef.instance) as DSOSuggestionPreviewComponent<T>);
        this.loading$.next(false);

        componentRef.changeDetectorRef.detectChanges();
      });
  }

  /**
   * Find the correct component based on the object suggestion's type
   */
  getSuggestionPreviewComponent(object: T, type: string) {
    const f: GenericConstructor<DSpaceObject> = object.constructor as GenericConstructor<DSpaceObject>;
    if (f === Item) {
      return getItemSuggestionPreviewRendererFor(type);
    } else {
      return getObjectSuggestionPreviewRendererFor(f);
    }
  }
}
