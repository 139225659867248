/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { Context } from '../../../app/core/shared/context.model';
import { ViewMode } from '../../../app/core/shared/view-mode.model';
import { CollectionElementLinkType } from '../../../app/shared/object-collection/collection-element-link.type';
import { RemoteData } from '../../../app/core/data/remote-data';
import { PaginatedList } from '../../../app/core/data/paginated-list.model';
import { ListableObject } from '../../../app/shared/object-collection/shared/listable-object.model';
import { ListableObjectSourceModel } from '../listable-object-sources';
import { ListableObjectSourceFactoryService } from '../sources/listable-object-source-factory.service';
import { AbstractListableObjectSource } from '../sources/abstract-listable-object-source';
import { ListableCacheableObject } from './listable-cachable-object.model';
import { hasValue } from '../../../app/shared/empty.util';

@Component({
  selector: 'ds-abstract-object-collection-wrapper',
  template: '',
})
/**
 * Common behavior for components wrapping {@link AtmireObjectCollectionComponent}
 *   - Create a {@link ListableObjectSource} from the {@link ListableObjectSourceModel} in {@link sourceModel}
 *   - Error handling with {@link setError} and {@link error$}
 *   - Subscription handling with {@link subs}
 */
export abstract class AbstractObjectCollectionWrapperComponent<T extends ListableCacheableObject> implements OnDestroy, OnChanges {
  /**
   * The model for the data {@link source} to retrieve the object collection from.
   * When this input changes the source will be reinitialized.
   */
  @Input() sourceModel: ListableObjectSourceModel;

  /**
   * The context of the result components. Default: {@link Context.Any}
   */
  @Input() context?: Context = Context.Any;

  /**
   * The view mode to use for the result components. Default: {@link ViewMode.ListElement}
   */
  @Input() viewMode?: ViewMode.ListElement | ViewMode.GridElement = ViewMode.ListElement;

  /**
   * The link type to use for the result components. Default: {@link CollectionElementLinkType.Link}
   */
  @Input() linkType?: CollectionElementLinkType = CollectionElementLinkType.Link;

  /**
   * Emits the current page on page changes.
   */
  @Output() pageChange: EventEmitter<RemoteData<PaginatedList<ListableObject>>> = new EventEmitter();

  /**
   * The i18n message to show in case no results are found.
   */
  @Input() noResultsMsg? = 'atmire.object-collection.no-results';

  /**
   * The i18n message to show in case some objects cannot be shown.
   */
  @Input() errorMsg? = 'atmire.object-collection.error.preamble';

  /**
   * Boolean to enable highlighting of the list object on hover
   */
  @Input() highlightOnHover = false;

  /**
   * Emits a click event from the list object
   */
  @Output() onObjectClick = new EventEmitter<ListableObject>();

  private _source: AbstractListableObjectSource<T>;
  error$: Observable<string>;
  isLoading$: BehaviorSubject<boolean>;

  protected subs: Subscription[];

  /**
   * Subscription used for the results observable. This should be un- and resubscribed when the source is updated.
   */
  protected resultSub: Subscription;

  protected constructor(
    protected sourceFactory: ListableObjectSourceFactoryService,
    protected translateService: TranslateService,
  ) {
    this.subs = [];
    this.isLoading$ = new BehaviorSubject<boolean>(true);
  }

  /**
   * Push the i18n key of an error message to {@link error$}.
   * The parameters in this message will be interpolated based on the current data {@link source}.
   * @param key
   * @protected
   */
  protected setError(key: string): void {
    this.error$ = combineLatest([
      this.translateService.get(this.errorMsg),
      this.translateService.get(key, this.source.params),
    ]).pipe(
      map((parts: string[]) => `<b>${parts[0]}:</b> ${parts[1]}`)
    );
  }

  /**
   * The data source of this object collection component.
   * @protected
   */
  protected get source(): AbstractListableObjectSource<T> {
    if (this.sourceModel === undefined) {
      throw new Error('AtmireObjectCollectionComponent initialized without sourceModel value');
    }
    if (this._source === undefined) {
      this._source = this.sourceFactory.getSource(this.sourceModel);
    }
    return this._source;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (Object.keys(changes).includes('sourceModel')) {
      // Reset the data source once sourceModel changes. It will be reinitialized next time it's requested.
      this._source = undefined;
    }
  }

  public ngOnDestroy(): void {
    this.subs.map((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    if (hasValue(this.resultSub)) {
      this.resultSub.unsubscribe();
    }
  }
}
