/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { autoserialize, inheritSerialization } from 'cerialize';
import { typedObject } from '../../../app/core/cache/builders/build-decorators';
import { excludeFromEquals } from '../../../app/core/utilities/equals.decorators';
import { QUERY_SUGGESTIONS_TYPE } from './query-suggestion-object';
import { SuggestionCategory } from '../../core/search/models/suggestion-category.model';
import { CacheableObject } from '../../../app/core/cache/object-cache.reducer';
import { HALResource } from '../../../app/core/shared/hal-resource.model';

/**
 * Class representing the response returned by the server when performing a search request
 */
@typedObject
@inheritSerialization(HALResource)
export class QuerySuggestionsObject extends CacheableObject implements HALResource {
  static type = QUERY_SUGGESTIONS_TYPE;

  /**
   * The sort parameters used in the search request
   * Hardcoded because rest doesn't provide a unique type
   */
  @excludeFromEquals
  public type = QUERY_SUGGESTIONS_TYPE;

  /**
   * The scope used in the search request represented by the UUID of a DSpaceObject
   */
  @autoserialize
  scope: string;

  /**
   * The search query used in the search request
   */
  @autoserialize
  query: string;

  /**
   * The currently active filters used in the search request
   */
  @autoserialize
  appliedFilters: any[]; // TODO

  /**
   * The sort parameters used in the search request
   */
  @autoserialize
  sort: any; // TODO

  /**
   * The sort parameters used in the search request
   */
  @autoserialize
  configurationName: string;

  @autoserialize
  facets: any; // TODO

  results: SuggestionCategory[];
}
