/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { NgModule } from '@angular/core';
import { SharedModule } from '../../app/shared/shared.module';
import { ItemPageModule } from '../../app/item-page/item-page.module';
import { ItemPageHtmlFieldComponent } from './simple/field-components/specific-field/html/item-page-html-field.component';
import { AtmireItemTemplateMetadataComponent } from './edit-item-page/item-metadata/item-metadata.component';
import { EditItemPageModule } from '../../app/item-page/edit-item-page/edit-item-page.module';
import { ComColDataService } from '../../app/core/data/comcol-data.service';
import { ComcolModule } from '../../app/shared/comcol/comcol.module';

const ENTRY_COMPONENTS = [
  // put only entry components that use custom decorator
];

const DECLARATIONS = [
    ...ENTRY_COMPONENTS,
  ItemPageHtmlFieldComponent,
  AtmireItemTemplateMetadataComponent,
];

@NgModule({
  imports: [
    SharedModule.withEntryComponents(),
    // Shouldn't import ItemPageModule here
    EditItemPageModule,
    ComcolModule,
  ],
  declarations: [
    ...DECLARATIONS
  ],
  exports: [
    ...DECLARATIONS
  ],
})
export class AtmireItemPageModule {
  /**
   * NOTE: this method allows to resolve issue with components that using a custom decorator
   * which are not loaded during SSR otherwise
   */
  static withEntryComponents() {
    return {
      ngModule: AtmireItemPageModule,
      providers: ENTRY_COMPONENTS.map((component) => ({ provide: component }))
    };
  }
}

