<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="container-fluid">
  <div *ngFor="let language of languageOptions" class="row">
    <div class="col-1 form-check">
      <input class="form-check-input" type="checkbox"
             [name]="language.code" [id]="language.code" [(ngModel)]="supported[language.code]">
      <label class="form-check-label"
             [for]="language.code">
        {{language.label}}
      </label>
    </div>
    <div class="col">
          <span *ngIf="displayWarning(language)" class="text-warning">
      {{'comcol.edit.languages.will-delete-metadata-warning' | translate}}
    </span>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col text-right">
      <button type="reset" class="btn btn-default"
              (click)="onCancel()">
        {{'form.cancel' | translate}}
      </button>
      <button type="submit" class="btn btn-primary"
              (click)="onSubmit()">
        {{'form.submit' | translate}}
      </button>
    </div>
  </div>
</div>
