/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { rendersSuggestionResult } from '../../suggestion.decorator';
import { SuggestionResultComponent } from '../suggestion-result.component';
import { SearchSuggestion } from '../../../../../../core/search/models/search-suggestion.model';
import { Router } from '@angular/router';
import { hasValue, isNotEmpty } from '../../../../../../../app/shared/empty.util';

/**
 * This component renders a suggestion result for a search suggestion
 */
@Component({
  selector: 'ds-search-suggestion-result',
  styleUrls: ['./search-suggestion-result.component.scss', '../suggestion-result.component.scss'],
  templateUrl: './search-suggestion-result.component.html'
})
@rendersSuggestionResult(SearchSuggestion)
export class SearchSuggestionResultComponent extends SuggestionResultComponent<SearchSuggestion> implements OnInit {

  /**
   * The element ref to the link
   */
  @ViewChild('link', {static: false}) link: ElementRef;

  constructor(private router: Router) {
    super();
  }

  ngOnInit() {
    this.displayValue = isNotEmpty(this.suggestion.hitHighlights) && isNotEmpty(this.suggestion.hitHighlights.search) ? this.suggestion.hitHighlights.search[0].toString() : this.suggestion.label;
  }

  /**
   * Method to navigate to the right page when clicking the suggestion result when it's already active
   */
  select() {
    if (this.active.getValue() && isNotEmpty(this.suggestion.queryParams)) {
      this.router.navigateByUrl('/search?' + this.suggestion.queryParams[0] );
    }
    return false;
  }

  /**
   * Method to set the focus on the link in the view
   */
  focus() {
    this.link.nativeElement.focus();
  }
}
