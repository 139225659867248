import { getInfoModulePath } from '../app-routing-paths';

// Atmire modifications START
// Client requested the URL to be changed to reflect their naming convention
export const END_USER_AGREEMENT_PATH = 'terms-and-conditions';
// Atmire modifications END

export const PRIVACY_PATH = 'privacy';
export const FEEDBACK_PATH = 'feedback';

export function getEndUserAgreementPath() {
    return getSubPath(END_USER_AGREEMENT_PATH);
}

export function getPrivacyPath() {
    return getSubPath(PRIVACY_PATH);
}

export function getFeedbackPath() {
    return getSubPath(FEEDBACK_PATH);
}

function getSubPath(path: string) {
    return `${getInfoModulePath()}/${path}`;
}
