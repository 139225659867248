/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { CollectionDataService } from '../../../app/core/data/collection-data.service';
import { Observable } from 'rxjs';
import { URLCombiner } from '../../../app/core/url-combiner/url-combiner';
import { map, switchMap } from 'rxjs/operators';
import { RemoteData } from '../../../app/core/data/remote-data';
import { NoContent } from '../../../app/core/shared/NoContent.model';
import { hasValue } from '../../../app/shared/empty.util';
import { getFirstCompletedRemoteData } from '../../../app/core/shared/operators';
import { Bitstream } from '../../../app/core/shared/bitstream.model';
import { createFailedRemoteDataObject$ } from '../../../app/shared/remote-data.utils';
import { Collection } from '../../../app/core/shared/collection.model';


export class AtmireCollectionDataService extends CollectionDataService {
  /**
   * Get the endpoint for the community or collection's logo
   * @param id  The community or collection's ID
   */
  public getDefaultThumbnailEndpoint(id: string): Observable<string> {
    return this.halService.getEndpoint(this.linkPath).pipe(
      // We can't use HalLinkService to discover the logo link itself, as objects without a logo
      // don't have the link, and this method is also used in the createLogo method.
      map((href: string) => new URLCombiner(href, id, 'thumbnail').toString())
    );
  }

  /**
   * Delete the logo from the community or collection
   * @param dso The object to delete the logo from
   */
  public deleteDefaultThumbnail(dso: Collection): Observable<RemoteData<NoContent>> {
    const thumbnail$ = dso.thumbnail;
    if (hasValue(thumbnail$)) {
      // We need to fetch the logo before deleting it, because rest doesn't allow us to send a
      // DELETE request to a `/logo` link. So we need to use the bitstream self link.
      return thumbnail$.pipe(
        getFirstCompletedRemoteData(),
        switchMap((thumbnailRD: RemoteData<Bitstream>) => {
          if (thumbnailRD.hasFailed) {
            console.error(`Couldn't retrieve the thumbnail '${dso._links.thumbnail.href}' in order to delete it.`);
            return [thumbnailRD];
          } else {
            return this.bitstreamDataService.deleteByHref(thumbnailRD.payload._links.self.href);
          }
        })
      );
    } else {
      return createFailedRemoteDataObject$(`The given object doesn't have a thummbnail`, 400);
    }
  }
}
