/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { ResourceType } from '../../../../app/core/shared/resource-type';

/**
 * The resource type for SuggestionCategory
 *
 * Needs to be in a separate file to prevent circular
 * dependencies in webpack.
 */
export const SUGGESTION_CATEGORY = new ResourceType('queryCategory');
