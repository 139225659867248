<ng-container *ngVar="(workflowitemRD$ | async)?.payload as workflowitem">
  <div class="alert alert-success w-100" role="alert">
    <h4 class="alert-heading">Approved</h4>
    <ds-themed-item-list-preview *ngIf="workflowitem"
                          [item]="(workflowitem?.item | async)?.payload"
                          [object]="object"
                          [status]="status"
                          [showSubmitter]="showSubmitter"></ds-themed-item-list-preview>
  </div>
</ng-container>
