/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component } from '@angular/core';
import { listableObjectComponent } from '../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { ViewMode } from '../../../../../../../app/core/shared/view-mode.model';
import { Context } from '../../../../../../../app/core/shared/context.model';
import { AlertType } from '../../../../../../../app/shared/alert/aletr-type';
import { DSONameService } from '../../../../../../../app/core/breadcrumbs/dso-name.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VersionHistoryDataService } from '../../../../../../../app/core/data/version-history-data.service';
import { TranslateService } from '@ngx-translate/core';
import { VersionDataService } from '../../../../../../../app/core/data/version-data.service';
import {
  ItemVersionsSharedService
} from '../../../../../../../app/shared/item/item-versions/item-versions-shared.service';
import { Router } from '@angular/router';
import { WorkspaceitemDataService } from '../../../../../../../app/core/submission/workspaceitem-data.service';
import { SearchService } from '../../../../../../../app/core/shared/search/search.service';
import { ItemDataService } from '../../../../../../../app/core/data/item-data.service';
import { RouteService } from '../../../../../../../app/core/services/route.service';
import { UntypedItemComponent } from '../untyped-item/untyped-item.component';
import {
  AuthorizationDataService
} from '../../../../../../../app/core/data/feature-authorization/authorization-data.service';

@listableObjectComponent('Publication', ViewMode.StandalonePage, Context.Any, 'client')
@Component({
  selector: 'ds-publication',
  templateUrl: '../untyped-item/untyped-item.component.html',
  // templateUrl: './publication.component.html',
  // templateUrl: '../../../../../../../app/item-page/simple/item-types/publication/publication.component.html',
  // styleUrls: ['./publication.component.scss'],
  styleUrls: ['../../../../../../../app/item-page/simple/item-types/publication/publication.component.scss'],
  animations: []
})
export class PublicationComponent extends UntypedItemComponent {
  AlertType = AlertType;

  constructor(
    modalService: NgbModal,
    versionHistoryService: VersionHistoryDataService,
    translateService: TranslateService,
    versionService: VersionDataService,
    itemVersionShared: ItemVersionsSharedService,
    router: Router,
    workspaceItemDataService: WorkspaceitemDataService,
    searchService: SearchService,
    itemService: ItemDataService,
    routeService: RouteService,
    public dsoNameService: DSONameService,
    authorizationService: AuthorizationDataService
  ) {
    super(modalService, versionHistoryService, translateService, versionService, itemVersionShared, router, workspaceItemDataService, searchService, itemService, routeService, dsoNameService, authorizationService);
  }
}
