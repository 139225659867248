<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="container">
    <div class="row">
        <div class="col-12 pb-4">
            <h2 id="sub-header" class="border-bottom pb-2">{{'collection.create.sub-head' | translate:{ parent: parentName$ | async } }}</h2>
        </div>
    </div>
    <ds-themed-collection-form (submitForm)="onSubmit($event)"
                        (back)="navigateToHome()"
                        (finish)="navigateToNewPage()"></ds-themed-collection-form>
</div>
