import { NgModule } from '@angular/core';

import { CommunityFormComponent } from './community-form.component';
import { SharedModule } from '../../shared/shared.module';
import { ComcolModule } from '../../shared/comcol/comcol.module';
import { FormModule } from '../../shared/form/form.module';
import { ThemedCommunityFormComponent } from './themed-community-form.component';

const ENTRY_COMPONENTS = [
];

const DECLARATIONS = [
  CommunityFormComponent,
  ThemedCommunityFormComponent,
  ...ENTRY_COMPONENTS,
];

@NgModule({
  imports: [
    ComcolModule,
    FormModule,
    SharedModule
  ],
  declarations: DECLARATIONS,
  entryComponents: ENTRY_COMPONENTS,
  exports: DECLARATIONS,
})
export class CommunityFormModule {

}
