/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
/**
 * This component renders a simple item page.
 * The route parameter 'id' is used to request the item it represents.
 * All fields of the item that should be displayed, are defined in its template.
 */
import { ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Suggestion } from '../../../../../core/search/models/suggestion.model';
import { SuggestionCategory } from '../../../../../core/search/models/suggestion-category.model';
import { BehaviorSubject } from 'rxjs';

/**
 * Abstract component for suggestion results
 */

@Component({
  selector: 'ds-abstract-suggestion-result-component',
  template: ``,
})
export class SuggestionResultComponent<T extends Suggestion> {

  /**
   * The suggestion object to render a view for
   */
  @Input() suggestion: T;

  /**
   * Whether or not this result is currently active
   */
  @Input() active: BehaviorSubject<boolean>;

  @Input() category: SuggestionCategory;

  /**
   * The display value of the result
   */
  displayValue: string;

  /**
   * Method to perform an action when the suggestion is selected
   */
  select() {
    // implement in child component
  }

  focus() {
    // implement in child component
  }
}
