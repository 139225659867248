/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MultilingualMetadataService } from '../../../app-atmire/core/shared/multilingual-metadata.service';
import { DSONameService } from '../../../app/core/breadcrumbs/dso-name.service';
import { DSpaceObject } from '../../../app/core/shared/dspace-object.model';

/**
 * Returns a name for a {@link DSpaceObject} based
 * on its render types.
 */
@Injectable({
  providedIn: 'root',
})
export class AtmireDSONameService extends DSONameService {

  constructor(
    protected translateService: TranslateService,
    protected multilingualMetadataService: MultilingualMetadataService,
  ) {
    super(translateService);
  }

  /**
   * Functions to generate the specific names.
   *
   * If this list ever expands it will probably be worth it to
   * refactor this using decorators for specific entity types,
   * or perhaps by using a dedicated model for each entity type
   *
   * With only two exceptions those solutions seem overkill for now.
   */
  protected readonly factories = {
    Person: (dso: DSpaceObject): string => {
      const givenName = this.multilingualMetadataService.firstValueCurrentLang(dso.metadata, 'person.givenName');
      const familyName = this.multilingualMetadataService.firstValueCurrentLang(dso.metadata, 'person.familyName');
      return `${familyName}, ${givenName}`;
    },
    OrgUnit: (dso: DSpaceObject): string => {
      return this.multilingualMetadataService.firstValueCurrentLang(dso.metadata, 'organization.legalName');
    },
    Bitstream: (dso: DSpaceObject): string => {
      return 'iedemenne';
    },
    Default: (dso: DSpaceObject): string => {
      // If object doesn't have dc.title metadata use name property
      return this.multilingualMetadataService.firstValueCurrentLang(dso.metadata, 'dc.title') || dso.name || this.translateService.instant('dso.name.untitled');
    }
  };
}
