/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Inject, Injectable } from '@angular/core';
import { NativeWindowRef, NativeWindowService } from '../app/core/services/window.service';
import { APP_CONFIG, AppConfig } from '../config/app-config.interface';
import { DOCUMENT } from '@angular/common';

/**
 * Service responsible for injecting and configuring the consentmanager CMP
 * https://www.consentmanager.net/
 *
 * This library uses the global window object to store configuration properties, so it should only be used client-side
 */
@Injectable()
export class ConsentManagerService {
  constructor(
    @Inject(NativeWindowService) protected _window: NativeWindowRef,
    @Inject(DOCUMENT) private document: any,
    @Inject(APP_CONFIG) protected appConfig: AppConfig,
  ) {
  }

  /**
   * Initialize consentmanager. Should be called during app initialization.
   */
  init() {
    this.configure();
    this.inject();
  }

  /**
   * Configure consentmanager through global window properties
   * https://help.consentmanager.net/books/cmp/page/client-side-configuration-options
   * @private
   */
  private configure() {
    const config = {
      cmp_block_ignoredomains: [
        // Never block REST requests; these include essential site content (e.g. thumbnails)
        this.appConfig.rest.host,
      ],
    };

    for (const [key, value] of Object.entries(config)) {
      this._window.nativeWindow[key] = value;
    }
  }

  /**
   * Inject consentmanager script into the DOM
   * @private
   */
  private inject() {
    const script = this.document.createElement('script');

    script.type = 'text/javascript';
    script.src = 'https://cdn.consentmanager.net/delivery/autoblocking/0be8b51051d1e.js';

    script['data-cmp-host'] = 'c.delivery.consentmanager.net';
    script['data-cmp-cdn'] = 'cdn.consentmanager.net';
    script['data-cmp-codesrc'] = 1;
    script['data-cmp-ab'] = 1;

    this.document.body.appendChild(script);
  }
}
