<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<ds-metadata-field-line-wrapper [label]="label"
                                [labelSuffix]="labelSuffix"
                                [labelClass]="labelClass"
                                [labelTooltip]="labelTooltip">
  <ul *ngIf="ul">
    <ng-container *ngFor="let mdValue of mdValues; let idx = index">
      <li *ngIf="mdValue && (!initialSize || showAll || (idx < initialSize))">
        <ng-container *ngTemplateOutlet="value; context: {mdValue: mdValue, idx: idx}"></ng-container>
      </li>
    </ng-container>
    <ng-container *ngTemplateOutlet="toggles"></ng-container>
  </ul>
  <ng-container *ngIf="!ul">
    <ng-container *ngFor="let mdValue of mdValues; let idx = index">
        <ng-container *ngTemplateOutlet="value; context: {mdValue: mdValue, idx: idx}"></ng-container>
    </ng-container>
    <ng-container *ngTemplateOutlet="toggles"></ng-container>
  </ng-container>
</ds-metadata-field-line-wrapper>

<ng-template #value let-mdValue="mdValue" let-idx="idx">
  <ng-container *ngIf="(!initialSize || showAll || (idx < initialSize))">
    <span *ngIf="!displayLink"
          class="{{valueClass}}" [ngClass]="{'d-inline-block': !ul}">
          {{ mdValue }}<span *ngIf="showSeparator(idx)">{{separator}} </span>
    </span>
    <span *ngIf="displayLink">
      <a class="link-indicator {{valueClass}}" [ngClass]="{'d-inline-block': !ul}"
         [href]="linkTransform(mdValue)" [target]="externalLink ? '_blank' : '_self'">
        {{ linkText ? linkText : (linkAsValue ? (linkTransform(mdValue)) : mdValue) }}
      </a><span *ngIf="showSeparator(idx)">{{separator}} </span>
    </span>
  </ng-container>
</ng-template>

<ng-template #toggles>
  <ng-container *ngIf="initialSize !== undefined && !showAll && (mdValues.length > initialSize)">
    <div class="d-inline-block md-show-toggle" [ngClass]="expandClass ? expandClass : valueClass" (click)="showAll = true">
      {{ showAllLabel | translate:{ amount: mdValues.length - initialSize } }}
    </div>
  </ng-container>
  <div class="d-inline-block md-show-toggle" [ngClass]="expandClass ? expandClass : valueClass"
       *ngIf="collapsible && showAll"
       (click)="showAll = false">
    {{ showLessLabel | translate:{ amount: mdValues.length - initialSize } }}
  </div>
</ng-template>
