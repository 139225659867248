<div class="space-children-mr">
  <a class="btn btn-primary mt-1 mb-3"
     ngbTooltip="{{'submission.workflow.generic.edit-help' | translate}}"
     [routerLink]="['/workspaceitems/' + object.id + '/edit']"
     role="button">
    <i class="fa fa-edit"></i> {{'submission.workflow.generic.edit' | translate}}
  </a>

  <button type="button"
          class="btn btn-danger mt-1 mb-3"
          ngbTooltip="{{'submission.workflow.generic.delete-help' | translate}}"
          (click)="$event.preventDefault();confirmDiscard(content)">
    <span *ngIf="(processingDelete$ | async)"><i class='fas fa-circle-notch fa-spin'></i> {{'submission.workflow.tasks.generic.processing' | translate}}</span>
    <span *ngIf="!(processingDelete$ | async)"><i class="fa fa-trash"></i> {{'submission.workflow.generic.delete' | translate}}</span>
  </button>
</div>

<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-danger">{{'submission.general.discard.confirm.title' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('cancel')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{'submission.general.discard.confirm.info' | translate}}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="c('cancel')">{{'submission.general.discard.confirm.cancel' | translate}}</button>
    <button type="button" class="btn btn-danger" (click)="c('ok')">{{'submission.general.discard.confirm.submit' | translate}}</button>
  </div>
</ng-template>
