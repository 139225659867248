/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../app/shared/shared.module';
import { ClientAppRoutingModule } from './client-app-routing.module';
import { AtmireAppModule } from '../app-atmire/atmire-app.module';
import {
  ClientStyledItemPageFieldComponent
} from './item-page/field-components/specific-field/source/client-styled-item-page-field.component';
import { SignInComponent } from './item-page/sections/sign-in/sign-in.component';
import { ClientMultilingualCollectionsComponent } from './item-page/field-components/multilingual-collections/multilingual-collections.component';
import { NgbModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { FaqButtonComponent } from './display-faq/faq-button.component';

const DECLARATIONS = [
  ClientStyledItemPageFieldComponent,
  ClientMultilingualCollectionsComponent,
  SignInComponent,
  FaqButtonComponent,
];

/**
 * Add components that use a custom decorator to ENTRY_COMPONENTS as well as DECLARATIONS. This will
 * ensure that decorator gets picked up when the app loads
 */
const ENTRY_COMPONENTS = [
];

const PROVIDERS = [
];

@NgModule({
  declarations: [
    ...DECLARATIONS,
  ],
  providers: [
    ...PROVIDERS,
    ...ENTRY_COMPONENTS.map((component) => ({ provide: component })),
  ],
  imports: [
    CommonModule,
    SharedModule,
    ClientAppRoutingModule,
    AtmireAppModule,
    NgbModule,
    NgbModalModule,
  ],
  exports: [
    ...DECLARATIONS,
  ]
})
export class ClientAppModule {
}
