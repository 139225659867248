/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { CreateComColPageComponent } from '../../../../../app/shared/comcol/comcol-forms/create-comcol-page/create-comcol-page.component';
import { Collection } from '../../../../../app/core/shared/collection.model';
import { Community } from '../../../../../app/core/shared/community.model';
import { ComColDataService } from '../../../../../app/core/data/comcol-data.service';
import { CommunityDataService } from '../../../../../app/core/data/community-data.service';
import { RouteService } from '../../../../../app/core/services/route.service';
import { NotificationsService } from '../../../../../app/shared/notifications/notifications.service';
import { DSONameService } from '../../../../../app/core/breadcrumbs/dso-name.service';
import { RequestService } from '../../../../../app/core/data/request.service';
import { isNotEmpty } from '../../../../../app/shared/empty.util';

/**
 * Component representing the create page for communities and collections
 */
@Component({
  selector: 'ds-create-comcol',
  template: ''
})
export class AtmireCreateComColPageComponent<TDomain extends Collection | Community> extends CreateComColPageComponent<TDomain> {

  /**
   * The name of the parent community of the object that is to be created
   */
  public parentName$: Observable<string>;

  public constructor(
    protected dsoDataService: ComColDataService<TDomain>,
    protected parentDataService: CommunityDataService,
    protected routeService: RouteService,
    protected router: Router,
    protected notificationsService: NotificationsService,
    protected translate: TranslateService,
    protected requestService: RequestService,
    protected dsoNameService: DSONameService,
  ) {
    super(
      dsoDataService,
      parentDataService,
      routeService,
      router,
      notificationsService,
      translate,
      requestService,
    );
  }

  ngOnInit(): void {
    this.parentUUID$ = this.routeService.getQueryParameterValue('parent');
    this.parentUUID$.pipe(take(1)).subscribe((parentID: string) => {
      if (isNotEmpty(parentID)) {
        this.parentRD$ = this.parentDataService.findById(parentID);
        this.parentName$ = this.parentRD$.pipe(
          map(parentRD => this.dsoNameService.getName(parentRD.payload))
        );
      }
    });
  }

  /**
   * Creates a new DSO based on the submitted user data and navigates to the new object's home page
   * @param event   The event returned by the community/collection form. Contains the new dso and logo uploader
   */
  onSubmit(event) {
    const dso = event.dso;

    // set the supported languages of this new DSO
    dso.metadata[environment.atmire.multiLanguage.metadataField] = environment.atmire.multiLanguage.defaultLanguages.map(lang => {
      return {
        language: null,
        value: lang,
      };
    });

    super.onSubmit(event);
  }
}
