/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AtmireSidebarSearchListElementComponent } from '../../../../../../../../app-atmire/shared/object-list/sidebar-search-list-element/atmire-sidebar-search-list-element.component';
import { Context } from '../../../../../../../../app/core/shared/context.model';
import { ViewMode } from '../../../../../../../../app/core/shared/view-mode.model';
import { listableObjectComponent } from '../../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { ItemSearchResult } from '../../../../../../../../app/shared/object-collection/shared/item-search-result.model';
import { Item } from '../../../../../../../../app/core/shared/item.model';
import { TruncatableService } from '../../../../../../../../app/shared/truncatable/truncatable.service';
import { LinkService } from '../../../../../../../../app/core/cache/builders/link.service';
import { DSONameService } from '../../../../../../../../app/core/breadcrumbs/dso-name.service';
import { isNotEmpty } from '../../../../../../../../app/shared/empty.util';
import { MultilingualMetadataService } from '../../../../../../../../app-atmire/core/shared/multilingual-metadata.service';

@listableObjectComponent('PersonSearchResult', ViewMode.ListElement, Context.SideBarSearchModal, 'atmire')
@listableObjectComponent('PersonSearchResult', ViewMode.ListElement, Context.SideBarSearchModalCurrent, 'atmire')
@Component({
  selector: 'ds-person-sidebar-search-list-element',
  templateUrl: '../../../../../../../../app/shared/object-list/sidebar-search-list-element/sidebar-search-list-element.component.html'
})
/**
 * Component displaying a list element for a {@link ItemSearchResult} of type "Person" within the context of
 * a sidebar search modal
 */
export class PersonSidebarSearchListElementComponent extends AtmireSidebarSearchListElementComponent<ItemSearchResult, Item> {
  constructor(
    protected truncatableService: TruncatableService,
    protected linkService: LinkService,
    protected translateService: TranslateService,
    protected dsoNameService: DSONameService,
    protected multilingualMetadataService: MultilingualMetadataService,
  ) {
    super(truncatableService, linkService, dsoNameService, multilingualMetadataService);
  }

  /**
   * Get the title of the Person by returning a combination of its family name and given name (or "No name found")
   */
  getTitle(): string {
    const familyName = this.firstMetadataValue('person.familyName');
    const givenName = this.firstMetadataValue('person.givenName');
    let title = '';
    if (isNotEmpty(familyName)) {
      title = familyName;
    }
    if (isNotEmpty(title)) {
      title += ', ';
    }
    if (isNotEmpty(givenName)) {
      title += givenName;
    }
    return this.defaultIfEmpty(title, this.translateService.instant('person.listelement.no-title'));
  }

  /**
   * Get the description of the Person by returning its job title(s)
   */
  getDescription(): string {
    const titles = this.allMetadataValues(['person.jobTitle']);
    let description = '';
    if (isNotEmpty(titles)) {
      description += titles.join(', ');
    }
    return this.undefinedIfEmpty(description);
  }
}
