import { Component, Input } from '@angular/core';
import { ThemedComponent } from '../../theme-support/themed.component';
import { SearchLabelsComponent } from './search-labels.component';

/**
 * Themed wrapper for SearchFiltersComponent
 */
@Component({
  selector: 'ds-themed-search-labels',
  styleUrls: [],
  templateUrl: '../../theme-support/themed.component.html',
})
export class ThemedSearchLabelsComponent extends ThemedComponent<SearchLabelsComponent> {

    /**
     * True when the search component should show results on the current page
     */
    @Input() inPlaceSearch;

    protected getComponentName(): string {
        return 'SearchLabelsComponent';
    }
    protected importThemedComponent(themeName: string): Promise<any> {
        return import(`../../../../themes/${themeName}/app/shared/search/search-labels/search-labels.component`);
    }
    protected importUnthemedComponent(): Promise<any> {
        return import('./search-labels.component');
    }

}
