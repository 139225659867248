import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemedComponent } from '../../shared/theme-support/themed.component';
import { CommunityFormComponent } from './community-form.component';
import { Community } from '../../core/shared/community.model';
import { FileUploader } from 'ng2-file-upload';
import { Operation } from 'fast-json-patch';

/**
 * Form used for creating and editing communities
 */
@Component({
  selector: 'ds-themed-community-form',
  styleUrls: [],
  templateUrl: '../../shared/theme-support/themed.component.html',
})
export class ThemedCommunityFormComponent extends ThemedComponent<CommunityFormComponent> {

  @Input() dso: Community = new Community();
  @Output() submitForm: EventEmitter<{
    dso: Community,
    uploader: FileUploader,
    deleteLogo: boolean,
    operations: Operation[],
  }> = new EventEmitter();
  @Output() back: EventEmitter<any> = new EventEmitter<any>();
  @Output() finish: EventEmitter<any> = new EventEmitter<any>();

  protected inAndOutputNames: (keyof CommunityFormComponent & keyof this)[] = [
    'dso',
    'submitForm',
    'back',
    'finish',
  ];

  protected getComponentName(): string {
    return 'CommunityFormComponent';
  }

  protected importThemedComponent(themeName: string): Promise<any> {
    return import(`../../../themes/${themeName}/app/community-page/community-form/community-form.component`);
  }

  protected importUnthemedComponent(): Promise<any> {
    return import('./community-form.component');
  }
}
