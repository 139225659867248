/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { PaginationComponentOptions } from '../../../app/shared/pagination/pagination-component-options.model';
import { SortOptions } from '../../../app/core/cache/models/sort-options.model';
import { RemoteData } from '../../../app/core/data/remote-data';
import { PaginatedList } from '../../../app/core/data/paginated-list.model';
import { PaginatedSearchOptions } from '../../../app/shared/search/models/paginated-search-options.model';
import { SearchService } from '../../../app/core/shared/search/search.service';
import { SearchOptions } from '../../../app/shared/search/models/search-options.model';
import { AtmireSearchListableObjectSourceModel } from '../listable-object-sources';
import { AbstractListableObjectSource, AbstractListableObjectSourceModel } from './abstract-listable-object-source';
import { map } from 'rxjs/operators';
import { DSpaceObject } from '../../../app/core/shared/dspace-object.model';
import { ListableObject } from '../../../app/shared/object-collection/shared/listable-object.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ListableCacheableObject } from '../base/listable-cachable-object.model';

/**
 * Retrieves objects via {@link SearchService}.
 */
export class AtmireSearchListableObjectSource<T extends DSpaceObject> extends AbstractListableObjectSource<T> {
  private readonly searchOptions: Partial<SearchOptions>;
  private readonly displayAsSearchResults: boolean;

  private readonly href$: BehaviorSubject<string> = new BehaviorSubject<string>('search');

  constructor(
    model: AtmireSearchListableObjectSourceModel<T>,
    private searchService: SearchService,
  ) {
    super(model as AbstractListableObjectSourceModel<T>);
    this.searchOptions = model.searchOptions;
    this.displayAsSearchResults = model.displayAsSearchResults;

    this.searchService.getEndpoint(
      Object.assign(new PaginatedSearchOptions({}), this.searchOptions)
    ).subscribe(href => {
      this.href$.next(href.replace(environment.rest.baseUrl + '/api', ''));
    });
  }

  public getList(
    paginationOptions: Partial<PaginationComponentOptions>, sortOptions: Partial<SortOptions>
  ): Observable<RemoteData<PaginatedList<ListableObject>>> {
    return this.searchService.search<T>(
      Object.assign(new PaginatedSearchOptions({}), {
        ...this.searchOptions,
        sort: sortOptions,
        pagination: paginationOptions,
      }),
      undefined, // responseMsToLive
      this.useCachedVersionIfAvailable,
      this.reRequestOnStale,
      ...this.linksToFollow,
    ).pipe(
      map((rd) => {
        if (!this.displayAsSearchResults && rd.payload?.page !== undefined) {
          return Object.assign(rd, {
            payload: Object.assign(rd.payload, {
              page: rd.payload.page.map((obj) => obj.indexableObject)
            })
          });
        }
        return rd;
      }),
    );
  }

  public get params() {
    return {
      href: this.href$.getValue(),
    };
  }
}
