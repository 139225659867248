<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="search-result-list">
  <!-- Community logo -->
  <div class="item-search-result-list-element-thumbnail-container" *ngIf="showThumbnail">
    <ds-comcol-page-logo *ngIf="dso.logo" [logo]="(dso.logo | async)?.payload" [alternateText]="'Community Logo'">
    </ds-comcol-page-logo>
  </div>
  <ds-themed-badges *ngIf="showLabel" [object]="dso"></ds-themed-badges>
  <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'"
     [attr.rel]="(linkType == linkTypes.ExternalLink) ? 'noopener noreferrer' : null"
     [routerLink]="['/communities/' + dso.id]" class="lead search-result-list-title" [innerHTML]="dsoTitle"></a>
  <span *ngIf="linkType == linkTypes.None" class="lead" [innerHTML]="dsoTitle"></span>
  <div *ngIf="dso.shortDescription" class="text-muted abstract-text"
       [innerHTML]="firstMetadataValue('dc.description.abstract')"></div>
</div>
