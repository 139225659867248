/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { autoserialize, inheritSerialization, autoserializeAs } from 'cerialize';
import { MetadataMap, MetadataMapSerializer } from '../../../../app/core/shared/metadata.models';
import { typedObject } from '../../../../app/core/cache/builders/build-decorators';
import { DSpaceObject } from '../../../../app/core/shared/dspace-object.model';
import { SUGGESTION } from '../resource-types/suggestion.resource-type';
import { excludeFromEquals } from '../../../../app/core/utilities/equals.decorators';

/**
 * Class that represents a suggestion for a user's input
 */
@typedObject
@inheritSerialization(DSpaceObject)
export class Suggestion extends DSpaceObject {
  static type = SUGGESTION;

  /**
   * The highlighted matching result
   */
  @excludeFromEquals
  @autoserializeAs(MetadataMapSerializer)
  hitHighlights: MetadataMap;

  /**
   * The label that was found
   */
  @autoserialize
  label: string;

  @autoserialize
  queryParams: string[];
}
