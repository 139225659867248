/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */

import { Observable, of as observableOf, Subscription } from 'rxjs';

import { switchMap } from 'rxjs/operators';
import { Component, OnInit, Input } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { AppState } from '../../../../app/app.reducer';
import { isAuthenticated } from '../../../../app/core/auth/selectors';
import { fadeInOut, fadeOut } from '../../../../app/shared/animations/fade';
import { HostWindowService } from '../../../../app/shared/host-window.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { AtmireAuthService } from '../../../../app-atmire/core/auth/atmire-auth.service';
import { Item } from '../../../../app/core/shared/item.model';

@Component({
  selector: 'ds-client-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  animations: [fadeInOut, fadeOut]
})
export class SignInComponent implements OnInit {

  @Input()
  item: Item;

  public isAuthenticated: Observable<boolean>;

  public field = 'fscdoc.hashidden.user';

  public hasHidden: boolean;

  redirectRoute$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

  constructor(protected store: Store<AppState>,
              protected windowService: HostWindowService,
              protected authService: AtmireAuthService
  ) {
  }

  private stringToBoolean(str: string): boolean {
    if (str !== undefined) {
      return str.toLowerCase() === 'yes';
    }
    return false;
  }

  ngOnInit(): void {
    // set isAuthenticated
    this.isAuthenticated = this.store.pipe(select(isAuthenticated));

    // set hasHidden
    this.hasHidden = this.stringToBoolean(this.item?.firstMetadataValue(this.field));


  }

  signIn(): void {
    console.log('Sign in button clicked');

    this.isAuthenticated.pipe(
      switchMap((authenticated) => {
        if (!authenticated) {
          console.log('not authenticated');
          return this.authService.getSsoServerUrl(); // Return the Observable
        } else {
          // If already authenticated, return undefined
          return observableOf(undefined);
        }
      })
    ).subscribe((path) => {
      console.log('path', path);
      this.redirectRoute$.next(path); // Subscribe to the resulting Observable
    });
  }
}
