/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';
import { find, map } from 'rxjs/operators';
import { SearchResult } from '../../../../app/shared/search/models/search-result.model';
import { DSpaceObject } from '../../../../app/core/shared/dspace-object.model';
import { AtmireSearchResultListElementComponent } from '../search-result-list-element/atmire-search-result-list-element.component';
import { TruncatableService } from '../../../../app/shared/truncatable/truncatable.service';
import { DSONameService } from '../../../../app/core/breadcrumbs/dso-name.service';
import { LinkService } from '../../../../app/core/cache/builders/link.service';
import { hasValue, isNotEmpty } from '../../../../app/shared/empty.util';
import { Context } from '../../../../app/core/shared/context.model';
import { RemoteData } from '../../../../app/core/data/remote-data';
import { followLink } from '../../../../app/shared/utils/follow-link-config.model';
import { ChildHALResource } from '../../../../app/core/shared/child-hal-resource.model';
import { MultilingualMetadataService } from '../../../core/shared/multilingual-metadata.service';

@Component({
  selector: 'ds-sidebar-search-list-element',
  templateUrl: '../../../../app/shared/object-list/sidebar-search-list-element/sidebar-search-list-element.component.html',
})
/**
 * Component displaying a list element for a {@link SearchResult} in the sidebar search modal
 * It displays the name of the parent, title and description of the object. All of which are customizable in the child
 * component by overriding the relevant methods of this component
 */
export class AtmireSidebarSearchListElementComponent<T extends SearchResult<K>, K extends DSpaceObject> extends AtmireSearchResultListElementComponent<T, K> {
  /**
   * Observable for the title of the parent object (displayed above the object's title)
   */
  parentTitle$: Observable<string>;

  /**
   * The title for the object to display
   */
  title: string;

  /**
   * A description to display below the title
   */
  description: string;

  public constructor(
    protected truncatableService: TruncatableService,
    protected linkService: LinkService,
    protected dsoNameService: DSONameService,
    protected multilingualMetadataService: MultilingualMetadataService,
  ) {
    super(truncatableService, dsoNameService, multilingualMetadataService);
  }

  /**
   * Initialise the component variables
   */
  ngOnInit(): void {
    super.ngOnInit();
    if (hasValue(this.dso)) {
      this.parentTitle$ = this.getParentTitle();
      this.title = this.getTitle();
      this.description = this.getDescription();
    }
  }

  /**
   * returns true if this element represents the current dso
   */
  isCurrent(): boolean {
    return this.context === Context.SideBarSearchModalCurrent;
  }

  /**
   * Get the title of the object's parent
   * Retrieve the parent by using the object's parent link and retrieving its 'dc.title' metadata
   */
  getParentTitle(): Observable<string> {
    return this.getParent().pipe(
      map((parentRD: RemoteData<DSpaceObject>) => {
        return hasValue(parentRD) && hasValue(parentRD.payload)
          ? this.multilingualMetadataService.firstValueCurrentLang(parentRD.payload.metadata, ['dc.title'])
          : undefined;
      })
    );
  }

  /**
   * Get the parent of the object
   */
  getParent(): Observable<RemoteData<DSpaceObject>> {
    if (typeof (this.dso as any).getParentLinkKey === 'function') {
      const propertyName = (this.dso as any).getParentLinkKey();
      return this.linkService.resolveLink(this.dso, followLink(propertyName))[propertyName].pipe(
        find((parentRD: RemoteData<ChildHALResource & DSpaceObject>) => parentRD.hasSucceeded || parentRD.statusCode === 204)
      );
    }
    return observableOf(undefined);
  }

  /**
   * Get the title of the object
   * Default: "dc.title"
   */
  getTitle(): string {
    return this.firstMetadataValue('dc.title');
  }

  /**
   * Get the description of the object
   * Default: "(dc.publisher, dc.date.issued) authors"
   */
  getDescription(): string {
    const publisher = this.firstMetadataValue('dc.publisher');
    const date = this.firstMetadataValue('dc.date.issued');
    const authors = this.allMetadataValues(['dc.contributor.author', 'dc.creator', 'dc.contributor.*']);
    let description = '';
    if (isNotEmpty(publisher) || isNotEmpty(date)) {
      description += '(';
    }
    if (isNotEmpty(publisher)) {
      description += publisher;
    }
    if (isNotEmpty(date)) {
      if (isNotEmpty(publisher)) {
        description += ', ';
      }
      description += date;
    }
    if (isNotEmpty(description)) {
      description += ') ';
    }
    if (isNotEmpty(authors)) {
      authors.forEach((author, i) => {
        description += author;
        if (i < (authors.length - 1)) {
          description += '; ';
        }
      });
    }
    return this.undefinedIfEmpty(description);
  }

  /**
   * Return undefined if the provided string is empty
   * @param value Value to check
   */
  undefinedIfEmpty(value: string) {
    return this.defaultIfEmpty(value, undefined);
  }

  /**
   * Return a default value if the provided string is empty
   * @param value Value to check
   * @param def   Default in case value is empty
   */
  defaultIfEmpty(value: string, def: string) {
    if (isNotEmpty(value)) {
      return value;
    } else {
      return def;
    }
  }
}
