<ds-themed-badges *ngIf="showLabel" [object]="dso"></ds-themed-badges>
<ds-truncatable [id]="dso.id">
    <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" [attr.rel]="(linkType == linkTypes.ExternalLink) ? 'noopener noreferrer' : null"
            [routerLink]="[itemPageRoute]" class="lead item-list-title dont-break-out"
            [innerHTML]="dsoTitle"></a>
    <span *ngIf="linkType == linkTypes.None"
          class="lead item-list-title dont-break-out"
          [innerHTML]="dsoTitle"></span>
    <span class="text-muted">
    <ds-truncatable-part [id]="dso.id" [minLines]="1">
            <span *ngIf="dso.allMetadata(['creativeworkseries.issn']).length > 0"
                  class="item-list-journals">
                    <span *ngFor="let value of allMetadataValues(['creativeworkseries.issn']); let last=last;">
                        <span [innerHTML]="value"><span [innerHTML]="value"></span></span>
                    </span>
            </span>
        </ds-truncatable-part>
  </span>
</ds-truncatable>
