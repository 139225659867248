/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, ComponentFactoryResolver, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ViewMode } from '../../../../../../../../app/core/shared/view-mode.model';
import { ItemSearchResult } from '../../../../../../../../app/shared/object-collection/shared/item-search-result.model';
import { Context } from '../../../../../../../../app/core/shared/context.model';
import {
  getListableObjectComponent,
  listableObjectComponent
} from '../../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { ListableObjectDirective } from '../../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.directive';
import { Item } from '../../../../../../../../app/core/shared/item.model';
import { TruncatableService } from '../../../../../../../../app/shared/truncatable/truncatable.service';
import { BitstreamDataService } from '../../../../../../../../app/core/data/bitstream-data.service';
import { ThemeService } from '../../../../../../../../app/shared/theme-support/theme.service';
import { MultilingualMetadataService } from '../../../../../../../../app-atmire/core/shared/multilingual-metadata.service';
import { GenericConstructor } from '../../../../../../../../app/core/shared/generic-constructor';
import { AtmireSearchResultGridElementComponent } from '../../../../../../../../app-atmire/shared/object-grid/search-result-grid-element/atmire-search-result-grid-element.component';

/**
 * The component for displaying a list element for an item search result on the admin search page
 */
@listableObjectComponent(ItemSearchResult, ViewMode.GridElement, Context.AdminSearch, 'atmire')
@Component({
  selector: 'ds-item-admin-search-result-grid-element',
  styleUrls: ['../../../../../../../../app/admin/admin-search-page/admin-search-results/admin-search-result-grid-element/item-search-result/item-admin-search-result-grid-element.component.scss'],
  templateUrl: '../../../../../../../../app/admin/admin-search-page/admin-search-results/admin-search-result-grid-element/item-search-result/item-admin-search-result-grid-element.component.html',
})
export class ItemAdminSearchResultGridElementComponent extends AtmireSearchResultGridElementComponent<ItemSearchResult, Item> implements OnInit {
  @ViewChild(ListableObjectDirective, { static: true }) listableObjectDirective: ListableObjectDirective;
  @ViewChild('badges', { static: true }) badges: ElementRef;
  @ViewChild('buttons', { static: true }) buttons: ElementRef;

  constructor(
    protected truncatableService: TruncatableService,
    protected bitstreamDataService: BitstreamDataService,
    private themeService: ThemeService,
    private componentFactoryResolver: ComponentFactoryResolver,
    protected multilingualMetadataService: MultilingualMetadataService,
  ) {
    super(truncatableService, bitstreamDataService, multilingualMetadataService);
  }

  /**
   * Setup the dynamic child component
   */
  ngOnInit(): void {
    super.ngOnInit();
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.getComponent());

    const viewContainerRef = this.listableObjectDirective.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(
      componentFactory,
      0,
      undefined,
      [
        [this.badges.nativeElement],
        [this.buttons.nativeElement]
      ]);
    (componentRef.instance as any).object = this.object;
    (componentRef.instance as any).index = this.index;
    (componentRef.instance as any).linkType = this.linkType;
    (componentRef.instance as any).listID = this.listID;
  }

  /**
   * Fetch the component depending on the item's entity type, view mode and context
   * @returns {GenericConstructor<Component>}
   */
  private getComponent(): GenericConstructor<Component> {
    return getListableObjectComponent(this.object.getRenderTypes(), ViewMode.GridElement, undefined, this.themeService.getThemeName());
  }
}
