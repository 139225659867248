<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<ul class="navbar-nav" [ngClass]="{'mr-auto': (isXsOrSm$ | async)}">
  <li *ngIf="!(isAuthenticated | async) && !(isXsOrSm$ | async) && (showAuth | async)" class="nav-item"
      (click)="$event.stopPropagation();">
    <div display="dynamic" placement="bottom-right" class="d-inline-block" @fadeInOut>
      <a *ngIf="redirectRoute$| async" [href]="redirectRoute$ |async" class="dropdownLogin px-3 py-2">
        <i class="far fa-user"></i>{{ 'nav.login' | translate }}
      </a>
    </div>
  </li>
  <li *ngIf="!(isAuthenticated | async) && (isXsOrSm$ | async)" class="nav-item">
    <a *ngIf="redirectRoute$| async" routerLinkActive="active" [href]="redirectRoute$ |async" class="loginLink px-3 py-2">
      <i class="far fa-user"></i>{{ 'nav.login' | translate }}<span class="sr-only">(current)</span>
    </a>
  </li>
  <li *ngIf="(isAuthenticated | async) && !(isXsOrSm$ | async) && (showAuth | async)" class="nav-item">
    <div ngbDropdown display="dynamic" placement="bottom-right" class="d-inline-block" @fadeInOut>
      <a href="#" role="button" [attr.aria-label]="'nav.logout' |translate" (click)="$event.preventDefault()" [title]="'nav.logout' | translate" class="px-3 py-2" ngbDropdownToggle>
        <i class="fas fa-user-circle fa-lg fa-fw"></i></a>
      <div class="logoutDropdownMenu" ngbDropdownMenu [attr.aria-label]="'nav.logout' |translate">
        <ds-user-menu></ds-user-menu>
      </div>
    </div>
  </li>
  <li *ngIf="(isAuthenticated | async) && (isXsOrSm$ | async)" class="nav-item">
    <a id="logoutLink" role="button" [attr.aria-label]="'nav.logout' |translate" [title]="'nav.logout' | translate" routerLink="/logout" routerLinkActive="active" class="px-3 py-2">
      <i class="fas fa-user-circle fa-lg fa-fw"></i>
      <span class="sr-only">(current)</span>
    </a>
  </li>
</ul>
