<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="input-group" (keydown)="focusInput($event)"
     (keydown.arrowdown)="shiftFocusDown($event)"
     (keydown.arrowup)="shiftFocusUp($event)" (keydown.esc)="close()" (dsClickOutside)="close()">
    <span class="input-fields input-group-{{inputSize}} input-group-prepend">
        <input *ngIf="isNotEmpty(suggestedQuery)" autocomplete="off" type="text" name="suggested_query"
               class="form-control" id="suggestion-input" [value]="getSuggestionTitle()" />
        <input #queryInput type="text" [formControl]="searchControl" autocomplete="off" name="query" (ngModelChange)="emitQuery($event)"
               class="form-control {{(show | async) ? 'autocomplete-show' : ''}}" [class.bg-white]="!isNotEmpty(suggestedQuery)" id="actual-input"
               [placeholder]="placeholder"
               (focus)="onFocus()" [ngModel]="query" aria-label="Search input" />
        <div *ngIf="loading$ | async" class="loading-spinner d-flex align-items-center position-absolute mr-1">
            <div class="spinner-border text-secondary"
                 role="status">
                <span class="sr-only">{{ 'loading.default' | translate }}</span>
            </div>
        </div>
        <div class="autocomplete dropdown-menu p-0 {{(show | async) ? 'show' : ''}}">
            <div class="row no-gutters">
                <ul class="results list-unstyled col-12 col-md-6 m-0 p-0 mh-100">
                    <div *ngIf="loading$ | async" class="d-flex h-100 justify-content-center align-items-center">
                        <ds-loading [showMessage]="false"></ds-loading>
                    </div>
                    <ng-container *ngFor="let category of (resultCategories | async)">
                        <ng-container *ngIf="category.values.page.length > 0">
                            <ng-container *ngIf="category.name !== 'search'">
                                <li class="category-title">
                                    <h6 class="dropdown-item">{{category.name}}</h6>
                                </li>
                            </ng-container>
                            <ds-suggestion-result-wrapper
                              #suggestion
                              *ngFor="let value of category.values.page"
                              [suggestion]="value"
                              [active]="selected === value"
                              [category]="category"
                              class="thumb-font-1"
                              (click)="selectItem(value, resultViews.toArray().indexOf(suggestion), category)">
                            </ds-suggestion-result-wrapper>
                        </ng-container>
                    </ng-container>
                </ul>
                <div class="preview d-none d-md-block col-6 p-3 mh-100">
                    <ng-container>
                        <ds-suggestion-preview-wrapper [searchOptions]="searchOptions"
                                                       [suggestion]="selected"
                                                       [category]="selectedCategory"></ds-suggestion-preview-wrapper>
                    </ng-container>
                </div>
            </div>
        </div>
    </span>
  <span *ngIf="showButton" class="input-group-append">
        <button (click)="submit()" type="submit"
                class="search-button btn btn-{{brandColor}}">{{ ('search.form.search' | translate) }}</button>
    </span>

</div>
