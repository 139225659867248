/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { rendersObjectSuggestionPreview } from '../../../suggestion.decorator';
import { Collection } from '../../../../../../../../app/core/shared/collection.model';
import { DSOSuggestionPreviewComponent } from '../dso-suggestion-preview.component';
import { RemoteData } from '../../../../../../../../app/core/data/remote-data';
import {
  SortDirection,
  SortOptions
} from '../../../../../../../../app/core/cache/models/sort-options.model';
import { SearchService } from '../../../../../../../../app/core/shared/search/search.service';
import { PaginationComponentOptions } from '../../../../../../../../app/shared/pagination/pagination-component-options.model';
import { DSpaceObject } from '../../../../../../../../app/core/shared/dspace-object.model';
import { PaginatedList } from '../../../../../../../../app/core/data/paginated-list.model';
import { SearchResult } from '../../../../../../../../app/shared/search/models/search-result.model';
import { PaginatedSearchOptions } from '../../../../../../../../app/shared/search/models/paginated-search-options.model';
import { DSONameService } from '../../../../../../../../app/core/breadcrumbs/dso-name.service';
import { Context } from '../../../../../../../../app/core/shared/context.model';

/**
 * This component renders a preview for a collection object suggestion
 * It shows a list of the 5 most recent submissions in the collection
 */

@Component({
  selector: 'ds-collection-suggestion-preview',
  styleUrls: ['./collection-suggestion-preview.component.scss'],
  templateUrl: './collection-suggestion-preview.component.html'
})
@rendersObjectSuggestionPreview(Collection)
export class CollectionSuggestionPreviewComponent extends DSOSuggestionPreviewComponent<Collection> implements OnInit {
  context = Context.QuerySuggestionPreviewList;

  /**
   * 5 most recent submissions in the collection
   */
  itemsRD$: Observable<RemoteData<PaginatedList<SearchResult<DSpaceObject>>>>;

  constructor(
    private searchService: SearchService,
    public dsoNameService: DSONameService,
  ) {
    super();
  }

  ngOnInit() {
    const searchOpts = Object.assign(
      new PaginatedSearchOptions({}),
      this.searchOptions,
      {
        query: undefined,
        scope: this.dso.uuid,
        pagination: Object.assign(new PaginationComponentOptions(), {
          currentPage: 1,
          pageSize: 5
        }),
        sort: new SortOptions('dc.date.issued', SortDirection.DESC)
      }
    );
    this.itemsRD$ = this.searchService.search(searchOpts);
  }
}
