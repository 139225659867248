/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import {
  Component, Input
} from '@angular/core';
import { TruncatableComponent } from '../../../app/shared/truncatable/truncatable.component';
import { TruncatableService } from '../../../app/shared/truncatable/truncatable.service';

@Component({
  selector: 'ds-atmire-truncatable',
  templateUrl: '../../../app/shared/truncatable/truncatable.component.html',
  styleUrls: ['../../../app/shared/truncatable/truncatable.component.scss'],

})
/**
 * Custom TruncatableComponent with the ability to disable the toggle
 * This is used in case we want to control the toggle from completely separate places (e.g. a button somewhere else)
 */
export class AtmireTruncatableComponent extends TruncatableComponent {
  /**
   * Disable the general toggle of this component
   * The component will still set the object's initial state
   */
  @Input() disabledToggle = false;

  constructor(protected service: TruncatableService) {
    super(service);
  }

  /**
   * Expands the truncatable when it's collapsed, collapses it when it's expanded
   */
  public toggle() {
    if (!this.disabledToggle) {
      this.service.toggle(this.id);
    }
  }

}
