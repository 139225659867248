/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { environment } from 'src/environments/environment';
import { MetadataMapInterface, MetadataValue, MetadataValueFilter, MetadataValueInterface } from '../../../app/core/shared/metadata.models';
import { Metadata } from '../../../app/core/shared/metadata.utils';
import { isEmpty } from '../../../app/shared/empty.util';

/**
 * Atmire utility class for working with DSpace object metadata.
 *
 * When specifying metadata keys, wildcards are supported, so `'*'` will match all keys, `'dc.date.*'` will
 * match all qualified dc dates, and so on. Exact keys will be evaluated (and matches returned) in the order
 * they are given.
 *
 * When multiple keys in a map match a given wildcard, they are evaluated in the order they are stored in
 * the map (alphanumeric if obtained from the REST api). If duplicate or overlapping keys are specified, the
 * first one takes precedence. For example, specifying `['dc.date', 'dc.*', '*']` will cause any `dc.date`
 * values to be evaluated (and returned, if matched) first, followed by any other `dc` metadata values,
 * followed by any other (non-dc) metadata values.
 */
export class AtmireMetadataUtils {

  /**
   * The language to fall back to if metadata can't be found in a requested language
   */
  static fallbackLang;

  /**
   * Gets all matching metadata in the map(s), prioritizing metadata values in a given language.
   *
   * @param {MetadataMapInterface|MetadataMapInterface[]} mapOrMaps The source map(s). When multiple maps are given, they will be
   * checked in order, and only values from the first with at least one match will be returned.
   * @param {string|string[]} keyOrKeys The metadata key(s) in scope. Wildcards are supported; see above.
   * @param {string} lang The preferred language for the metadata values. If a metadata field is not available in that
   * language, English will be used as a fallback. If no English value is found either, all of the available languages
   * will be returned.
   * @param {MetadataValueFilter} filter The value filter to use. If unspecified, no filtering will be done.
   * @returns {MetadataValue[]} the matching values or an empty array.
   */
  public static allForLang(mapOrMaps: MetadataMapInterface | MetadataMapInterface[], keyOrKeys: string | string[],
                           lang: string, filter?: MetadataValueFilter): MetadataValue[] {
    const mdMaps: MetadataMapInterface[] = mapOrMaps instanceof Array ? mapOrMaps : [mapOrMaps];
    const matches: MetadataValue[] = [];
    for (const mdMap of mdMaps) {
      for (const mdKey of Metadata.resolveKeys(mdMap, keyOrKeys)) {
        AtmireMetadataUtils.resolveLanguage(mdMap[mdKey], lang, filter)
                .forEach((candidate) => { matches.push(candidate as MetadataValue); });
      }
      if (!isEmpty(matches)) {
        return matches;
      }
    }
    return matches;
  }

  /**
   * Gets the first matching metadata in the map(s), prioritizing metadata values in a given language.
   *
   * @param {MetadataMapInterface|MetadataMapInterface[]} mapOrMaps The source map(s). When multiple maps are given, they will be
   * checked in order, and only values from the first with at least one match will be returned.
   * @param {string|string[]} keyOrKeys The metadata key(s) in scope. Wildcards are supported; see above.
   * @param {string} lang The preferred language for the metadata values. If a metadata field is not available in that
   * language, English will be used as a fallback. If no English value is found either, all of the available languages
   * will be returned.
   * @param {MetadataValueFilter} filter The value filter to use. If unspecified, no filtering will be done.
   * @returns {MetadataValue[]} the matching values or an empty array.
   */
  public static firstForLang(mapOrMaps: MetadataMapInterface | MetadataMapInterface[], keyOrKeys: string | string[],
    lang: string, filter?: MetadataValueFilter): MetadataValue {
    const mdMaps: MetadataMapInterface[] = mapOrMaps instanceof Array ? mapOrMaps : [mapOrMaps];
    for (const mdMap of mdMaps) {
      for (const mdKey of Metadata.resolveKeys(mdMap, keyOrKeys)) {
        const candidates = AtmireMetadataUtils.resolveLanguage(mdMap[mdKey], lang, filter);
        if (candidates.length > 0) {
          return candidates[0] as MetadataValue;
        }
      }
    }
    return undefined;
  }

  private static resolveLanguage(candidates: MetadataValueInterface[], lang: string, filter?: MetadataValueFilter): MetadataValue[] {
    const genericMatches = candidates.filter((c: MetadataValue) => Metadata.valueMatches(c, filter));

    if (!isEmpty(genericMatches)) {
      const languageMatches = genericMatches.filter((c: MetadataValue) => AtmireMetadataUtils.valueLangIs(c, lang)).filter((c: MetadataValue) => c.value !== null);
      if (!isEmpty(languageMatches)) {
        return languageMatches as MetadataValue[];
      }

      if (!AtmireMetadataUtils.fallbackLang) {
        AtmireMetadataUtils.fallbackLang = environment.languages.filter(language => language.active)[0].code;
      }

      const fallbackMatches = genericMatches.filter(
        (c: MetadataValue) => AtmireMetadataUtils.valueLangIs(c, AtmireMetadataUtils.fallbackLang)
      );
      return !isEmpty(fallbackMatches) ? fallbackMatches as MetadataValue[]
                                       : genericMatches as MetadataValue[];
    }
    return [];
  }

  /**
   * Checks if a value is in a specific language
   *
   * @param {MetadataValue} mdValue the value to check.
   * @param {MetadataValueFilter} the language to match.
   * @returns {boolean} whether the metadata field's language is set and matches the language provided.
   */
  public static valueLangIs(mdValue: MetadataValue, lang: string) {
    return lang && mdValue.language && lang === mdValue.language;
  }
}
