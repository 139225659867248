import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemedComponent } from '../../shared/theme-support/themed.component';
import { CollectionFormComponent } from './collection-form.component';
import { Collection } from '../../core/shared/collection.model';
import { FileUploader } from 'ng2-file-upload';
import { Operation } from 'fast-json-patch';

/**
 * Form used for creating and editing collections
 */
@Component({
  selector: 'ds-themed-collection-form',
  styleUrls: [],
  templateUrl: '../../shared/theme-support/themed.component.html',
})
export class ThemedCollectionFormComponent extends ThemedComponent<CollectionFormComponent> {

  @Input() dso: Collection = new Collection();
  @Output() submitForm: EventEmitter<{
    dso: Collection,
    uploader: FileUploader,
    deleteLogo: boolean,
    operations: Operation[],
  }> = new EventEmitter<{dso: Collection; uploader: FileUploader; deleteLogo: boolean; operations: Operation[]}>();
  @Output() back: EventEmitter<any> = new EventEmitter<any>();
  @Output() finish: EventEmitter<any> = new EventEmitter<any>();

  protected inAndOutputNames: (keyof CollectionFormComponent & keyof this)[] = [
    'dso',
    'submitForm',
    'back',
    'finish',
  ];

  protected getComponentName(): string {
    return 'CollectionFormComponent';
  }

  protected importThemedComponent(themeName: string): Promise<any> {
    return import(`../../../themes/${themeName}/app/collection-page/collection-form/collection-form.component`);
  }

  protected importUnthemedComponent(): Promise<any> {
    return import(`./collection-form.component`);
  }
}
