<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<li [ngClass]="{'active' :  active | async}">
    <a #link href="" (click)="select()" class="dropdown-item d-flex flex-row align-items-start">
        <span class="flex-grow-1" [innerHTML]="displayValue"></span>
        <span class="badge badge-secondary badge-pill mt-1">{{ suggestion.count }}</span>
    </a>
</li>
