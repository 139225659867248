/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Injectable } from '@angular/core';
import { Metadata } from '../../../app/core/shared/metadata.utils';
import { LocaleService } from '../../../app/core/locale/locale.service';
import { MetadataMapInterface, MetadataValue, MetadataValueFilter } from '../../../app/core/shared/metadata.models';
import { isUndefined } from '../../../app/shared/empty.util';
import { AtmireMetadataUtils } from './atmire-metadata.utils';

/**
 * Locale-aware metadata utils
 * Can be used as a slot-in replacement for the Metadata util class
 */
@Injectable({
    providedIn: 'root'
})
export class MultilingualMetadataService {
    all = Metadata.all;
    allForLang = AtmireMetadataUtils.allForLang;
    allValues = Metadata.allValues;
    first = Metadata.first;
    firstValue = Metadata.firstValue;
    has = Metadata.has;
    valueMatches = Metadata.valueMatches;
    valueLangIs = AtmireMetadataUtils.valueLangIs;
    toViewModelList = Metadata.toViewModelList;
    toMetadataMap = Metadata.toMetadataMap;
    setFirstValue = Metadata.setFirstValue;

    constructor(private localeService: LocaleService) {
    }


    /**
     * Gets all matching metadata in the map(s) for the language of the current locale.
     *
     * @param {MetadataMapInterface|MetadataMapInterface[]} mapOrMaps The source map(s). When multiple maps are given, they will be
     * checked in order, and only values from the first with at least one match will be returned.
     * @param {string|string[]} keyOrKeys The metadata key(s) in scope. Wildcards are supported; see above.
     * @param {MetadataValueFilter} filter The value filter to use. If unspecified, no filtering will be done.
     * @returns {MetadataValue[]} the matching values or an empty array.
     */
    public allCurrentLang(mapOrMaps: MetadataMapInterface | MetadataMapInterface[], keyOrKeys: string | string[],
                          filter?: MetadataValueFilter): MetadataValue[] {
        return AtmireMetadataUtils.allForLang(mapOrMaps, keyOrKeys, this.localeService.getCurrentLanguageCode(), filter);
    }

    /**
     * Gets all matching metadata in the map(s) for the language of the current locale as strings.
     *
     * @param {MetadataMapInterface|MetadataMapInterface[]} mapOrMaps The source map(s). When multiple maps are given, they will be
     * checked in order, and only values from the first with at least one match will be returned.
     * @param {string|string[]} keyOrKeys The metadata key(s) in scope. Wildcards are supported; see above.
     * @param {MetadataValueFilter} filter The value filter to use. If unspecified, no filtering will be done.
     * @returns {MetadataValue[]} the matching values or an empty array.
     */
    public allValuesCurrentLang(mapOrMaps: MetadataMapInterface | MetadataMapInterface[], keyOrKeys: string | string[],
                                filter?: MetadataValueFilter): string[] {
        return AtmireMetadataUtils.allForLang(mapOrMaps, keyOrKeys, this.localeService.getCurrentLanguageCode(), filter)
            .map(m => m.value);
    }

    /**
     * Gets the first matching metadata in the map(s) for the language of the current locale.
     *
     * @param {MetadataMapInterface|MetadataMapInterface[]} mapOrMaps The source map(s). When multiple maps are given, they will be
     * checked in order, and only values from the first with at least one match will be returned.
     * @param {string|string[]} keyOrKeys The metadata key(s) in scope. Wildcards are supported; see above.
     * @param {MetadataValueFilter} filter The value filter to use. If unspecified, no filtering will be done.
     * @returns {MetadataValue[]} the matching values or an empty array.
     */
    public firstCurrentLang(mapOrMaps: MetadataMapInterface | MetadataMapInterface[], keyOrKeys: string | string[],
                            filter?: MetadataValueFilter): MetadataValue {
        return AtmireMetadataUtils.firstForLang(mapOrMaps, keyOrKeys, this.localeService.getCurrentLanguageCode(), filter);
    }

    /**
     * Gets the first matching metadata in the map(s) for the language of the current locale as a string.
     *
     * @param {MetadataMapInterface|MetadataMapInterface[]} mapOrMaps The source map(s). When multiple maps are given, they will be
     * checked in order, and only values from the first with at least one match will be returned.
     * @param {string|string[]} keyOrKeys The metadata key(s) in scope. Wildcards are supported; see above.
     * @param {MetadataValueFilter} filter The value filter to use. If unspecified, no filtering will be done.
     * @returns {MetadataValue[]} the matching values or an empty array.
     */
    public firstValueCurrentLang(mapOrMaps: MetadataMapInterface | MetadataMapInterface[], keyOrKeys: string | string[],
                                 filter?: MetadataValueFilter): string {
        const value = AtmireMetadataUtils.firstForLang(mapOrMaps, keyOrKeys, this.localeService.getCurrentLanguageCode(), filter);
        return isUndefined(value) ? undefined : value.value;
    }
}
