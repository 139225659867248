/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SearchResult } from '../../../../app/shared/search/models/search-result.model';
import { AtmireAbstractListableElementComponent } from '../../object-collection/shared/object-collection-element/atmire-abstract-listable-element.component';
import { DSpaceObject } from '../../../../app/core/shared/dspace-object.model';
import { TruncatableService } from '../../../../app/shared/truncatable/truncatable.service';
import { BitstreamDataService } from '../../../../app/core/data/bitstream-data.service';
import { hasValue } from '../../../../app/shared/empty.util';
import { MetadataMapInterface } from '../../../../app/core/shared/metadata.models';
import { MultilingualMetadataService } from '../../../core/shared/multilingual-metadata.service';

@Component({
  selector: 'ds-search-result-grid-element',
  template: ``,
})
export class AtmireSearchResultGridElementComponent<T extends SearchResult<K>, K extends DSpaceObject> extends AtmireAbstractListableElementComponent<T> implements OnInit {
  /**
   * The DSpaceObject of the search result
   */
  dso: K;

  /**
   * Whether or not the grid element is currently collapsed
   */
  isCollapsed$: Observable<boolean>;

  public constructor(
    protected truncatableService: TruncatableService,
    protected bitstreamDataService: BitstreamDataService,
    protected multilingualMetadataService: MultilingualMetadataService,
  ) {
    super(multilingualMetadataService);
  }

  /**
   * Retrieve the dso from the search result
   */
  ngOnInit(): void {
    if (hasValue(this.object)) {
      this.dso = this.object.indexableObject;
      this.isCollapsed$ = this.isCollapsed();
    }
  }

  protected get metadata(): MetadataMapInterface[] {
    return [this.object.hitHighlights, this.dso.metadata];
  }

  private isCollapsed(): Observable<boolean> {
    return this.truncatableService.isCollapsed(this.dso.id);
  }
}
