/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component } from '@angular/core';
import {
  CommunityAdminSearchResultListElementComponent as BaseComponent
} from '../../../../../../../../app/admin/admin-search-page/admin-search-results/admin-search-result-list-element/community-search-result/community-admin-search-result-list-element.component';
import { ViewMode } from '../../../../../../../../app/core/shared/view-mode.model';
import { listableObjectComponent } from '../../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { Context } from '../../../../../../../../app/core/shared/context.model';
import { CommunitySearchResult } from '../../../../../../../../app/shared/object-collection/shared/community-search-result.model';

@listableObjectComponent(CommunitySearchResult, ViewMode.ListElement, Context.AdminSearch, 'client')
@Component({
  selector: 'ds-community-admin-search-result-list-element-client',
  // styleUrls: ['./community-admin-search-result-list-element.component.scss'],
  styleUrls: ['../../../../../../../../app/admin/admin-search-page/admin-search-results/admin-search-result-list-element/community-search-result/community-admin-search-result-list-element.component.scss'],
  templateUrl: './community-admin-search-result-list-element.component.html',
  // templateUrl: '../../../../../../../../app/admin/admin-search-page/admin-search-results/admin-search-result-list-element/community-search-result/community-admin-search-result-list-element.component.html',
})
/**
 * The component for displaying a list element for a community search result on the admin search page
 */
export class CommunityAdminSearchResultListElementComponent extends BaseComponent {
}
