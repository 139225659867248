/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { LangConfig } from '../../../../../../config/lang-config.interface';
import { Collection } from '../../../../../../app/core/shared/collection.model';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { getFirstCompletedRemoteData, getFirstSucceededRemoteDataPayload } from '../../../../../../app/core/shared/operators';
import { first, map, take } from 'rxjs/operators';
import { Operation } from 'fast-json-patch/commonjs/core';
import { flattenDeep, isEqual } from 'lodash';
import { Observable } from 'rxjs';
import { RemoteData } from '../../../../../../app/core/data/remote-data';
import { Community } from '../../../../../../app/core/shared/community.model';
import { ComColDataService } from '../../../../../../app/core/data/comcol-data.service';
import { NotificationsService } from '../../../../../../app/shared/notifications/notifications.service';
import { Metadata } from '../../../../../../app/core/shared/metadata.utils';

@Component({
  template: '',
})
/**
 * Component for modifying the supported languages of a community or collection
 */
export class ComcolLanguagesComponent<T extends Community | Collection> implements OnInit {
  /**
   * Frontend endpoint for this type of DSO
   */
  protected frontendURL: string;

  /**
   * The initial DSO object for this community / collection
   */
  public dso$: Observable<RemoteData<T>>;

  languageOptions: LangConfig[];
  supported: { [key: string]: boolean };
  ogSupported: { [key: string]: boolean };
  willDeleteMetadataOnDeselect: { [key: string]: boolean };

  public constructor(
    protected dsoDataService: ComColDataService<T>,
    protected route: ActivatedRoute,
    protected router: Router,
    protected translate: TranslateService,
    protected notificationsService: NotificationsService,
  ) {
  }

  ngOnInit(): void {
    this.dso$ = this.route.parent.parent.data.pipe(first(), map((data) => {
      return data.dso;
    }));
    this.languageOptions = environment.languages.filter((lc) => lc.active === true);

    this.dso$.pipe(
      getFirstSucceededRemoteDataPayload(),
      take(1),
    ).subscribe((c: T) => {
      this.willDeleteMetadataOnDeselect = {};
      for (const lang of this.languageOptions) {
        this.willDeleteMetadataOnDeselect = {
          ...this.willDeleteMetadataOnDeselect,
          [lang.code]: Metadata.all(c.metadata, '*', { language: lang.code }).length > 0
        };
      }
      this.setSupportedLanguages(c.allMetadataValues(environment.atmire.multiLanguage.metadataField));
    });
  }

  onSubmit() {
    if (!isEqual(this.ogSupported, this.supported)) {
      this.dso$.pipe(
        getFirstSucceededRemoteDataPayload(),
        take(1),
      ).subscribe((c: T) => {
        const supportedLanguages = Object.keys(this.supported)
                                         .filter((lang) => this.supported[lang]);
        const removedLanguages = Object.keys(this.supported)
                                       .filter((lang) => this.ogSupported[lang] && !this.supported[lang]);

        const clearLanguagesOp = {
          op: 'remove', path: `/metadata/${environment.atmire.multiLanguage.metadataField}`,
        } as Operation;
        const removeLanguageMetadataOps: Operation[] = flattenDeep(
          removedLanguages.map(lang => Object.keys(c.metadata)
            .map(mdf => Metadata.all(c.metadata, mdf, { language: lang })
              .map(mdv => ({ op: 'remove', path: `/metadata/${mdf}/${mdv.place}` } as Operation))
            )
          )
        );
        const addLanguageOps: Operation[] = supportedLanguages.map(lang => ({
          op: 'add', path: `/metadata/${environment.atmire.multiLanguage.metadataField}`, value: lang
        } as Operation));

        this.dsoDataService.patch(c, [clearLanguagesOp, ...removeLanguageMetadataOps, ...addLanguageOps]).pipe(
          getFirstCompletedRemoteData(),
          take(1),
        ).subscribe((response: RemoteData<T>) => {
          if (response.hasSucceeded) {
            this.setSupportedLanguages(c.allMetadataValues(environment.atmire.multiLanguage.metadataField));
            this.notificationsService.success(null, this.translate.get(`${c.type}.edit.notifications.success`));
            this.router.navigate([this.frontendURL + c.id]);
          } else if (response.statusCode === 403) {
            this.notificationsService.error(null, this.translate.get(`${c.type}.edit.notifications.unauthorized`));
          } else {
            this.notificationsService.error(null, this.translate.get(`${c.type}.edit.notifications.error`));
          }
        });
      });
    }
  }

  onCancel() {
    this.supported = { ...this.ogSupported };
  }

  displayWarning(lang: LangConfig): boolean {
    return this.willDeleteMetadataOnDeselect[lang.code] && !this.supported[lang.code] && this.ogSupported[lang.code];
  }

  private setSupportedLanguages(supportedCodes: string[]): void {
    this.supported = this.languageOptions.reduce((obj, lang) => Object.assign(obj, { [lang.code]: false }), {});
    for (const code of supportedCodes) {
      this.supported[code] = true;
    }
    this.ogSupported = { ...this.supported };
  }
}
