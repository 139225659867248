/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Community } from '../../../../../app/core/shared/community.model';
import { CommunityDataService } from '../../../../../app/core/data/community-data.service';
import { RouteService } from '../../../../../app/core/services/route.service';
import { NotificationsService } from '../../../../../app/shared/notifications/notifications.service';
import { RequestService } from '../../../../../app/core/data/request.service';
import { DSONameService } from '../../../../../app/core/breadcrumbs/dso-name.service';
import { AtmireCreateComColPageComponent } from '../../../../../app-atmire/shared/comcol/comcol-forms/create-comcol-page/atmire-create-comcol-page.component';

/**
 * Component that represents the page where a user can create a new Community
 */
@Component({
  selector: 'ds-create-community',
  styleUrls: ['./create-community-page.component.scss'],
  templateUrl: './create-community-page.component.html'
})
export class CreateCommunityPageComponent extends AtmireCreateComColPageComponent<Community> {
  protected frontendURL = '/communities/';
  protected type = Community.type;

  public constructor(
    protected communityDataService: CommunityDataService,
    protected routeService: RouteService,
    protected router: Router,
    protected notificationsService: NotificationsService,
    protected translate: TranslateService,
    protected requestService: RequestService,
    protected dsoNameService: DSONameService,
  ) {
    super(communityDataService, communityDataService, routeService, router, notificationsService, translate, requestService, dsoNameService);
  }
}
