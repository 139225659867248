/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, switchMap } from 'rxjs/operators';
import { hasValue } from '../../../app/shared/empty.util';
import { RemoteData } from '../../../app/core/data/remote-data';
import { DSpaceObject } from '../../../app/core/shared/dspace-object.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LangSwitchService {
    public availableLanguages$: BehaviorSubject<string[]> = new BehaviorSubject([]);

    constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    }

    /**
     * Get the DSO that is currently being displayed from the router. The return value may be undefined.
     */
    public listenForRouteChange(): void {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => this.activatedRoute),
            map((route: ActivatedRoute) => {
                let nextChild = route.firstChild;
                while (hasValue(nextChild.firstChild)) {
                    nextChild = nextChild.firstChild;
                }
                return nextChild;
            }),
            switchMap((route) => route.data),
            map((data) => (data as any).dso),
        ).subscribe(dso => this.processRouteChange(dso));
    }

    private processRouteChange(dsoRD: RemoteData<DSpaceObject>): void {
        this.availableLanguages$.next(dsoRD?.payload?.allMetadataValues(environment.atmire.multiLanguage.metadataField));
    }
}
