<ds-themed-results-back-button *ngIf="showBackButton | async" [back]="back"></ds-themed-results-back-button>
<div class="row" *ngIf="iiifEnabled">
  <div class="col-12">
    <ds-mirador-viewer  id="iiif-viewer"
                        [object]="object"
                        [searchable]="iiifSearchEnabled"
                        [query]="iiifQuery$ | async">
    </ds-mirador-viewer>
  </div>
</div>
<div class="d-flex flex-row">
  <ds-themed-item-page-title-field [item]="object" class="mr-auto">
  </ds-themed-item-page-title-field>
  <div class="pl-2 space-children-mr">
    <ds-dso-page-version-button (newVersionEvent)="onCreateNewVersion()" [dso]="object"
                                [tooltipMsgCreate]="'item.page.version.create'"
                                [tooltipMsgHasDraft]="'item.page.version.hasDraft'"></ds-dso-page-version-button>
    <ds-dso-page-edit-button [pageRoute]="itemPageRoute" [dso]="object" [tooltipMsg]="'publication.page.edit'"></ds-dso-page-edit-button>
  </div>
</div>
<div class="row">
  <div class="col-xs-12 col-md-4">
    <ng-container *ngIf="!mediaViewer.image">
      <ds-metadata-field-wrapper [hideIfNoTextContent]="false">
        <ds-themed-thumbnail [thumbnail]="object?.thumbnail | async"></ds-themed-thumbnail>
      </ds-metadata-field-wrapper>
    </ng-container>
    <div *ngIf="mediaViewer.image" class="mb-2">
      <ds-themed-media-viewer [item]="object" [videoOptions]="mediaViewer.video"></ds-themed-media-viewer>
    </div>
    <ds-themed-item-page-file-section [item]="object"></ds-themed-item-page-file-section>
    <ds-themed-item-page-date-field [item]="object"></ds-themed-item-page-date-field>
    <ds-themed-metadata-representation-list class="ds-item-page-mixed-author-field"
      [parentItem]="object"
      [itemType]="'Person'"
      [metadataFields]="['dc.contributor.author', 'dc.creator']"
      [label]="'relationships.isAuthorOf' | translate">
    </ds-themed-metadata-representation-list>
    <ds-themed-generic-item-page-field [item]="object"
      [fields]="['journal.title']"
      [label]="'publication.page.journal-title'">
    </ds-themed-generic-item-page-field>
    <ds-themed-generic-item-page-field [item]="object"
      [fields]="['journal.identifier.issn']"
      [label]="'publication.page.journal-issn'">
    </ds-themed-generic-item-page-field>
    <ds-themed-generic-item-page-field [item]="object"
      [fields]="['journalvolume.identifier.name']"
      [label]="'publication.page.volume-title'">
    </ds-themed-generic-item-page-field>
    <ds-themed-generic-item-page-field [item]="object"
      [fields]="['dc.publisher']"
      [label]="'publication.page.publisher'">
    </ds-themed-generic-item-page-field>
  </div>
  <div class="col-xs-12 col-md-6">
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isProjectOfPublication'"
      [label]="'relationships.isProjectOf' | translate">
    </ds-related-items>
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isOrgUnitOfPublication'"
      [label]="'relationships.isOrgUnitOf' | translate">
    </ds-related-items>
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isJournalIssueOfPublication'"
      [label]="'relationships.isJournalIssueOf' | translate">
    </ds-related-items>
    <ds-themed-item-page-abstract-field [item]="object"></ds-themed-item-page-abstract-field>
    <ds-themed-generic-item-page-field [item]="object"
      [fields]="['dc.description']"
      [label]="'publication.page.description'">
    </ds-themed-generic-item-page-field>

    <ds-themed-generic-item-page-field [item]="object"
      [fields]="['dc.subject']"
      [separator]="', '"
      [label]="'item.page.subject'">
    </ds-themed-generic-item-page-field>
    <ds-themed-generic-item-page-field [item]="object"
      [fields]="['dc.identifier.citation']"
      [label]="'item.page.citation'">
    </ds-themed-generic-item-page-field>
    <ds-item-page-uri-field [item]="object"
      [fields]="['dc.identifier.uri']"
      [label]="'item.page.uri'">
    </ds-item-page-uri-field>
    <ds-item-page-collections [item]="object"></ds-item-page-collections>
    <div>
      <a class="btn btn-outline-primary" role="button" [routerLink]="[itemPageRoute + '/full']">
        <i class="fas fa-info-circle"></i> {{"item.page.link.full" | translate}}
      </a>
    </div>
  </div>
</div>
