/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component } from '@angular/core';
import { listableObjectComponent } from '../../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { ClaimedApprovedTaskSearchResult } from '../../../../../../../../app/shared/object-collection/shared/claimed-approved-task-search-result.model';
import { ViewMode } from '../../../../../../../../app/core/shared/view-mode.model';
import { AtmireSearchResultListElementComponent } from '../../../../../../../../app-atmire/shared/object-list/search-result-list-element/atmire-search-result-list-element.component';
import { ClaimedTaskSearchResult } from '../../../../../../../../app/shared/object-collection/shared/claimed-task-search-result.model';
import { ClaimedTask } from '../../../../../../../../app/core/tasks/models/claimed-task-object.model';
import { Observable } from 'rxjs';
import { RemoteData } from '../../../../../../../../app/core/data/remote-data';
import { WorkflowItem } from '../../../../../../../../app/core/submission/models/workflowitem.model';
import { LinkService } from '../../../../../../../../app/core/cache/builders/link.service';
import { TruncatableService } from '../../../../../../../../app/shared/truncatable/truncatable.service';
import { DSONameService } from '../../../../../../../../app/core/breadcrumbs/dso-name.service';
import { followLink } from '../../../../../../../../app/shared/utils/follow-link-config.model';
import { MultilingualMetadataService } from '../../../../../../../../app-atmire/core/shared/multilingual-metadata.service';
import { Context } from '../../../../../../../../app/core/shared/context.model';
import { MyDspaceItemStatusType } from 'src/app/shared/object-collection/shared/badges/my-dspace-status-badge/my-dspace-item-status-type';

/**
 * This component renders claimed task approved object for the search result in the list view.
 */
@Component({
    selector: 'ds-claimed-approved-search-result-list-element',
    styleUrls: ['../../../../../../../../app/shared/object-list/search-result-list-element/search-result-list-element.component.scss'],
    templateUrl: '../../../../../../../../app/shared/object-list/my-dspace-result-list-element/claimed-search-result/claimed-approved-search-result/claimed-approved-search-result-list-element.component.html'
})
// app/shared/object-list/my-dspace-result-list-element/claimed-search-result/claimed-approved-search-result/
@listableObjectComponent(ClaimedApprovedTaskSearchResult, ViewMode.ListElement, Context.Any, 'atmire')
export class ClaimedApprovedSearchResultListElementComponent extends AtmireSearchResultListElementComponent<ClaimedTaskSearchResult, ClaimedTask> {

    /**
     * A boolean representing if to show submitter information
     */
    public showSubmitter = true;

    /**
     * Represent item's status
     */
    public status = MyDspaceItemStatusType.APPROVED;

    /**
     * The workflowitem object that belonging to the result object
     */
    public workflowitemRD$: Observable<RemoteData<WorkflowItem>>;

    public constructor(
        protected linkService: LinkService,
        protected truncatableService: TruncatableService,
        protected dsoNameService: DSONameService,
        protected multilingualMetadataService: MultilingualMetadataService,
    ) {
        super(truncatableService, dsoNameService, multilingualMetadataService);
    }

    /**
     * Initialize all instance variables
     */
    ngOnInit() {
        super.ngOnInit();
        this.linkService.resolveLinks(this.dso,
            followLink('workflowitem',
                { useCachedVersionIfAvailable: false },
                followLink('item'),
                followLink('submitter')
            ),
            followLink('action')
        );
        this.workflowitemRD$ = this.dso.workflowitem as Observable<RemoteData<WorkflowItem>>;
    }

}
