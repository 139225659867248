/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component } from '@angular/core';
import { AtmireAbstractListableElementComponent } from '../../../../../../app-atmire/shared/object-collection/shared/object-collection-element/atmire-abstract-listable-element.component';
import { listableObjectComponent } from '../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { Collection } from '../../../../../../app/core/shared/collection.model';
import { ViewMode } from '../../../../../../app/core/shared/view-mode.model';
import { Context } from '../../../../../../app/core/shared/context.model';
import { MultilingualMetadataService } from '../../../../../../app-atmire/core/shared/multilingual-metadata.service';

@Component({
  selector: 'ds-collection-list-element',
  styleUrls: ['./collection-list-element.component.scss'],
  templateUrl: './collection-list-element.component.html'
})
/**
 * Component representing list element for a collection
 */
@listableObjectComponent(Collection, ViewMode.ListElement, Context.Any, 'atmire')
export class CollectionListElementComponent extends AtmireAbstractListableElementComponent<Collection> {

  public constructor(
    protected multilingualMetadataService: MultilingualMetadataService,
  ) {
    super(multilingualMetadataService);
  }
}
