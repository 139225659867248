<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<section *ngIf="!empty" [ngClass]="sectionClass">
  <ng-content></ng-content>
</section>
