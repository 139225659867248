/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Injectable } from '@angular/core';
import { ParsedResponse, RestResponse } from '../../../app/core/cache/response.models';
import { ResponseParsingService } from '../../../app/core/data/parsing.service';
import { RestRequest } from '../../../app/core/data/request.models';
import { ObjectCacheService } from '../../../app/core/cache/object-cache.service';
import { SuggestionCategory } from '../search/models/suggestion-category.model';
import { RawRestResponse } from '../../../app/core/dspace-rest/raw-rest-response.model';
import { Suggestion } from '../search/models/suggestion.model';
import { PaginatedList } from '../../../app/core/data/paginated-list.model';
import { DSpaceSerializer } from '../../../app/core/dspace-rest/dspace.serializer';
import { QuerySuggestionsObject } from '../../shared/search/query-suggestion-object.model';
import { DspaceRestResponseParsingService } from '../../../app/core/data/dspace-rest-response-parsing.service';

@Injectable()
export class SuggestResponseParsingService extends DspaceRestResponseParsingService implements ResponseParsingService {


  constructor(
    protected objectCache: ObjectCacheService,
  ) {
    super(objectCache);
  }

  parse(request: RestRequest, data: RawRestResponse): RestResponse {
    const results: any[] = data.payload._embedded.queryCategories;
    let normalizedCategories: SuggestionCategory[] = [];
    results
      .forEach((nsc) => {
        const category: SuggestionCategory = this.deserialize(nsc);
        this.addToObjectCache(category, request, data);
        const suggestionData = nsc._embedded.searchResult;
        const suggestionPage: PaginatedList<Suggestion> = this.processPaginatedList<Suggestion>(suggestionData, request);

        /* I don't think I can cache these, since they don't have a self link */
        suggestionPage.page = suggestionData._embedded.suggestions.map((sug) => this.deserialize(sug));

        category.values = suggestionPage;
        return normalizedCategories = [...normalizedCategories, category];
      });

    const deserialized: QuerySuggestionsObject = new DSpaceSerializer(QuerySuggestionsObject).deserialize(data.payload);
    deserialized.results = normalizedCategories;
    this.addToObjectCache(deserialized, request, data);
    return new ParsedResponse(data.statusCode, deserialized._links.self);

  }

}
