import { ChangeDetectorRef, Component, ComponentFactoryResolver, EventEmitter, Input, Output } from '@angular/core';
import { ThemedComponent } from '../theme-support/themed.component';
import { SearchFormComponent } from './search-form.component';
import { ThemeService } from '../theme-support/theme.service';

/**
 * Themed wrapper for ThumbnailComponent
 */
@Component({
  selector: 'ds-themed-search-form',
  styleUrls: ['./search-form.component.scss'],
  templateUrl: '../theme-support/themed.component.html',
})
export class ThemedSearchFormComponent extends ThemedComponent<SearchFormComponent> {
  protected inAndOutputNames: (keyof SearchFormComponent & keyof this)[] = [
    'query',
    'inPlaceSearch',
    'scope',
    'currentUrl',
    'large',
    'brandColor',
    'searchPlaceholder',
    'showScopeSelector',
    'showButton',
    'useSearchOptions',
    'querySuggestions',
    'submitSearch',
  ];

  @Input() query: string;

  @Input() inPlaceSearch;

  @Input() scope = '';

  @Input() currentUrl: string;

  @Input() large = false;

  @Input() brandColor = 'primary';

  @Input() searchPlaceholder: string;

  @Input() showScopeSelector = false;

  // Atmire modifications START
  @Input() showButton = true;

  @Input() useSearchOptions = false;

  @Input() querySuggestions = true;
  // Atmire modifications END

  @Output() submitSearch = new EventEmitter<any>();

  constructor(
    protected resolver: ComponentFactoryResolver,
    protected cdr: ChangeDetectorRef,
    protected themeService: ThemeService
  ) {
    super(resolver, cdr, themeService);
  }

  protected getComponentName(): string {
    return 'SearchFormComponent';
  }

  protected importThemedComponent(themeName: string): Promise<any> {
    return import(`../../../themes/${themeName}/app/shared/search-form/search-form.component`);
  }

  protected importUnthemedComponent(): Promise<any> {
    return import(`./search-form.component`);
  }
}
