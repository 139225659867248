/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component } from '@angular/core';
import { AtmireSidebarSearchListElementComponent } from 'src/app-atmire/shared/object-list/sidebar-search-list-element/atmire-sidebar-search-list-element.component';
import { Collection } from 'src/app/core/shared/collection.model';
import { Context } from 'src/app/core/shared/context.model';
import { ViewMode } from 'src/app/core/shared/view-mode.model';
import { CollectionSearchResult } from 'src/app/shared/object-collection/shared/collection-search-result.model';
import { listableObjectComponent } from 'src/app/shared/object-collection/shared/listable-object/listable-object.decorator';

@listableObjectComponent(CollectionSearchResult, ViewMode.ListElement, Context.SideBarSearchModal, 'atmire')
@listableObjectComponent(CollectionSearchResult, ViewMode.ListElement, Context.SideBarSearchModalCurrent, 'atmire')
@Component({
  selector: 'ds-collection-sidebar-search-list-element',
  templateUrl: '../../../../../../../app/shared/object-list/sidebar-search-list-element/sidebar-search-list-element.component.html'
})
/**
 * Component displaying a list element for a {@link CollectionSearchResult} within the context of a sidebar search modal
 */
export class CollectionSidebarSearchListElementComponent extends AtmireSidebarSearchListElementComponent<CollectionSearchResult, Collection> {
  /**
   * Get the description of the Collection by returning its abstract
   */
  getDescription(): string {
    return this.firstMetadataValue('dc.description.abstract');
  }
}
