/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component } from '@angular/core';
import { HeaderNavbarWrapperComponent as BaseComponent } from '../../../../app/header-nav-wrapper/header-navbar-wrapper.component';

/**
 * This component represents a wrapper for the horizontal navbar and the header
 */
@Component({
  selector: 'ds-header-navbar-wrapper',
  // styleUrls: ['header-navbar-wrapper.component.scss'],
  styleUrls: ['../../../../app/header-nav-wrapper/header-navbar-wrapper.component.scss'],
  templateUrl: 'header-navbar-wrapper.component.html',
  // templateUrl: '../../../../app/header-nav-wrapper/header-navbar-wrapper.component.html',
})
export class HeaderNavbarWrapperComponent extends BaseComponent {
}
