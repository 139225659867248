<ng-container *ngVar="(workflowitemRD$ | async)?.payload as workflowitem">
  <ds-themed-item-detail-preview *ngIf="workflowitem"
                          [item]="(workflowitem.item | async)?.payload"
                          [object]="object"
                          [showSubmitter]="showSubmitter"
                          [status]="status">
  </ds-themed-item-detail-preview>

  <ds-claimed-task-actions *ngIf="workflowitem" [object]="dso" (processCompleted)="reloadedObject.emit($event.reloadedObject)"></ds-claimed-task-actions>
</ng-container>
