/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, OnInit } from '@angular/core';
import { FilterSuggestion } from '../../../../../../core/search/models/filter-suggestion.model';
import { rendersSuggestionPreview } from '../../suggestion.decorator';
import { SuggestionPreviewComponent } from '../suggestion-preview.component';
import { Observable } from 'rxjs';
import { RemoteData } from '../../../../../../../app/core/data/remote-data';
import { DSpaceObject } from '../../../../../../../app/core/shared/dspace-object.model';
import { PaginationComponentOptions } from '../../../../../../../app/shared/pagination/pagination-component-options.model';
import { SortDirection, SortOptions } from '../../../../../../../app/core/cache/models/sort-options.model';
import { SearchService } from '../../../../../../../app/core/shared/search/search.service';
import { PaginatedList } from '../../../../../../../app/core/data/paginated-list.model';
import { SearchResult } from '../../../../../../../app/shared/search/models/search-result.model';
import { PaginatedSearchOptions } from '../../../../../../../app/shared/search/models/paginated-search-options.model';
import { SearchFilter } from '../../../../../../../app/shared/search/models/search-filter.model';
import { Context } from '../../../../../../../app/core/shared/context.model';

/**
 * This component renders a preview for a filter suggestion
 * It shows the 5 most relevant search results when the filter suggestion would be applied
 */

@Component({
  selector: 'ds-filter-suggestion-preview',
  styleUrls: ['./filter-suggestion-preview.component.scss'],
  templateUrl: './filter-suggestion-preview.component.html'
})
@rendersSuggestionPreview(FilterSuggestion)
export class FilterSuggestionPreviewComponent extends SuggestionPreviewComponent<FilterSuggestion> implements OnInit {
  context = Context.QuerySuggestionPreviewList;

  /**
   * Search results for when this suggestion would be applied
   */
  resultsRD$: Observable<RemoteData<PaginatedList<SearchResult<DSpaceObject>>>>;

  constructor(
    private searchService: SearchService
  ) {
    super();
  }

  ngOnInit() {
    const searchOpts = Object.assign(
      new PaginatedSearchOptions({}),
      this.searchOptions,
      {
        query: undefined,
        pagination: Object.assign(
          new PaginationComponentOptions(), {
            pageSize: 5
          }),
        sort: new SortOptions('score', SortDirection.DESC),
        filters: [new SearchFilter('f.' + this.category.name, [decodeURI(this.suggestion.label) + ',equals'])]
      }
    );
    this.resultsRD$ = this.searchService.search(searchOpts);
  }
}
