/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component } from '@angular/core';
import { Item } from '../../../../../../../../app/core/shared/item.model';
import {
  listableObjectComponent
} from '../../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { ViewMode } from '../../../../../../../../app/core/shared/view-mode.model';
import {
  ItemSearchResultListElementComponent as BaseComponent
} from '../../../../../../../../app/shared/object-list/search-result-list-element/item-search-result/item-types/item/item-search-result-list-element.component';
import { Context } from '../../../../../../../../app/core/shared/context.model';

@listableObjectComponent('Publication', ViewMode.ListElement, Context.RelatedItems, 'client')
@listableObjectComponent(Item, ViewMode.ListElement, Context.RelatedItems, 'client')
@Component({
  selector: 'ds-related-item-list-element',
  styleUrls: ['../../../../../../../../app/shared/object-list/search-result-list-element/item-search-result/item-types/item/item-search-result-list-element.component.scss'],
  templateUrl: './related-item-list-element.component.html',
  // templateUrl: '../../../../../../../../app/shared/object-list/item-list-element/item-types/item/item-list-element.component.html',
})
export class RelatedItemListElementComponent extends BaseComponent {
}
