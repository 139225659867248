/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Input } from '@angular/core';
import { CollectionDataService } from '../../../../app/core/data/collection-data.service';

import { Collection } from '../../../../app/core/shared/collection.model';
import { Item } from '../../../../app/core/shared/item.model';
import { CollectionsComponent } from '../../../../app/item-page/field-components/collections/collections.component';
import { MultilingualMetadataService } from '../../../core/shared/multilingual-metadata.service';

/**
 * This component renders the parent collections section of the item
 * inside a 'ds-metadata-field-wrapper' component.
 */
@Component({
  selector: 'ds-item-page-multilingual-collections',
  templateUrl: './multilingual-collections.component.html',
})
export class MultilingualCollectionsComponent extends CollectionsComponent {

  @Input() item: Item;

  constructor(
    protected cds: CollectionDataService,
    private multilingualMetadataService: MultilingualMetadataService,
  ) {
    super(cds);
  }

  getName(collection: Collection): string {
    return this.multilingualMetadataService.firstValueCurrentLang(collection.metadata, 'dc.title');
  }
}
