/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import {Component} from '@angular/core';
import {ComColFormComponent} from '../../../../../app/shared/comcol/comcol-forms/comcol-form/comcol-form.component';
import {Community} from '../../../../../app/core/shared/community.model';
import {Collection} from '../../../../../app/core/shared/collection.model';
import {
  BehaviorSubject,
  combineLatest as observableCombineLatest,
  from as observableFrom,
  Observable,
  of as observableOf
} from 'rxjs';
import {DynamicFormControlModel, DynamicFormService, DynamicInputModel} from '@ng-dynamic-forms/core';
import {first, map, mergeMap, switchMap, take, toArray} from 'rxjs/operators';
import {
  getFirstCompletedRemoteData,
  getFirstSucceededRemoteDataPayload
} from '../../../../../app/core/shared/operators';
import {environment} from '../../../../../environments/environment';
import {hasValue} from '../../../../../app/shared/empty.util';
import {RemoteData} from '../../../../../app/core/data/remote-data';
import {Bitstream} from '../../../../../app/core/shared/bitstream.model';
import {RestRequestMethod} from '../../../../../app/core/data/rest-request-method';
import {NoContent} from '../../../../../app/core/shared/NoContent.model';
import {MetadataMap, MetadataValue} from '../../../../../app/core/shared/metadata.models';
import {Operation} from 'fast-json-patch';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {NotificationsService} from '../../../../../app/shared/notifications/notifications.service';
import {AuthService} from '../../../../../app/core/auth/auth.service';
import {RequestService} from '../../../../../app/core/data/request.service';
import {ObjectCacheService} from '../../../../../app/core/cache/object-cache.service';
import {AlertType} from 'src/app/shared/alert/aletr-type';

/**
 * A form for creating and editing Communities or Collections
 */
@Component({
  selector: 'ds-comcol-form',
  styleUrls: ['./atmire-comcol-form.component.scss'],
  templateUrl: './atmire-comcol-form.component.html',
})
export class AtmireComColFormComponent<T extends Collection | Community> extends ComColFormComponent<T> {

  /**
   * The languages for which form fields actually exist
   */
  formLanguages: Set<string> = new Set();

  /**
   * The languages that have at least one invalid field in their tab
   */
  invalidLanguages$ = new BehaviorSubject<string[]>([]);

  invalidLanguagesSummary$: Observable<string>;

  /**
   * Whether to enable the save button
   */
  enableSave$ = new BehaviorSubject<boolean>(false);

  AlertType = AlertType;

  public constructor(protected formService: DynamicFormService,
                     protected route: ActivatedRoute,
                     protected translate: TranslateService,
                     protected notificationsService: NotificationsService,
                     protected authService: AuthService,
                     protected requestService: RequestService,
                     protected objectCache: ObjectCacheService) {
    super(
      formService,
      translate,
      notificationsService,
      authService,
      requestService,
      objectCache,
    );
  }

  /**
   * Parse the language code from a DynamicInputModel
   * @param fieldModel
   * @protected
   */
  private static getLanguage(fieldModel: DynamicInputModel): string {
    const pieces = fieldModel.id.split(/[_]+/);
    if (pieces.length > 1) {
      const lang = pieces[pieces.length - 1].trim();
      return lang ? lang : null;
    } else {
      return null;
    }
  }

  ngOnInit(): void {

    const supported = environment.languages.map(language => language.code);

    this.route.parent.data.pipe(
      first(),
      map((data) => data.dso),
      getFirstSucceededRemoteDataPayload(),
      take(1),
      map((c: Collection) => {
        // for consistency, keep only supported languages in the same order as environment.languages
        return c.allMetadataValues(environment.atmire.multiLanguage.metadataField)
          .filter(lang => supported.includes(lang))
          .sort((a, b) => supported.indexOf(a) - supported.indexOf(b));
      }),
    ).subscribe(
      (languageCodes: string[]) => {
        this.formLanguages.clear();
        this.buildFormModel(languageCodes).subscribe(
          (formModel) => {
            this.formModel = formModel;
            this.initialize();
          }
        );
      },
      () => {
        // if the Collection doesn't exist yet, i.e. when creating a new one
        this.formLanguages.clear();
        this.buildFormModel(environment.atmire.multiLanguage.defaultLanguages).subscribe(
          (formModel) => {
            this.formModel = formModel;
            this.initialize();
          }
        );
      }
    );

    this.invalidLanguagesSummary$ = this.invalidLanguages$.pipe(
      switchMap(invalidLanguages => {
        return observableFrom(invalidLanguages).pipe(
          mergeMap(invalidLanguage => this.translate.get(`language.${invalidLanguage}.label`)),
          toArray(),
        );
      }),
      map(labels => labels.join(', ')),
    );

  }

  initialize(): void {
    this.formModel.forEach(
      (fieldModel: DynamicInputModel) => {
        const lang = AtmireComColFormComponent.getLanguage(fieldModel);
        if (lang !== null) {
          fieldModel.value = this.dso.firstMetadataValue(fieldModel.name, { language: lang });
        } else {
          fieldModel.value = this.dso.firstMetadataValue(fieldModel.name);
        }
      }
    );
    this.formGroup = this.formService.createFormGroup(this.formModel);

    this.updateFieldTranslations();
    this.translate.onLangChange
      .subscribe(() => {
        this.updateFieldTranslations();
      });

    if (hasValue(this.dso.id)) {
      this.subs.push(
        observableCombineLatest([
          this.dsoService.getLogoEndpoint(this.dso.id),
          this.dso.logo
        ]).subscribe(([href, logoRD]: [string, RemoteData<Bitstream>]) => {
          this.uploadFilesOptions.url = href;
          this.uploadFilesOptions.authToken = this.authService.buildAuthHeader();
          // If the object already contains a logo, send out a PUT request instead of POST for setting a new logo
          if (hasValue(logoRD.payload)) {
            this.uploadFilesOptions.method = RestRequestMethod.PUT;
          }
          this.initializedUploaderOptions.next(true);
        })
      );
    } else {
      // Set a placeholder URL to not break the uploader component. This will be replaced once the object is created.
      this.uploadFilesOptions.url = 'placeholder';
      this.uploadFilesOptions.authToken = this.authService.buildAuthHeader();
      this.initializedUploaderOptions.next(true);
    }
  }

  gatherErrors(): void {
    const invalidLanguages = new Set<string>();

    this.formModel.forEach((model: DynamicInputModel) => {
      const control = this.formGroup.get(model.id);
      if (!control.valid) {
        invalidLanguages.add(AtmireComColFormComponent.getLanguage(model));
      }
    });

    this.invalidLanguages$.next([...invalidLanguages]);
    this.enableSave$.next(this.formGroup.valid);
  }

  /**
   * Checks which new fields were added and sends the updated version of the DSO to the parent component
   */
  onSubmit() {
    if (this.markLogoForDeletion && hasValue(this.dso.id) && hasValue(this.dso._links.logo)) {
      this.dsoService.deleteLogo(this.dso).pipe(
        getFirstCompletedRemoteData()
      ).subscribe((response: RemoteData<NoContent>) => {
        if (response.hasSucceeded) {
          this.notificationsService.success(
            this.translate.get(this.type.value + '.edit.logo.notifications.delete.success.title'),
            this.translate.get(this.type.value + '.edit.logo.notifications.delete.success.content')
          );
        } else {
          this.notificationsService.error(
            this.translate.get(this.type.value + '.edit.logo.notifications.delete.error.title'),
            response.errorMessage
          );
        }
        this.dso.logo = undefined;
        this.uploadFilesOptions.method = RestRequestMethod.POST;
        this.refreshCache();
        this.finish.emit();
      });
    }

    const formMetadata = {}  as MetadataMap;
    this.formModel.forEach((fieldModel: DynamicInputModel) => {
      const value: MetadataValue = {
        value: fieldModel.value as string,
        language: AtmireComColFormComponent.getLanguage(fieldModel),
      } as any;
      if (formMetadata.hasOwnProperty(fieldModel.name)) {
        formMetadata[fieldModel.name].push(value);
      } else {
        formMetadata[fieldModel.name] = [value];
      }
    });

    const updatedDSO = Object.assign({}, this.dso, {
      metadata: {
        ...this.dso.metadata,
        ...formMetadata
      },
      type: Community.type
    });

    const operations: Operation[] = [];
    this.formModel.forEach((fieldModel: DynamicInputModel) => {

      const lang: string = AtmireComColFormComponent.getLanguage(fieldModel);
      if (lang !== null) {
        const index = this.dso.allMetadata(fieldModel.name).findIndex(md => md.language === lang);
        if (index !== -1) {
          operations.push({
            op: 'replace',
            path: `/metadata/${fieldModel.name}/${index}`,
            value: {
              value: fieldModel.value,
              language: lang,
            },
          });
        } else {
          operations.push({
            op: 'add',
            path: `/metadata/${fieldModel.name}`,
            value: {
              value: fieldModel.value,
              language: lang,
            },
          });
        }
      } else if (fieldModel.value !== this.dso.firstMetadataValue(fieldModel.name)) {
        operations.push({
          op: 'replace',
          path: `/metadata/${fieldModel.name}`,
          value: {
            value: fieldModel.value,
            language: null,
          },
        });
      }
    });

    this.submitForm.emit({
      dso: updatedDSO,
      uploader: hasValue(this.uploaderComponent) ? this.uploaderComponent.uploader : undefined,
      deleteLogo: this.markLogoForDeletion,
      operations: operations,
    });
  }

  /**
   * Generate the form for the community/collection. Should be overridden in extending classes.
   * @param languages
   * @protected
   */
  protected buildFormModel(languages: string[]): Observable<DynamicFormControlModel[]> {
    return observableOf([]);
  }

  /**
   * Return part of the form in a specific language
   * @param lang    a language code
   */
  getFormByLanguage(lang: string | null): DynamicFormControlModel[] {
    return this.formModel.filter((control: DynamicInputModel) => AtmireComColFormComponent.getLanguage(control) === lang);
  }
}
